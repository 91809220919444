import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function ResetPassword() {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;
  const backgroundImage = "url('/assets/card.png')";
  const [isLoading, setIsLoading] = useState(true);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };


  const togglePasswordVisibility1 = () => {
    setShowPassword1((prevState) => !prevState);
  };


  console.log(token)
  useEffect(() => {
    const fakeDataFetch = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    };
    fakeDataFetch();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    try {
      const response = await fetch(`${baseUrl}/users/resetpasswordd?token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ newPassword, confirmPassword }),
      });

      const data = await response.json();

      if (data.success) {
        setMessage(data.message);
        setMessageType("success");
      } else {
        setMessage(data.message);
        setMessageType("error");
      }
    } catch (error) {
      setMessage("Error resetting password. Please try again.");
      setMessageType("error");
      console.error("Error resetting password:", error);
    }

    setTimeout(() => {
      setMessage('');
      setMessageType('');
      navigate("/")

    }, 2000);
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center bg-[#ffff] py-14 p-2">
      <div className="absolute top-4 md:top-[100px] lg:top-40 left-4 md:left-[20px] xl:left-[120px]">
        <img src="/assets/logo3.png" alt="Left Logo" className="w-16 md:w-48" />
      </div>

      <div className="absolute top-[60px] md:top-[46px] right-4 md:right-[8px]">
        <img
          src="/assets/log2.png"
          alt="Right Logo"
          className="w-20 md:w-[150px] lg:w-[306px]"
        />
      </div>

      <div
        className="bg-white p-10 mt-2 rounded-lg shadow-lg w-full max-w-md"
        style={{
          backgroundImage: backgroundImage,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h2 className="text-2xl font-bold mb-6 text-center text-[#ffff]">
          Reset Password
        </h2>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>

            <div className="relative">
              <input

                type={showPassword ? "text" : "password"}
                id="password"
                placeholder="Enter New Password*"
                value={newPassword}

                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full h-12 px-4 placeholder-[#234971] border-2 rounded-md"
                required
              />

              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-6 flex items-center text-[#234971]"
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            {/* <input
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              placeholder="Enter New Password*"
              value={newPassword}

              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full h-12 px-4 placeholder-[#234971] border-2 rounded-md"
              required
            /> */}
          </div>


          
          <div className="relative">
              <input

                type={showPassword1 ? "text" : "password"}
                name="confirmpassword"
                id="confirmpassword"
                placeholder="Enter Confirm Password *"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full h-12 px-4  placeholder-[#234971] border-2 rounded-md"
                required
              />

              <button
                type="button"
                onClick={togglePasswordVisibility1}
                className="absolute  inset-y-0 right-6 flex items-center text-[#234971]"
              >
                {showPassword1 ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          {/* <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name="confirmpassword"
              id="confirmpassword"
              placeholder="Enter Confirm Password *"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full h-12 px-4 placeholder-[#234971] border-2 rounded-md"
              required
            />
          </div> */}
      
          <div className="flex justify-center">
            <button
              type="submit"
              className="w-[130px] h-12 bg-[#ffffff] text-[#234971] hover:bg-[#234971] hover:text-[#ffffff] border-2 rounded-[40px] text-center text-lg focus:outline-none focus:ring-2 focus:ring-[#234971] focus:ring-opacity-50"
            >
              Submit
            </button>
          </div>
        </form>

        {/* Display success or error message */}
        {message && (
          <div className={`mt-4 p-2 rounded ${messageType === "success" ? "bg-green-500 text-white" : "bg-red-500 text-white"}`}>
            {message}
          </div>
        )}
      </div>
    </div>
  );
}

export default ResetPassword;
