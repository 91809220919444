import React, { useState } from "react";

const RequiredTestsDropdown = ({ formData, setFormData }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const testOptions = [
    { value: "OGTT NF", label: "OGTT NF" },
    { value: "ICT/Antibody Screening", label: "ICT/Antibody Screening" },
    { value: "CA 72-4", label: "CA 72-4" },
    { value: "Calcium", label: "Calcium" },
    { value: "Protein, Total", label: "Protein, Total" },
    { value: "CEA", label: "CEA" },
    { value: "Copper", label: "Copper" },
    { value: "TIBC", label: "TIBC" },
    { value: "PT+INR", label: "PT+INR" },
    { value: "Free PSA", label: "Free PSA" },
    { value: "Electrolytes: CL, Na, K", label: "Electrolytes: CL, Na, K" },
    { value: "Transferrin", label: "Transferrin" },
    { value: "PTT C", label: "PTT C" },
    { value: "Calcitonin", label: "Calcitonin" },
    { value: "Iron", label: "Iron" },
    { value: "Triglycerides", label: "Triglycerides" },
    { value: "Anti-Thrombin III", label: "Anti-Thrombin III" },
    { value: "Magnesium", label: "Magnesium" },
    { value: "Troponin T", label: "Troponin T" },
    { value: "D-DIMER/FDP", label: "D-DIMER/FDP" },
    { value: "17 OH Progesterone", label: "17 OH Progesterone" },
    { value: "Phosphorous", label: "Phosphorous" },
    { value: "Urea", label: "Urea" },
    { value: "Factor II Mutation", label: "Factor II Mutation" },
    { value: "Aldosterone", label: "Aldosterone" },
    { value: "Zinc", label: "Zinc" },
    { value: "Uric Acid", label: "Uric Acid" },
    { value: "Factor V Mutation", label: "Factor V Mutation" },
    { value: "Androstenedione Hormone (ACTH)", label: "Androstenedione Hormone (ACTH)" },
    { value: "Fibrinogen", label: "Fibrinogen" },
    { value: "Beta HCG", label: "Beta HCG" },
    { value: "Albumin", label: "Albumin" },
    { value: "Alkaline Phosphatase", label: "Alkaline Phosphatase" },
    { value: "Lupus Anticoagulants", label: "Lupus Anticoagulants" },
    { value: "DHEA-S", label: "DHEA-S" },
    { value: "Bilirubin: Direct, Total", label: "Bilirubin: Direct, Total" },
    { value: "ALT/GPT", label: "ALT/GPT" },
    { value: "Estradiol", label: "Estradiol" },
    { value: "Pro-BNP", label: "Pro-BNP" },
    { value: "Amylase", label: "Amylase" },
    { value: "Free T3", label: "Free T3" },
    { value: "Catecholamines HP", label: "Catecholamines HP" },
    { value: "AST/GOT", label: "AST/GOT" },
    { value: "Creatinine Clearance", label: "Creatinine Clearance" },
    { value: "Free T4", label: "Free T4" },
    { value: "Cholesterol", label: "Cholesterol" },
    { value: "Cholinesterase", label: "Cholinesterase" },
    { value: "Drug of Abusecreen", label: "Drug of Abusecreen" },
    { value: "Free Testosterone", label: "Free Testosterone" },
    { value: "CK-MB", label: "CK-MB" },
    { value: "CK", label: "CK" },
    { value: "Electrolytes:l, Na, K24", label: "Electrolytes:l, Na, K24" },
    { value: "Free Testosterone (Calculated)", label: "Free Testosterone (Calculated)" },
    { value: "C-Peptide: F, R", label: "C-Peptide: F, R" },
    { value: "GGT", label: "GGT" },
    { value: "Total Protein", label: "Total Protein" },
    { value: "FSH", label: "FSH" },
    { value: "CRP", label: "CRP" },
    { value: "LDH", label: "LDH" },
    { value: "Growth Hormone", label: "Growth Hormone" },
    { value: "Cystatin/GFR", label: "Cystatin/GFR" },
    { value: "Lipase", label: "Lipase" },
    { value: "Exam.,oncentrated", label: "Exam.,oncentrated" },
    { value: "IGF-1", label: "IGF-1" },
    { value: "Ferritin", label: "Ferritin" },
    { value: "Pancreatic Amylase", label: "Pancreatic Amylase" },
    { value: "Exam., Direct", label: "Exam., Direct" },
    { value: "Insulin, fasting", label: "Insulin, fasting" },
    { value: "Glucose: Fasting, R, PP NF", label: "Glucose: Fasting, R, PP NF" },
    { value: "Occult Blood", label: "Occult Blood" },
    { value: "Luteinizing Hormone (LH)", label: "Luteinizing Hormone (LH)" },
    { value: "HbA1c", label: "HbA1c" },
    { value: "CBC + machine diff.", label: "CBC + machine diff." },
    { value: "H.pylori Ag", label: "H.pylori Ag" },
    { value: "Parathyroid Hormone (PTH)P", label: "Parathyroid Hormone (PTH)P" },
    { value: "HDL-Cholesterol", label: "HDL-Cholesterol" },
    { value: "CBC + manual diff.", label: "CBC + manual diff." },
    { value: "Rotavirus Ag", label: "Rotavirus Ag" },
    { value: "Homocysteine", label: "Homocysteine" },
    { value: "ESR", label: "ESR" },
    { value: "Calprotectin", label: "Calprotectin" },
    { value: "Folate", label: "Folate" },
    { value: "Immunoglobulin", label: "Immunoglobulin" },
    { value: "G6PDH", label: "G6PDH" },
    { value: "Vitamin A", label: "Vitamin A" },
    { value: "Hb-HPLC", label: "Hb-HPLC" },
    { value: "AFP", label: "AFP" },
    { value: "Vitamin B1", label: "Vitamin B1" },
    { value: "Malariamear + Ag", label: "Malariamear + Ag" },
    { value: "Beta-HCG", label: "Beta-HCG" },
    { value: "Vitamin B2", label: "Vitamin B2" },
    { value: "Reticulocytes", label: "Reticulocytes" },
    { value: "CA 125", label: "CA 125" },
    { value: "Vitamin B6P", label: "Vitamin B6P" },
    { value: "LDL-Cholesterol", label: "LDL-Cholesterol" },
    { value: "CA 15-3", label: "CA 15-3" },
    { value: "Vitamin B12", label: "Vitamin B12" },
    { value: "Lipoprotein (a)", label: "Lipoprotein (a)" },
    { value: "Blood Group & Rh +", label: "Blood Group & Rh +" },
    { value: "CA 19-9", label: "CA 19-9" },
    { value: "Vitamin D, 25OH total (For Vitamin D deficiency)", label: "Vitamin D, 25OH total (For Vitamin D deficiency)" },
    { value: "Anemia Profile ", label: "Anemia Profile " },
    { value: "Antenatal Profile", label: "Antenatal Profile" },
    { value: "Coagulation Profile", label: "Coagulation Profile" },
    { value: "Coronary Risk Profile", label: "Coronary Risk Profile" },
    { value: "Diabetes Profile", label: "Diabetes Profile" },
    { value: "Hepatitis Screen", label: "Hepatitis Screen" },
    { value: "Hepatitis B Profile", label: "Hepatitis B Profile" },
    { value: "Hirsutism Profile", label: "Hirsutism Profile" },
    { value: "Infertility Profile-Male", label: "Infertility Profile-Male" },
    { value: "Infertility Profile -Female ", label: "Infertility Profile -Female " },
    { value: "Lipid Profile", label: "Lipid Profile" },
    { value: "Liver Function Test", label: "Liver Function Test" },
    { value: "Renal Function Test", label: "Renal Function Test" },
    { value: "Menopause Profile ", label: "Menopause Profile " },
    { value: "Osteoporosis Screen", label: "Osteoporosis Screen" },
    { value: "STD Panel", label: "STD Panel" },
    { value: "Cancer Screen Female", label: "Cancer Screen Female" },
    { value: "Cancer Screen Male", label: "Cancer Screen Male" },
    { value: "Thyroid Function, Free", label: "Thyroid Function, Free" },
    { value: "Thyroid Function, Total ", label: "Thyroid Function, Total " },
    { value: "Thyroid Function Extended ", label: "Thyroid Function Extended " },
    { value: "TORCH IgG", label: "TORCH IgG" },
    { value: "TORCH IgM", label: "TORCH IgM" },


  ];

  const filteredOptions = testOptions.filter((test) =>
    test.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelect = (test) => {
    setFormData((prev) => {
      const currentRequiredTests = Array.isArray(prev.requiredTests) ? prev.requiredTests : [];

      const exists = currentRequiredTests.find((t) => t.value === test.value);
      if (exists) {
        return {
          ...prev,
          requiredTests: currentRequiredTests.filter((t) => t.value !== test.value),
        };
      } else {
        return {
          ...prev,
          requiredTests: [...currentRequiredTests, { ...test, fasting: false }],
        };
      }
    });
  };

  const isSelected = (test) => {
    const currentRequiredTests = Array.isArray(formData.requiredTests) ? formData.requiredTests : [];
    return currentRequiredTests.some((t) => t.value === test.value);
  };

  return (
    <>
      {/* Main Button to trigger the popup */}
      <button
      type="button"
        className="w-full py-[10px] px-4 border-[#4ab0b0] border-[1px] rounded-lg"
        onClick={() => setIsDropdownOpen(true)}
      >
        {formData.requiredTests?.length > 0
          ? `${formData.requiredTests.length} Tests Selected`
          : "Pick the Required Tests"}
      </button>

      {isDropdownOpen && (
        <div className="fixed inset-0 z-30 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-[#11485B] md:w-[50%] w-[90%] h-[50%]  p-4 rounded-lg shadow-lg relative">
            <button
            type="button"
              className="absolute top-0 right-2 text-4xl text-gray-500"
              onClick={() => setIsDropdownOpen(false)}
            >
              &times;
            </button>


            <div className="p-4">
              <input
                className="w-full py-2 px-4 border rounded-lg"
                type="text"
                placeholder="Search Tests"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="px-2 h-[70%] overflow-y-auto">
              {filteredOptions.map((test) => (
                <div key={test.value} className="flex items-center p-2 hover:bg-gray-100 hover:text-black">
                  <div className="flex items-center justify-center w-10">
                    <input
                      type="checkbox"
                      checked={isSelected(test)}
                      onChange={() => handleSelect(test)}
                    />
                  </div>
                  <label
                    className="cursor-pointer"
                    onClick={() => handleSelect(test)}
                  >
                    {test.label}
                  </label>
                </div>
              ))}
              {/* <button
            className="w-full p-2 text-center bg-[#4ab0b0] text-white"
            onClick={() => setIsDropdownOpen(false)}
          >
            Done
          </button> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RequiredTestsDropdown;
