import React from 'react'

const Partners = () => {
    return (
        <div className='pt-10 relative'>
            <div className="absolute mt-[75px] h-full w-full -z-10 hidden lg:block">
                <img src="./assets/bg_blue.png" alt="" className="object-fill h-full w-full absolute top-0 -z-20" />
                <img src="./assets/bg_green.png" alt="" className="object-fill h-full w-full absolute top-0 -z-40" />
            </div>
            <div className="min-h-screen py-20 xl:px-[120px] px-5">
                <p className="lg:text-[36px] md:text-[28px] text-[24px] text-[#234971] font-[700] my-5 text-center">OUR PARTNERS</p>
                <p className="text-[#234971] lg:text-[24px] md:text-[20px] text-[18px] font-[400] text-justify">At <span className='font-[700]'>AQUA Medical Laboratories,</span> we are proud to collaborate with a network of esteemed partners who share our commitment to excellence in healthcare. Our partnerships with leading hospitals, research institutions, and healthcare providers enable us to deliver cutting-edge diagnostics and innovative solutions. Together, we strive to enhance patient care and advance medical science. Thank you to our partners for their invaluable support and collaboration.</p>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-10 mt-10 ">
                    <div className="shadow-xl w-[100%] md:h-[180px] h-[140px] flex justify-center items-center bg-white">
                        <img src="./assets/partners/img1.png" alt="image" className='w-[200px] h-ful' />
                    </div>
                    <div className="shadow-xl w-[100%] md:h-[180px] h-[140px] flex justify-center items-center bg-white">
                        <img src="./assets/partners/img2.png" alt="image" className='w-[200px] h-ful' />
                    </div>
                    <div className="shadow-xl w-[100%] md:h-[180px] h-[140px] flex justify-center items-center bg-white">
                        <img src="./assets/partners/img3.png" alt="image" className='w-[200px] h-ful' />
                    </div>
                    <div className="shadow-xl w-[100%] md:h-[180px] h-[140px] flex justify-center items-center bg-white">
                        <img src="./assets/partners/img4.png" alt="image" className='w-[200px] h-ful' />
                    </div>
                    <div className="shadow-xl w-[100%] md:h-[180px] h-[140px] flex justify-center items-center bg-white">
                        <img src="./assets/partners/img5.png" alt="image" className='w-[200px] h-ful' />
                    </div>
                    <div className="shadow-xl w-[100%] md:h-[180px] h-[140px] flex justify-center items-center bg-white">
                        <img src="./assets/partners/img6.png" alt="image" className='w-[200px] h-ful' />
                    </div>
                    <div className="shadow-xl w-[100%] md:h-[180px] h-[140px] flex justify-center items-center bg-white">
                        <img src="./assets/partners/img7.png" alt="image" className='w-[200px] h-ful' />
                    </div>
                    <div className="shadow-xl w-[100%] md:h-[180px] h-[140px] flex justify-center items-center bg-white">
                        <img src="./assets/partners/img8.png" alt="image" className='w-[200px] h-ful' />
                    </div>
                    <div className="shadow-xl w-[100%] md:h-[180px] h-[140px] flex justify-center items-center bg-white">
                        <img src="./assets/partners/img9.png" alt="image" className='w-[200px] h-ful' />
                    </div>
                    <div className="shadow-xl w-[100%] md:h-[180px] h-[140px] flex justify-center items-center bg-white">
                        <img src="./assets/partners/img10.png" alt="image" className='w-[100px] h-ful' />
                    </div>
                    <div className="shadow-xl w-[100%] md:h-[180px] h-[140px] flex justify-center items-center bg-white">
                        <img src="./assets/partners/img11.png" alt="image" className='w-[200px] h-ful' />
                    </div>
                    <div className="shadow-xl w-[100%] md:h-[180px] h-[140px] flex justify-center items-center bg-white">
                        <img src="./assets/partners/img12.png" alt="image" className='w-[200px] h-ful' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Partners
