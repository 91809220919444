import React from 'react'

function Termscon() {
    const handleGoBack = () => {
        window.history.back();
    };
    return (
        <>
            <div className=" lg:pt-[140px] pt-20 relative">
                <div className="flex justify-between xl:px-[120px] px-3 my-4">
                    <button
                        onClick={handleGoBack}
                        className="flex gap-4 items-center cursor-pointer w-[100px]"
                    >
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M19 11.4997H7.82999L12.71 6.61967C13.1 6.22967 13.1 5.58967 12.71 5.19967C12.6175 5.10697 12.5076 5.03342 12.3866 4.98324C12.2656 4.93306 12.136 4.90723 12.005 4.90723C11.874 4.90723 11.7443 4.93306 11.6234 4.98324C11.5024 5.03342 11.3925 5.10697 11.3 5.19967L4.70999 11.7897C4.61728 11.8822 4.54373 11.9921 4.49355 12.113C4.44337 12.234 4.41754 12.3637 4.41754 12.4947C4.41754 12.6256 4.44337 12.7553 4.49355 12.8763C4.54373 12.9973 4.61728 13.1072 4.70999 13.1997L11.3 19.7897C11.3926 19.8823 11.5025 19.9557 11.6234 20.0058C11.7444 20.0559 11.8741 20.0817 12.005 20.0817C12.1359 20.0817 12.2656 20.0559 12.3865 20.0058C12.5075 19.9557 12.6174 19.8823 12.71 19.7897C12.8026 19.6971 12.876 19.5872 12.9261 19.4662C12.9762 19.3453 13.002 19.2156 13.002 19.0847C13.002 18.9537 12.9762 18.8241 12.9261 18.7031C12.876 18.5822 12.8026 18.4723 12.71 18.3797L7.82999 13.4997H19C19.55 13.4997 20 13.0497 20 12.4997C20 11.9497 19.55 11.4997 19 11.4997Z"
                                fill="#202020"
                            />
                        </svg>
                        <p className="text-[#202020] font-[600]">Back</p>
                    </button>
                    <img src="../assets/logo3.png" alt="" className="w-[200px]" />
                </div>
                <div className="flex flex-col md:flexs-row justify-between items-start md:items-center">
                    <div className="bg-[#139EB5] w-full">
                        <div className="bg-white w-fit xl:mx-[120px] mx-3 px-2">
                            <p className="text-[#11485B]  md:text-[22px] text-center font-[700]">
                                Terms and Conditions                            </p>
                        </div>
                    </div>
                </div>
                <div className="mt-4 xl:px-[120px] px-3">
                    <div className="">
                        <p className="text-[14px] md:text-[20px] text-[#83d284]  font-semibold py-3">
                            1. Introduction
                        </p>
                        <p className="text-[14px] md:text-[20px] rich-content">
                            Welcome to Aqua Medical Laboratory. By accessing or using our services, you agree to be bound by these Terms and Conditions.                        </p>
                        <p className="text-[14px] md:text-[20px] text-[#83d284]  font-semibold py-3">
                            2. Services
                        </p>
                        <p className="text-[14px] md:text-[20px] rich-content">
                            Aqua Medical Laboratory provides a range of medical testing services. Detailed information about our services can be found on our website.
                        </p>

                        <p className="text-[14px] md:text-[20px] text-[#83d284]  font-semibold py-3">
                            3. User Responsibilities       </p>
                        <ul className='list-disc text-[14px] md:text-[20px] rich-content'>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>Eligibility:</span> You must be at least 18 years old to use our services
                            </li>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>Account Information:</span> Ensure that all information provided during registration is accurate and complete.
                            </li>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>Confidentiality: </span> Maintain the confidentiality of your account details and notify us immediately of any unauthorized access.</li>
                        </ul>
                    </div>

                    <div className="">
                        <p className="text-[14px] md:text-[20px] text-[#83d284]  font-semibold py-3">
                            4. Appointments and Cancellations
                        </p>

                        <ul className='list-disc text-[14px] md:text-[20px] rich-content'>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>Scheduling:</span> Appointments can be scheduled through our website or by contacting our customer service team.
                            </li>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'> Cancellation Policy:</span>  If you need to cancel or reschedule an appointment, please provide at least 24 hours' notice.
                            </li>


                        </ul>
                    </div>

                    <div className="">
                        <p className="text-[14px] md:text-[20px] text-[#83d284] font-semibold py-3">
                            5. Payment Terms
                        </p>

                        <ul className='list-disc text-[14px] md:text-[20px] rich-content'>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>Requesting a Quote:</span> To know the price for a specific test, you will first need to request a quote through our website.
                            </li>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>Team Contact:</span> After submitting your request, our team will contact you to confirm the details and provide the price for the particular test.
                            </li>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>Payment Methods:</span> Once the price is confirmed, payment can be made using various methods, including credit card or cash, at the time of service.
                            </li>
                        </ul>
                    </div>

                    <div className="">
                        <p className="text-[14px] md:text-[20px] text-[#83d284] font-semibold py-3">
                            6. Test Results
                        </p>

                        <ul className='list-disc text-[14px] md:text-[20px] rich-content'>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>Delivery Options:</span> The delivery method for your test results will be decided during your conversation with our team when requesting the quote. You can choose the method that best suits your needs.
                            </li>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>Result Availability:</span> Once the test is complete, the results will be provided to you according to the agreed method—either via email, online portal, or physical copy.
                            </li>
                        </ul>
                    </div>

                    <div className="">
                        <p className="text-[14px] md:text-[20px] text-[#83d284] font-semibold py-3">
                            7. Privacy Policy
                        </p>
                        <p className="text-[14px] md:text-[20px]">
                            Your privacy is important to us. Please refer to our <a href="#" className="text-[#A3D284] underline">Privacy Policy</a> for details on how we collect, use, and protect your personal information.
                        </p>
                    </div>

                    <div className="py-2">
                        <p className="text-[14px] md:text-[20px] text-[#83d284] font-semibold py-3">
                            8. Changes to Terms and Conditions
                        </p>
                        <p className="text-[14px] md:text-[20px]">
                            We reserve the right to update or modify these Terms and Conditions at any time. Any changes will be posted on our website.
                        </p>

                        <p className="text-[14px] mt-3 md:text-[20px]">

                        For any questions or concerns, please contact us at  <span className='text-[#83d284]'>info@aquamedlabs.com.</span></p>
                    </div>



                </div>
                <div className="bg-[#139EB5] w-full h-[40px]"></div>
            </div>
        </>
    )
}


export default Termscon