import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HeroSection from "./component/herosection";
import Login from "./component/login";
import Navbar from "./component/navbar";
import Register from "./component/register";
import ResetPassword from "./component/resetpassword";
import ForgetPassword from "./component/forgetpassword";
import Footer from "./component/footer";
import OurService from "./component/ourservice";
import SideBar from "./component/sidebar";
import Profile from "./component/profile";
import Blog from "./component/blog";
import ConsultDoctor from "./component/consultDoctor/consultDoctor";
import LoadingAnimation from "./component/loadinganimation/LoadingAnimation";
import { useEffect, useState } from "react";
import Feedback from "./component/feedback";
import Inquiries from "./component/myinquries";
import BlogDetail from "./component/blog/blogDetail";
import Cart from "./component/cart";
import AboutUs from "./component/aboutUs";
import PackageView from "./component/packageView";
import Gototop from "./component/gototop";
import Partners from "./component/partners";
import DoctorProfile from "./component/doctorProfile";
import ViewHistory from "./component/viewhistoryy";
import OurTeam from "./component/ourTeam/indx";
import TeamProfile from "./component/ourTeam/teamProfile";
import ViewBooking from "./component/viewBooking/index";
import PrivacyPolicy from "./component/privacypolicy";
import LegalNotice from "./component/legalnotice";
import DataProtection from "./component/dataProtection";
import Termscon from "./component/termscondition";
import Promotions from "./component/promotions";
import PromotionView from "./component/promotionView";
import PromotionPopup from "./component/promotionpoup";




function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [showPromotion, setShowPromotion] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const closePromotion = () => {
    setShowPromotion(false);
    console.log("showPromotion after close:", showPromotion); // This should print `false`
};

  return (
    <BrowserRouter>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <>          {showPromotion && <PromotionPopup onClose={closePromotion} />}

          <Gototop />
          <Navbar />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/forgetpassword" element={<ForgetPassword />} />
            <Route path="/ourservice" element={<OurService />} />
            {/* <Route path="/myprofile" element={<SideBar />} /> */}
            <Route path="/profile" element={<Profile />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/consult" element={<ConsultDoctor />} />
            <Route path="/inquries" element={<Inquiries />} />
            <Route path="/" element={<HeroSection />} />
            <Route path="/blogpost" element={<BlogDetail />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/ourservice/packageview" element={<PackageView />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/doctorProfile" element={<DoctorProfile />} />
            <Route path="/inquries/viewHistory" element={<ViewHistory />} />
            <Route path="/ourTeam" element={<OurTeam />} />
            <Route path="/teamProfile" element={<TeamProfile />} />
            <Route path="/viewBooking/:bookingId" element={<ViewBooking />} />
            <Route path="/privacyandpolicy" element={<PrivacyPolicy />} />
            <Route path="/legalNotice" element={<LegalNotice />} />
            <Route path="/dataProtection" element={<DataProtection />} />
            <Route path="/termscondition" element={<Termscon />} />
            <Route path="/promotions" element={<Promotions />} />
            <Route path="/promotions/promotionview" element={<PromotionView />} />



          </Routes>
          <Footer />
        </>
      )}

    </BrowserRouter>
  );
}

export default App;
