import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { jwtDecode } from "jwt-decode";
import DatePicker from "react-datepicker";
import axios from "axios";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { getData, getNames } from "country-list";
import Flag from "react-world-flags";

function Cart() {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const [popup, setPopup] = useState(false);
  const [book, setBook] = useState(false);
  const [packageView, setPackageView] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [token, setToken] = useState(null);
  const [popup1, setPopup1] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedDOB, setSelectedDOB] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const countries = getNames();
  const modifiedCountries = [...countries, 'United Kingdom'];
  const queryParams = new URLSearchParams(location.search);

  const subId = queryParams.get("subId");

  useEffect(() => {
    const token = localStorage.getItem("aqua");
    if (!token) {
      console.error("No token found");
      return;
    }

    const jwt = jwtDecode(token);
    console.log(jwt);

    fetch(`${baseUrl}/userInfo/getUserDetails`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch user details");
        }
      })
      .then((data) => {
        if (data && data.result) {
          // setUserInfo1(data.result)
          setFormData({
            title: data.result.Title || "",
            FirstName: data.result.FirstName || "",
            LastName: data.result.LastName || "",
            phoneNumber: data.result.PhoneNumber || "",
            email: data.result.Email || "",
            address1: data.result.ClinicAddress || "",
            // clinicName: data.result.ClinicName || '',
          });
        } else {
          console.error("API returned undefined result:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });

    const fakeDatafetch = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    fakeDatafetch();
  }, [baseUrl]);

  const [cartItems, setCartItems] = useState([]);
  console.log(cartItems, "cartget");
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    gender: "",
    phoneNumber: "",
    title: "",
    FirstName: "",
    LastName: "",
    email: "",
    address1: "",
    address2: "",
    postalcode: "",
    country: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (book) {
      document.body.classList.add("fixed-background");
    } else {
      document.body.classList.remove("fixed-background");
    }
    return () => document.body.classList.remove("fixed-background");
  }, [book]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const storedToken = localStorage.getItem("aqua");
    setToken(storedToken);
  }, []);

  useEffect(() => {
    const fetchServices = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${baseUrl}/home/viewcategory?subId=${subId}`
        );
        if (!response.ok) {
          throw new Error("Unable to fetch data");
        }
        const data = await response.json();
        setPackageView(data);
        console.log("Package data:", data);
      } catch (error) {
        console.error("Error fetching services:", error);
        setPackageView([]);
      } finally {
        setLoading(false);
      }
    };

    if (subId) {
      fetchServices();
    } else {
      setLoading(false);
    }
  }, [subId, baseUrl]);

  useEffect(() => {
    if (!token) return;

    const decodedToken = jwtDecode(token);

    const fetchCartItems = async () => {
      try {
        const response = await fetch(`${baseUrl}/cart/getcart`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch cart items");
        }

        const result = await response.json();
        setCartItems(result.cartItems || []);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCartItems();
  }, [token, baseUrl]);

  const handleBookNow = () => {
    setBook(!book);
    if (popup) {
      setPopup(false);
    }
  };

  const handleDelete = async (subId) => {
    const token = localStorage.getItem("aqua");

    try {
      const response = await fetch(`${baseUrl}/cart/deletecart`, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ subId: subId }),
      });

      if (response.ok) {
        setCartItems((prevCartItems) =>
          prevCartItems
            .map((item) => ({
              ...item,
              serviceDetails: item.serviceDetails.filter(
                (service) => service.subId !== subId
              ),
            }))
            .filter((item) => item.serviceDetails.length > 0)
        );
        window.location.reload();
      } else {
        console.error("Failed to delete item from cart");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const BookNow = () => {
    setBook(!book);
    if (popup) {
      setPopup(false);
    }
  };

  const handleDateChange = (date) => {
    if (date) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      const hours = date.getHours() % 12 || 12;
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const amPm = date.getHours() >= 12 ? "PM" : "AM";

      const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes} ${amPm}`;

      setSelectedTime(formattedDateTime);

      console.log("Formatted DateTime:", formattedDateTime);
    } else {
      console.error("Invalid date selected:", date);
    }
  };

  const handleDateChange1 = (date) => {
    if (date) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
      const year = date.getFullYear();

      // Format the date to DD-MM-YYYY
      const formattedDate = `${day}-${month}-${year}`;

      // Set the formatted date string in the state
      setSelectedDOB(formattedDate);

      console.log("Formatted DOB:", formattedDate);
    } else {
      console.error("Invalid date selected:", date);
    }
  };

  const handleBooking = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem("aqua");

    const subIds = cartItems
      .map((service) => service.serviceDetails.map((detail) => detail.subId))
      .flat();
    const testNames = cartItems
      .map((service) =>
        service.serviceDetails.map((detail) => detail.serviceName)
      )
      .flat();
    const fastings = cartItems
      .map((service) => service.serviceDetails.map((detail) => detail.fasting))
      .flat();
    const types = cartItems
      .map((service) => service.serviceDetails.map((detail) => detail.type))
      .flat();
    const testpacks = cartItems.map((service) => service.title);

    const bookingData = {
      patientfirstname: formData.FirstName,
      patienttitle: formData.title,
      patientlastname: formData.LastName,
      gender: formData.gender,
      patientNumber: formData.phoneNumber,
      patientemail: formData.email,
      Patientdob: selectedDOB,
      // bookingDate: selectedTime,
      serviceType: selectedOption,
      address: formData.address1,
      Country: formData.country,
      subId: subIds,
      TestName: testNames,
      Fasting: fastings,
      type: types,
      testpack: testpacks,
      addressLine1: formData.address1,
      addressLine2: formData.address2,
      postalCode: formData.postalcode,
    };

    try {
      const headers = {
        "Content-Type": "application/json",
      };

      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      const response = await axios.post(
        `${baseUrl}/booking/bookservice1`,
        bookingData,
        { headers }
      );

      if (response.data.isSuccess) {
        setPopup1(true);

        event.target.reset();
        setSelectedOption("");

        setFormData({
          gender: "",
          phoneNumber: "",
          title: "",
          FirstName: "",
          LastName: "",
          email: "",
          address1: "",
          address2: "",
          postalcode: "",
          country: "",
        });

        setTimeout(() => {
          setPopup1(false);
          window.location.reload();
        }, 2000);
      } else {
        setPopup1(true);
        setTimeout(() => {
          setPopup1(false);
        }, 2000);
      }
    } catch (error) {
      console.error(
        "Error creating booking:",
        error.response ? error.response.data.message : error.message
      );
      setPopup1(false);
      setPopup(false);
    } finally {
      setTimeout(() => {
        setPopup(false);
        setPopup1(false);
      }, 4000);
    }
  };

  const handlePhoneNumberChange = (phoneNumber) => {
    if (isValidPhoneNumber(phoneNumber)) {
      setFormData({ ...formData, phoneNumber });
    } else {
      console.error("Invalid phone number");
    }
  };

  const options = [
    { value: "homeService", label: "Home Service" },
    { value: "labService", label: "Lab Service" },
  ];

  const handleOptionClick = (option) => {
    setSelectedOption(option.label);
    setShowDropdown(false);
  };

  const countryOptions = getData().map((country) => ({
    value: country.code,
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Flag
          code={country.code}
          className="flag-img"
          style={{ width: "20px", marginRight: "30px" }}
        />
        {country.name}
      </div>
    ),
  }));

  const handleChange1 = (value) => {
    setSelectedCountry(value);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="min-h-screen h-full relative mb-10 overflow-hidden">
        <div className="h-full w-full absolute lg:top-[115px] top-[80px] left-0 -z-10">
          <div className="relative h-full w-full">
            {/* <img
              src="./assets/bg_blue.png"
              alt=""
              className="object-fill h-full w-full absolute top-0 -z-20"
            />
            <img
              src="./assets/bg_green.png"
              alt=""
              className="object-fill h-full w-full absolute top-0 -z-40"
            /> */}
          </div>
        </div>
        <div className="px-5 md:px-20 xl:px-28 mt-[130px] lg:mt-[160px]">
          <div className="flex justify-between">
            <button onClick={() => navigate(-1)} className="flex gap-2">
              {/* SVG for Back Button */}
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.0004 11.4997H7.83041L12.7104 6.61967C13.1004 6.22967 13.1004 5.58967 12.7104 5.19967C12.6179 5.10697 12.508 5.03342 12.387 4.98324C12.2661 4.93306 12.1364 4.90723 12.0054 4.90723C11.8744 4.90723 11.7448 4.93306 11.6238 4.98324C11.5028 5.03342 11.3929 5.10697 11.3004 5.19967L4.71041 11.7897C4.61771 11.8822 4.54416 11.9921 4.49398 12.113C4.4438 12.234 4.41797 12.3637 4.41797 12.4947C4.41797 12.6256 4.4438 12.7553 4.49398 12.8763C4.54416 12.9973 4.61771 13.1072 4.71041 13.1997L11.3004 19.7897C11.393 19.8823 11.5029 19.9557 11.6239 20.0058C11.7448 20.0559 11.8745 20.0817 12.0054 20.0817C12.1363 20.0817 12.266 20.0559 12.387 20.0058C12.5079 19.9557 12.6178 19.8823 12.7104 19.7897C12.803 19.6971 12.8764 19.5872 12.9265 19.4662C12.9766 19.3453 13.0024 19.2156 13.0024 19.0847C13.0024 18.9537 12.9766 18.8241 12.9265 18.7031C12.8764 18.5822 12.803 18.4723 12.7104 18.3797L7.83041 13.4997H19.0004C19.5504 13.4997 20.0004 13.0497 20.0004 12.4997C20.0004 11.9497 19.5504 11.4997 19.0004 11.4997Z"
                  fill="#202020"
                />
              </svg>

              <p>Back</p>
            </button>
            {cartItems.length > 0 && (
              <div
                onClick={BookNow}
                className="flex items-center justify-center h-[30px] md:h-[50px] w-[100px] md:w-[150px] px-3 py-2 bg-[#11485B] rounded-full cursor-pointer mt-4"
              >
                <p className="font-[600] text-[#A3D284] text-[10px] md:text-[16px]">
                  Request Quote
                </p>
              </div>
            )}
          </div>
          {book && (
            <div className="absolute top-0 p-2 lg:scroll left-0 w-full z-50 overflow-y-auto md:h-screen h-full bg-black bg-opacity-[80%] grid place-content-center figtree px-3 md:px-0">
              <div className="text-[#ffffff] border-[1px] border-white relative bg-[#11485B] z-50 w-full h-[70vh] max-w-xl md:max-w-2xl xl:max-w-3xl md:h-auto overflow-y-auto md:rounded-[30px] rounded-[20px] px-5 py-2">
                <form onSubmit={handleBooking}>
                  <div
                    className="absolute md:right-6 md:top-5 right-4 cursor-pointer"
                    onClick={BookNow}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>

                  <p className="text-[12px]   md:text-[18px] font-[600] md:py-1">
                    Enter the Patient Details
                  </p>
                  <p className="text-[12px] md:text-[18px] font-[300] my-2 text-justify">
                    Our administrative team will contact you using the details
                    provided. Please ensure all information entered is accurate.
                  </p>
                  <div className="md:flex gap-3 md:mt-0">
                    <div className="inputbox text-black">
                      <select
                        name="title"
                        id="title"
                        value={formData.title}
                        onChange={handleChange}
                        className="w-full h-12 px-4 border-2 rounded-md custom-select"
                        required
                      >
                        <option value="">Title</option>
                        <option value="Mr.">Mr.</option>
                        <option value="Mrs.">Mrs.</option>
                        <option value="Miss.">Miss.</option>
                        <option value="Dr.">Dr.</option>
                      </select>
                    </div>
                    <div className="inputbox">
                      <input
                        type="text"
                        name="FirstName"
                        value={formData.FirstName}
                        onChange={handleChange}
                        required
                      />
                      <span>First Name</span>
                      <i></i>
                    </div>
                  </div>

                  <div className="md:flex gap-3 md:mt-0">
                    <div className="inputbox">
                      <input
                        type="text"
                        name="LastName"
                        value={formData.LastName}
                        onChange={handleChange}
                        required
                      />
                      <span>Last Name</span>
                      <i></i>
                    </div>

                    <div className="inputbox custom-phone-input">
                      <PhoneInput
                        defaultCountry="GB"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handlePhoneNumberChange}
                        placeholder="Phone Number*"
                        international
                        withCountryCallingCode
                      />
                    </div>
                  </div>

                  <div className="md:flex gap-3 md:mt-0">
                    <div className="inputbox">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      <span>Email</span>
                      <i></i>
                    </div>

                    <div className="inputbox">
                      <div className="select-wrapper">
                        <select
                          name="gender"
                          value={formData.gender}
                          onChange={handleChange}
                          className="custom-select"
                          required
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                        <i className="highlight"></i>
                      </div>
                    </div>
                  </div>
                  <div className="inputbox">
                    <input
                      type="text"
                      name="address1"
                      value={formData.address1}
                      onChange={handleChange}
                      required
                    />
                    <span>Address Line1</span>
                    <i></i>
                  </div>
                  <div className="inputbox">
                    <input
                      type="text"
                      name="address2"
                      value={formData.address2}
                      onChange={handleChange}
                      required
                    />
                    <span>Address Line2</span>
                    <i></i>
                  </div>
                  <div className="md:flex gap-3 md:mt-0">
                    <div className="inputbox">
                      <input
                        type="text"
                        name="postalcode"
                        value={formData.postalcode}
                        onChange={handleChange}
                        required
                      />
                      <span>Postal Code</span>
                      <i></i>
                    </div>

                    <div className="inputbox z-10">
                      <div className="select-wrapper">
                        <select
                          name="country"
                          id="country"
                          value={formData.country}
                          onChange={handleChange}
                          className="w-full custom-select h-12 px-4 rounded-md"
                          required
                        >
                          <option value="">Select Country</option>
                          {modifiedCountries.map((country, index) => (
                            <option key={index} value={country}>
                              {country}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex gap-3 md:mt-0 ">
                    {/* <div className="inputboxDate border-[1px] border-[#4ab0b0] rounded-[6px] z-50">
                      <div className="!w-full">
                        <DatePicker
                          name="bookingDate"
                          selected={selectedTime ? new Date(selectedTime.split('-').reverse().join('-')) : null}
                          onChange={handleDateChange}
                          showTimeSelect
                          showTimeSelectOnly={false}
                          timeIntervals={15}
                          timeFormat="p"
                          dateFormat="MMMM d, yyyy h:mm a"
                          placeholderText="Select Date and Time"
                          className="!w-[280px] bg-transparent outline-none xl:text-[18px] text-[14px]"
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                          popperClassName="custom-datepicker" 

                        />
                      </div>
                    </div> */}
                    <div className="inputboxDate border-[1px] border-[#4ab0b0] rounded-[6px] z-20">
                      <DatePicker
                        name="dob"
                        selected={
                          selectedDOB
                            ? new Date(
                              selectedDOB.split("-").reverse().join("-")
                            )
                            : null
                        }
                        onChange={handleDateChange1}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="DOB (mm/dd/yyyy)"
                        className="!w-[280px] bg-transparent outline-none xl:text-[18px] text-[14px]"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={50}
                        popperClassName="custom-datepicker"
                      />
                    </div>
                  </div>

                  <div className="flex justify-center mt-4">
                    <button
                      type="submit"
                      className="text-[#11485B] bg-[#ffffff] lg:w-[300px] p-2 w-auto h-[40px] rounded-[20px] flex justify-center items-center cursor-pointer"
                    >
                      <p className="text-[12px]">Request Quote</p>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          <p className="text-center text-[26px] mt-4 text-[#234971] font-semibold">
            Your Cart
          </p>
          {error && <p className="text-red-500 text-center">{error}</p>}
          {cartItems.length === 0 ? (
            <p className="text-center text-[20px] text-[#234971] mt-40">
              No Item In Cart !!
            </p>
          ) : (
            cartItems.map((item, index) => (
              <div
                key={index}
                className="h-auto p-2 w-full bg-white shadow-xl border-2 border-black  flex cursor-pointer justify-between px-2 md:px-10 mt-10"
              >
                {Array.isArray(item.serviceDetails) &&
                  item.serviceDetails.map((subService, subIndex) => (
                    <div key={subIndex} className="flex items-center">
                      <p className="text-[12px] md:text-[18px] font-semibold text-[#202020]">
                        {subService.serviceName}
                      </p>
                    </div>
                  ))}
                {Array.isArray(item.serviceDetails) &&
                  item.serviceDetails.map((subService, subIndex) => (
                    <div
                      key={subIndex}
                      className="flex items-center gap-2 md:gap-5"
                    >
                      <Link
                        to={`/ourservice/packageview?subId=${subService.subId}`}
                      >
                        <button className="bg-[#234971] h-[30px] w-[80px] lg:w-[110px] md:h-[35px] xl:h-[40px] text-[10px] lg:text-[14px] text-white md:px-0 md:py-2 rounded-lg">
                          View Test
                        </button>
                      </Link>
                      <button
                        onClick={() => handleDelete(subService.subId)}
                        className="bg-[#234971] p-2 rounded"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="20px"
                          viewBox="0 -960 960 960"
                          width="20px"
                          fill="#e8eaed"
                        >
                          <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
                        </svg>
                      </button>
                    </div>
                  ))}
              </div>
            ))
          )}
        </div>
      </div>

      {popup1 && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-5 rounded-lg shadow-lg">
            <p className="text-lg font-semibold text-green-500">
              Booking Confirmed! , Team will contact you for further information
            </p>
            {/* <button
              onClick={() => setPopup1(false)}
              className="bg-[#1B5E20] text-white py-2 px-4 rounded-lg mt-3"
            >
              Close
            </button> */}
          </div>
        </div>
      )}

      {/* {popup1 && (
        <div className="fixed bottom-0 left-0 right-0 bg-green-500 text-white p-4 text-center">
          Booking Confirmed! , Team will contact you for further information
        </div>
      )} */}
    </>
  );
}

export default Cart;
