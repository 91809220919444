import React, { useEffect, useState } from "react";
import Loader from "../Loader";

function ConsultDoctor() {
  const backgroundImage = "url('/assets/ourservicebanner.jpg')";

  const [isOpen1, setIsOpen1] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState(null);

  const [isOpen2, setIsOpen2] = useState(false);
  const [selectedOption2, setSelectedOption2] = useState(null);

  const options1 = [
    { label: "Headache", value: "option1" },
    { label: "Fever", value: "option2" },
    { label: "Cold", value: "option3" },
    { label: "All", value: "option4" },
  ];

  const options2 = [
    { label: "One Day", value: "option4" },
    { label: "Two Days", value: "option5" },
    { label: "More Than Two Days", value: "option6" },
  ];

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
  };

  const handleOptionSelect1 = (option) => {
    setSelectedOption1(option);
    setIsOpen1(false);
  };

  const toggleDropdown2 = () => {
    setIsOpen2(!isOpen2);
  };

  const handleOptionSelect2 = (option) => {
    setSelectedOption2(option);
    setIsOpen2(false);
  };

  const [isloading, setIsloading] = useState(true);

  useEffect(() => {
      const fakeDatafetch = () => {
          setTimeout(() => {
              setIsloading(false);
          }, 1000)
      }
      fakeDatafetch();
  }, [])


  return   (
    <div>
      <div
        className="overflow-x-hidden md:h-screen h-[70vh] flex items-center justify-center relative"
        style={{
          backgroundImage: backgroundImage,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
        <div className="relative z-10">
          <p className="text-[#ffffff] text-[36px] md:text-[56px] font-semibold">
            CONSULTATION
          </p>
        </div>
      </div>

      <div className="flex justify-center mt-5">
        <p className="text-[20px] md:text-[30px] text-[#234971] font-semibold">
          Consult Doctor
        </p>
      </div>
      <div className="flex justify-center lg:mt-5 mb-10">
        <div className="px-2 md:px-10 lg:px-0 mt-10 lg:mt-0">
          <div className="relative">
            <img
              className="md:h-[520px] md:w-[800px] w-full h-[440px] rounded-[25px] relative"
              src="/assets/card.png"
              alt="secured-letter--v1"
            />
            <div className="absolute top-0 px-5 xl:px-20 md:w-full w-full mt-8">
              <div className="symptoms-input-container">
                <input
                  type={isOpen1 ? "text" : "symptoms"}
                  name="symptoms"
                  id="symptoms"
                  placeholder="Select Symptoms"
                  className="w-full h-12 px-4 placeholder-[#234971] border-2 rounded-md"
                  onClick={toggleDropdown1}
                  value={selectedOption1?.label || ""}
                />
                {isOpen1 && (
                  <ul className="dropdown-list bg-white text-center rounded-b-xl">
                    {options1.map((option) => (
                      <li
                        className="hover:bg-[#A3D284] rounded-full "
                        key={option.value}
                        onClick={() => handleOptionSelect1(option)}
                      >
                        {option.label}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="symptoms-input-container mt-10">
                <input
                  type={isOpen2 ? "text" : "days"}
                  name="days"
                  id="days"
                  placeholder="OverAll Time Period"
                  className="w-full h-12 px-4 placeholder-[#234971] border-2 rounded-md"
                  onClick={toggleDropdown2}
                  value={selectedOption2?.label || ""}
                />
                {isOpen2 && (
                  <ul className="dropdown-list bg-white text-center rounded-b-xl">
                    {options2.map((option) => (
                      <li
                        className="hover:bg-[#A3D284] rounded-full"
                        key={option.value}
                        onClick={() => handleOptionSelect2(option)}
                      >
                        {option.label}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className="flex justify-center mt-5">
                <button className="  text-[16px] lg:text-[20px] h-[55px] w-[150px] bg-[#ffffff] text-[#234971] hover:bg-[#234971] hover:text-[#ffffff] hover:border-[#ffffff] border-2 border-transparent border-[#234971] transition-all duration-300 rounded-[40px] font-[600]">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConsultDoctor;
