import React, { useState } from "react";

const ResuestSample = ({ formData, setFormData }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const testOptions = [
    { value: "(S): Serum", label: "(S): Serum" },
    { value: "(E): EDTA Blood", label: "(E): EDTA Blood" },
    { value: "(C): Citrate Blood", label: "(C): Citrate Blood" },
    { value: "(HP): Heparin Plasma", label: "(HP): Heparin Plasma" },
    { value: "(EP): EDTA Plasma", label: "(EP): EDTA Plasma" },
    { value: "(NF): Na Fluoride", label: "(NF): Na Fluoride" },
    { value: "(HCY): Homocysteine", label: "(HCY): Homocysteine" },
    { value: "(Se): Semen", label: "(Se): Semen" },
    { value: "(CSF): Cerebrospinal Fluid", label: "(CSF): Cerebrospinal Fluid" },
    { value: "(BAL): Bronchoalveolar Lavage", label: "(BAL): Bronchoalveolar Lavage" },
    { value: "(U): Urine", label: "(U): Urine" },
    { value: "(U24): 24hr Urine", label: "(U24): 24hr Urine" },
    { value: "(F): Feces/Stool", label: "(F): Feces/Stool" },
    { value: "(FC): Filter Card", label: "(FC): Filter Card" },
    { value: "(SV): Special Vial", label: "(SV): Special Vial" },
    { value: "(Sw): Swab", label: "(Sw): Swab" },
    { value: "(TP): Thin Prep", label: "(TP): Thin Prep" },
    { value: "(LBC): Liquid Based Cytology", label: "(LBC): Liquid Based Cytology" },
    { value: "(ST): Special Tubes", label: "(ST): Special Tubes" },
    { value: "(D.SB): Dry Swab", label: "(D.SB): Dry Swab" },
    
   

  ];

  const filteredOptions = testOptions.filter((test) =>
    test.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelect = (test) => {
    setFormData((prev) => {
      const currentRequiredTests = Array.isArray(prev.SerumTypes) ? prev.SerumTypes : [];

      const exists = currentRequiredTests.find((t) => t.value === test.value);
      if (exists) {
        return {
          ...prev,
         SerumTypes: currentRequiredTests.filter((t) => t.value !== test.value),
        };
      } else {
        return {
          ...prev,
         SerumTypes: [...currentRequiredTests, { ...test, fasting: false }],
        };
      }
    });
  };

  const isSelected = (test) => {
    const currentRequiredTests = Array.isArray(formData.SerumTypes) ? formData.SerumTypes : [];
    return currentRequiredTests.some((t) => t.value === test.value);
  };

  return (
    <>
      {/* Main Button to trigger the popup */}
      <button
      type="button"
        className="w-full py-[10px] px-4 border-[#4ab0b0] border-[1px] rounded-lg"
        onClick={() => setIsDropdownOpen(true)}
      >
        {formData.SerumTypes?.length > 0
          ? `${formData.SerumTypes.length} Sample Selected`
          : "Sample Types"}
      </button>

      {isDropdownOpen && (
        <div className="fixed inset-0 z-30 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-[#11485B] md:w-[50%] w-[90%] h-[50%]  p-4 rounded-lg shadow-lg relative">
          <button
          type="button"
              className="absolute top-0 right-2 text-4xl text-gray-500"
              onClick={() => setIsDropdownOpen(false)}
            >
              &times;
            </button>

            <div className="p-4">
              <input
                className="w-full py-2 px-4 border rounded-lg"
                type="text"
                placeholder="Search Tests"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="px-2 h-[70%] overflow-y-auto">
              {filteredOptions.map((test) => (
                <div key={test.value} className="flex items-center p-2 hover:bg-gray-100 hover:text-black">
                  <div className="flex items-center justify-center w-10">
                    <input
                      type="checkbox"
                      checked={isSelected(test)}
                      onChange={() => handleSelect(test)}
                    />
                  </div>
                  <label
                    className="cursor-pointer"
                    onClick={() => handleSelect(test)}
                  >
                    {test.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResuestSample;
