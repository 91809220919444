import { jwtDecode } from "jwt-decode";
import React, { useState, useEffect } from "react";
import {
  MdAssignment,
  MdCheckCircle,
  MdArrowBack,
  MdArrowForward,
} from "react-icons/md"; // Import pagination icons
import { Link } from "react-router-dom";

function Booking_History() {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const [bookings, setBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 3;

  useEffect(() => {
    fetchBookingHistory();
  }, []);

  const fetchBookingHistory = async () => {
    const token = localStorage.getItem("aqua");
    const jwt = jwtDecode(token);
    try {
      const response = await fetch(`${baseUrl}/booking/bookinghistory`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      const data = await response.json();
      if (data.isSuccess) {
        setBookings(data.bookings);
        setTotalPages(Math.ceil(data.bookings.length / itemsPerPage));
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error("Error fetching booking history:", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getPaginatedBookings = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return bookings.slice(startIndex, startIndex + itemsPerPage);
  };

  const groupBookings = (bookings) => {
    const grouped = [];
    for (let i = 0; i < bookings.length; i += 3) {
      grouped.push(bookings.slice(i, i + 3));
    }
    return grouped;
  };

  return (
    <div
      className="border xl:w-[866px] lg:w-[666px] md:w-[400px] xl:px-8 px-2 py-10 mt-4 rounded-xl mx-2 md:mx-0"
      style={{
        backgroundImage: "url('/assets/card.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {bookings.length === 0 ? (
        <p className="text-center text-[20px] text-[#FFFF] mt-0">
          No Request Available!
        </p>
      ) : (
        groupBookings(getPaginatedBookings()).map(
          (bookingGroup, groupIndex) => (
            <div key={groupIndex} className="mb-8">
              {bookingGroup.map((booking, index) => (
                <Link to={`/viewBooking/${booking.bookingId}`} key={index}>
                  <div className="bg-white w-full md:h-[100px] h-[80px] rounded-[10px] lg:px-5 px-2 flex justify-between mb-5 cursor-pointer">
                    <div className="lg:font-[700] flex items-center">
                      <div>
                        <div className="flex items-center">
                          <p className="text-[#234971] lg:text-[16px] text-[7px] mr-1">
                            RequestID-
                          </p>
                          <p className="text-[#234971] lg:text-[14px] text-[7px]">
                            {booking.bookingId}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-[#234971] lg:text-[16px] text-[7px] mr-1">
                          RequestDate:
                          </p>
                          <p className="text-[#234971] lg:text-[14px] text-[7px]">
                            {booking.bookingDate}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-center text-center py-6 w-[40%]">
                      <p className="text-[#234971] lg:text-[14px] text-[7px] font-[700]">
                        {booking.TestName}
                      </p>
                    </div>
                    {/* <div className="flex items-center">
                      <button
                        className={`border-[3px] rounded-[100px] lg:text-[14px] text-[7px] font-[700] px-2 ${
                          booking.bookingStatus === "Confirmed"
                            ? "bg-[#A3D284] text-white"
                            : "bg-[#A3D284] text-[#234971]"
                        }`}
                      >
                        <p>{booking.bookingStatus}</p>
                      </button>
                    </div> */}

                    <Link to={`/viewBooking/${booking.bookingId}`}>
                      <div className="flex cursor-pointer items-center">
                        <p
                          className="bg-[#ffffff] text-[#234971] px-4 py-1 mt-4 rounded-2xl hover:bg-[#234971] hover:text-[#ffffff] border-2 hover:border-[#ffffff] 
       text-[7px] md:text-base lg:text-lg 
       sm:px-4 md:px-6 
        sm:py-2 md:py-3 
       "
                        >
                          View Test
                        </p>
                      </div>
                    </Link>
                  </div>
                </Link>
              ))}
            </div>
          )
        )
      )}

      {bookings.length > 0 && (
        <div className="flex justify-center mt-4 items-center">
          <button
            onClick={() =>
              handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
            }
            className="mx-1 px-2 py-1 rounded bg-gray-200 text-black"
          >
            <MdArrowBack />
          </button>

          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`mx-1 px-2 py-1 rounded ${
                currentPage === index + 1
                  ? "bg-[#A3D284] text-white"
                  : "bg-gray-200 text-black"
              }`}
            >
              {index + 1}
            </button>
          ))}

          <button
            onClick={() =>
              handlePageChange(
                currentPage < totalPages ? currentPage + 1 : totalPages
              )
            }
            className="mx-1 px-2 py-1 rounded bg-gray-200 text-black"
          >
            <MdArrowForward />
          </button>
        </div>
      )}
    </div>
  );
}

export default Booking_History;
