import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Link } from "react-router-dom";

function Register() {
  const [value, setValue] = useState();
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;
  const backgroundImage = "url('/assets/card.png')";
  const [isClinicOwner, setIsClinicOwner] = useState("no");
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [data, setData] = useState({
    title: "",
    firstname: "",
    lastname: "",
    clinicName: "",
    clinicAddress: "",
    phoneNumber: "",
    email: "",
    password: "",
    confirmPassword: "",
    isClinicOwner: isClinicOwner,
    newsletter: false,  // Optional newsletter field
  });
  const [error, setError] = useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);  // State for terms checkbox
  const [acceptNewsletter, setAcceptNewsletter] = useState(false);  // State for newsletter checkbox

  const handleRadioChange = (e) => {
    const isOwner = e.target.value === "Yes";
    setIsClinicOwner(isOwner ? "yes" : "no");
    setData({ ...data, isClinicOwner: isOwner ? "yes" : "no" });
  };

  const handleChange = ({ currentTarget: input }) => {
    setData({
      ...data,
      [input.name]:
        input.name === "email" ? input.value.toLowerCase() : input.value,
    });
  };

  const handlePhoneNumberChange = (phoneNumber) => {
    setData({ ...data, phoneNumber });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the phone number
    if (!isValidPhoneNumber(data.phoneNumber)) {
      setError("Please enter a valid phone number.");
      return;
    }

    if (!acceptTerms) {
      setError("You must accept the terms and conditions.");
      return;
    }

    try {
      setError("");
      setSuccessMessage("");
      const url = `${baseUrl}/users/register`;
      const registrationData = { ...data, newsletter: acceptNewsletter }; // Include newsletter status
      const response = await axios.post(url, registrationData);

      console.log("Server Response:", response.data);

      if (response.data.success) {
        setSuccessMessage(response.data.message);
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        setSuccessMessage(response.data.message);
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      }
    } catch (err) {
      console.log("Error:", err);
      if (
        err.response &&
        err.response.status >= 400 &&
        err.response.status <= 500
      ) {
        setError(err.response.data.message);
      } else {
        setError("An unexpected error occurred.");
      }
    }
  };


  return (
    <div className="relative min-h-screen flex items-center justify-center py-14 p-2 overflow-x-hidden">
      {/* <div className="absolute top-4 md:top-[100px] lg:top-40 left-4 md:left-[20px] xl:left-[120px]">
        <img src="/assets/logo3.png" alt="Left Logo" className="w-16 md:w-48" />
      </div>

      <div className="absolute top-[60px] md:top-[46px] right-4 md:right-[8px]">
        <img
          src="/assets/log2.png"
          alt="Right Logo"
          className="w-20 md:w-[150px] lg:w-[306px]"
        />
      </div> */}

      <div
        className="bg-white p-10 mt-20 rounded-lg shadow-lg w-full max-w-md"
        style={{
          backgroundImage: backgroundImage,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h2 className="text-2xl font-bold mb-6 text-center text-[#ffff]">
          Register
        </h2>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div className="flex items-center ml-20">
            {/* <span className="text-[20px] font-bold text-center text-[#ffff]">
              Business Individual ?
            </span> */}

            <label className="inline-flex items-center ml-2">
              <input
                type="radio"
                name="clinicOwner"
                value="Yes"
                checked={isClinicOwner === "yes"}
                onChange={handleRadioChange}
                className="h-5 w-5 text-[#234971] border-[#234971] focus:ring-[#234971]"
              />
              <span className="ml-2 text-[#ffff]"> Business </span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                name="clinicOwner"
                value="No"
                checked={isClinicOwner === "no"}
                onChange={handleRadioChange}
                className="h-5 w-5 text-[#234971] border-[#234971] focus:ring-[#234971]"
              />
              <span className="ml-2 text-[#ffff]"> Individual </span>
            </label>
          </div>

          <div>
            <select
              value={data.title}
              onChange={handleChange}
              name="title"
              id="title"
              className="w-full h-12 px-4 placeholder-[#234971] border-2 rounded-md"
              required
            >
              <option value="" disabled>
                Title*
              </option>
              <option value="Mr.">Mr.</option>
              <option value="Mrs.">Mrs.</option>
              <option value="Miss.">Miss.</option>
              <option value="Dr.">Dr.</option>
            </select>

            <input
              value={data.firstname}
              onChange={handleChange}
              type="text"
              name="firstname"
              id="firstname"
              placeholder="First Name*"
              className="w-full h-12 px-4 mt-4 placeholder-[#234971] border-2 rounded-md"
              required
            />
            <input
              value={data.lastname}
              onChange={handleChange}
              type="text"
              name="lastname"
              id="lastname"
              placeholder="Last Name*"
              className="w-full h-12 px-4 mt-4 placeholder-[#234971] border-2 rounded-md"
              required
            />
          </div>
          {isClinicOwner === "yes" && (
            <>
              <div>
                <input
                  onChange={handleChange}
                  type="text"
                  name="clinicName"
                  id="clinicName"
                  placeholder="Business Name*"
                  className="w-full h-12 px-4 placeholder-[#234971] border-2 rounded-md"
                  required
                />
              </div>
              <div>
                <textarea
                  onChange={handleChange}
                  name="clinicAddress"
                  id="clinicAddress"
                  placeholder="Business Address*"
                  className="w-full h-[103px] px-4 placeholder-[#234971] text-black border-2 rounded-md"
                  required
                />
              </div>
            </>
          )}

          <div className="inputboxDatehome h-[40px] md:h-[50px] rounded-[6px] z-20">
            <PhoneInput
              defaultCountry="GB"
              name="phoneNumber"
              value={data.phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder="Phone Number*"
              className="w-full h-[40px] md:h-[50px] outline-none mb-4 text-[#234971] block indent-5 rounded-[5px]"
              international
              withCountryCallingCode
            />
          </div>
          {/* <div className="inputboxDatehome h-[40px] md:h-[50px] rounded-[6px] z-20">
            <PhoneInput
              defaultCountry="GB"
              name="phoneNumber"
               onChange={handleChange}
               value={data.phoneNumber}
              
              placeholder="phone number"
              className="w-full h-[40px] md:h-[50px] outline-none mb-4 text-[#234971] block indent-5 rounded-[5px]"
            />
          </div> */}
          <div>
            <input
              onChange={handleChange}
              value={data.email}
              type="email"
              name="email"
              id="email"
              placeholder="Email*"
              className="w-full h-12 px-4 placeholder-[#234971] border-2 rounded-md"
              required
            />
          </div>
          <div>
            <input
              onChange={handleChange}
              value={data.password}
              type="password"
              name="password"
              id="password"
              placeholder="Password*"
              className="w-full h-12 px-4 placeholder-[#234971] border-2 rounded-md"
              required
            />
          </div>
          <div>
            <input
              onChange={handleChange}
              value={data.confirmPassword}
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              placeholder="Confirm Password*"
              className="w-full h-12 px-4 placeholder-[#234971] border-2 rounded-md"
              required
            />
          </div>

          {/* Terms and Conditions Checkbox */}
          <div className="flex items-center">
            <input
              type="checkbox"
              id="acceptTerms"
              checked={acceptTerms}
              onChange={() => setAcceptTerms(!acceptTerms)}
              className="h-5 w-5 text-[#234971] border-[#234971] focus:ring-[#234971]"
              required
            />
            <label htmlFor="acceptTerms" className="ml-2  text-[#ffff] text-[13px] cursor-pointer">
              I accept the <span className="font-bold">  <a href="/termscondition" target="_blank" rel="noopener noreferrer">Terms and Conditions</a></span> with
              <a href="/privacyandpolicy" target="_blank" rel="noopener noreferrer">
                {" "}
                <span className="cursor-pointer font-bold">Privacy Policy</span>
              </a>
            </label>

          </div>

          {/* Newsletter Checkbox (Optional) */}
          <div className="flex items-center">
            <input
              type="checkbox"
              id="acceptNewsletter"
              checked={acceptNewsletter}
              onChange={() => setAcceptNewsletter(!acceptNewsletter)}
              className="h-5 w-5 text-[#234971] border-[#234971] focus:ring-[#234971]"
            />
            <label htmlFor="acceptNewsletter" className="ml-2 text-[13px] text-[#ffff] ">
              I would like to receive the newsletter
            </label>
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className="w-[200px] h-12 bg-[#ffffff] text-[#234971] hover:bg-[#234971] hover:text-[#ffffff] border-2 rounded-[40px] text-lg focus:outline-none focus:ring-2 focus:ring-[#234971] focus:ring-opacity-50"
            >
              Register
            </button>
          </div>
          <p className="text-white mt-4  text-[13px]">
            <h1>Password Requirements</h1>
            <ul>
              <li>At least 8 characters long</li>
              <li>
                Mix of uppercase and lowercase letters, numbers, and special
                characters (e.g., @, #, $, %)
              </li>
              <li>Should not have space</li>
            </ul>
          </p>
        </form>
        {error && <p className="error-message text-red-600">{error}</p>}
        {successMessage && (
          <p className="success-message text-green-600">{successMessage}</p>
        )}
      </div>
      <div className="h-full w-full absolute top-0 left-0 -z-10">

        <div className="relative h-full w-full">
          <img src="./assets/bg_blue.png" alt="" className="object-fill h-full w-full absolute top-0 -z-20" />
          <img src="./assets/bg_green.png" alt="" className="object-fill h-full w-full absolute top-0 -z-40" />
        </div>
      </div>
    </div>
  );
}

export default Register;
