import React from 'react';

const LoadingAnimation = () => {
  return (
    <div className="block w-full h-full fixed top-0 left-0 z-50">
    <div className="inset-0 flex items-center justify-center bg-white ">
        <div className="container">
            <div className='container '>
                <img src="../logo1.png" alt="" className="box-one h-[200px] w-[200px]" />
            </div>
        </div>
    </div>
</div>
  );
};

export default LoadingAnimation;
