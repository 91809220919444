import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useNavigation } from "react-router-dom";

const TeamProfile = () => {
    const baseUrl =
        process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_BASE_URL_DEV
            : process.env.REACT_APP_BASE_URL_PROD;

    console.log(baseUrl)
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const Id = queryParams.get('id');

    const [teamData, setTeamData] = useState({});

    useEffect(() => {
        const fetchDoctorData = async () => {
            try {
                const response = await fetch(`${baseUrl}/home//teamById?id=${Id}`);
                if (response.status === 200) {
                    const data = await response.json();
                    console.log("Fetched DOC data:", data);
                    setTeamData(data);
                } else {
                    throw new Error("Unable to fetch data");
                }
            } catch (err) {
                console.error(err);
            }
        };

        fetchDoctorData();
    }, []);

    return (
        <div>
            <div className="pt-20">
                <div className="xl:px-[120px] px-5 mt-20"
                >
                    <button onClick={() => navigate(-1)} className="flex gap-4 items-center cursor-pointer w-[100px] ">
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M19 11.4997H7.82999L12.71 6.61967C13.1 6.22967 13.1 5.58967 12.71 5.19967C12.6175 5.10697 12.5076 5.03342 12.3866 4.98324C12.2656 4.93306 12.136 4.90723 12.005 4.90723C11.874 4.90723 11.7443 4.93306 11.6234 4.98324C11.5024 5.03342 11.3925 5.10697 11.3 5.19967L4.70999 11.7897C4.61728 11.8822 4.54373 11.9921 4.49355 12.113C4.44337 12.234 4.41754 12.3637 4.41754 12.4947C4.41754 12.6256 4.44337 12.7553 4.49355 12.8763C4.54373 12.9973 4.61728 13.1072 4.70999 13.1997L11.3 19.7897C11.3926 19.8823 11.5025 19.9557 11.6234 20.0058C11.7444 20.0559 11.8741 20.0817 12.005 20.0817C12.1359 20.0817 12.2656 20.0559 12.3865 20.0058C12.5075 19.9557 12.6174 19.8823 12.71 19.7897C12.8026 19.6971 12.876 19.5872 12.9261 19.4662C12.9762 19.3453 13.002 19.2156 13.002 19.0847C13.002 18.9537 12.9762 18.8241 12.9261 18.7031C12.876 18.5822 12.8026 18.4723 12.71 18.3797L7.82999 13.4997H19C19.55 13.4997 20 13.0497 20 12.4997C20 11.9497 19.55 11.4997 19 11.4997Z"
                                fill="#202020"
                            />
                        </svg>
                        <p className="text-[#202020] lg:md:text-[24px] font-[600]">
                            Back
                        </p>
                    </button>
                </div>
                <div className="min-h-screen lg:py-10 relative">
                    {/* <img
                        src="/assets/logo1.png"
                        alt=""
                        className="absolute inset-0 w-[300px] lg:w-[500px]   object-cover opacity-40  mx-auto my-auto"
                    /> */}
                    {/* <div className="absolute md:top-20 top-12 xl:px-[120px] px-5"
                    >
                        <button onClick={() => navigate(-1)} className="flex gap-4 items-center cursor-pointer w-[100px] ">
                            <svg
                                width="24"
                                height="25"
                                viewBox="0 0 24 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M19 11.4997H7.82999L12.71 6.61967C13.1 6.22967 13.1 5.58967 12.71 5.19967C12.6175 5.10697 12.5076 5.03342 12.3866 4.98324C12.2656 4.93306 12.136 4.90723 12.005 4.90723C11.874 4.90723 11.7443 4.93306 11.6234 4.98324C11.5024 5.03342 11.3925 5.10697 11.3 5.19967L4.70999 11.7897C4.61728 11.8822 4.54373 11.9921 4.49355 12.113C4.44337 12.234 4.41754 12.3637 4.41754 12.4947C4.41754 12.6256 4.44337 12.7553 4.49355 12.8763C4.54373 12.9973 4.61728 13.1072 4.70999 13.1997L11.3 19.7897C11.3926 19.8823 11.5025 19.9557 11.6234 20.0058C11.7444 20.0559 11.8741 20.0817 12.005 20.0817C12.1359 20.0817 12.2656 20.0559 12.3865 20.0058C12.5075 19.9557 12.6174 19.8823 12.71 19.7897C12.8026 19.6971 12.876 19.5872 12.9261 19.4662C12.9762 19.3453 13.002 19.2156 13.002 19.0847C13.002 18.9537 12.9762 18.8241 12.9261 18.7031C12.876 18.5822 12.8026 18.4723 12.71 18.3797L7.82999 13.4997H19C19.55 13.4997 20 13.0497 20 12.4997C20 11.9497 19.55 11.4997 19 11.4997Z"
                                    fill="#202020"
                                />
                            </svg>
                            <p className="text-[#202020] lg:md:text-[24px] font-[600]">
                                Back
                            </p>
                        </button>
                    </div> */}

                    <div className="relative">
                        <div className="flex-col flex items-censter justify-center xl:px-[120px]">
                            {/* <div className="flex items-censter justify-center w-full px-5 lg:px-0">
                                <div className="lg:w-[40%] w-[60%] h-full">
                                    <img
                                        src="./assets/team/1.jpg"
                                        alt=""
                                        className="object-cover h-[540px] lg:w-[80%]"
                                    />
                                    <p className="lg:text-[28px] md:text-[28px] text-[24px] font-[700] ">
                                        Savannah Nguven
                                    </p>
                                    <p className="lg:text-[24px] md:text-[18px] text-[16px] font-[400]">
                                        HCPC Registered Biomedical Scientist
                                    </p>
                                </div>
                            </div> */}
                            <div className="relative mt-5 ">
                                <div className="lg:flex items-center">
                                    <div className="h-[200%] absoslute left-0 p-4 md:p-18 lg:p-0 flex justify-center">
                                        <img
                                            src={teamData.img}
                                            alt=""
                                            className="object-cover rounded-xl lg:h-[500px] lg:w-[400px] h-[300px] w-[230px] md:h-[340px] md:w-[260px] transition-transform duration-300 ease-in-out transform group-hover:scale-105"
                                        />
                                    </div>
                                    <div className="bg-[#C4D6E0]  text-[#234971] lg:bg-transparent lg:w-[75%] text-center lg:text-start inter lg:pl-5 py-3 lg:py-0">
                                        <p className="lg:text-[22px] md:text-[18px] text-[16px] font-[700] ">
                                            {teamData.name}                                </p>
                                        <p className="lg:text-[22px] md:text-[18px] text-[#234971] text-[16px] font-[400]">
                                            {teamData.position}
                                        </p>
                                        {/* <div className="lg:flex items-center lg:gap-5">
                                            <div className="flex justify-center gap-3 items-center">
                                                <svg
                                                    width="20"
                                                    height="16"
                                                    viewBox="0 0 20 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1 3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H17C17.5304 1 18.0391 1.21071 18.4142 1.58579C18.7893 1.96086 19 2.46957 19 3V13C19 13.5304 18.7893 14.0391 18.4142 14.4142C18.0391 14.7893 17.5304 15 17 15H3C2.46957 15 1.96086 14.7893 1.58579 14.4142C1.21071 14.0391 1 13.5304 1 13V3Z"
                                                        stroke="black"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M1 3L10 9L19 3"
                                                        stroke="black"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                                <p className="">
                                                    <i className=" text-[14px] md:text-[16px] font-[400] py-3">
                                                        info@aquamedlabs.com
                                                    </i>
                                                </p>


                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="hidden lg:block bg-[#139EB5] w-full h-[200px] absolute center left-0 -z-10"></div>
                                </div>
                            </div>
                            <div className="px-5 py-4 text-[#202020]">
                                {/* <p className="xl:text-[30px] md:text-[20px] text-[18px] font-[600] ">About me</p> */}
                                <p className="md:text-[20px] text-justify text-[14px] font-[400] team"
                                    dangerouslySetInnerHTML={{ __html: (teamData.description || '').replace(/\n/g, '<br>') }}>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamProfile;
