import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import Card from "../card";

const Blog = () => {
  const navigate = useNavigate();

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  AOS.init();

  const [blog, setBlog] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(`${baseUrl}/blog/blogs`);
        if (response.status === 200) {
          const data = await response.json();
          //   console.log("Fetched Blog data:", data);
          setBlog(data);
        } else {
          throw new Error("Unable to fetch data");
        }
      } catch (err) {
        console.error(err);
        setBlog([]);
      }
    };

    fetchBlogs();
  }, []);

  const backgroundImage = "url('/assets/blog/blog_hero.png')";
  const [isloading, setIsloading] = useState(true);

  useEffect(() => {
    const fakeDatafetch = () => {
      setTimeout(() => {
        setIsloading(false);
      }, 2000);
    };
    fakeDatafetch();
  }, []);

  return (
    <div className="pt-20 lg:pt-[140px] relative">
      <div className="absolute -mt-[5px] h-full w-full -z-10 hidden xl:block">
        <img src="./assets/bg_blue.png" alt="" className="object-fill h-full w-full absolute top-0 -z-20" />
        <img src="./assets/bg_green.png" alt="" className="object-fill h-full w-full absolute top-0 -z-40" />
      </div>
      <Link to="/">
        <div className="xl:px-[120px] px-5">
          <div className="flex gap-4 items-center cursor-pointer w-[100px] ">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 11.4997H7.82999L12.71 6.61967C13.1 6.22967 13.1 5.58967 12.71 5.19967C12.6175 5.10697 12.5076 5.03342 12.3866 4.98324C12.2656 4.93306 12.136 4.90723 12.005 4.90723C11.874 4.90723 11.7443 4.93306 11.6234 4.98324C11.5024 5.03342 11.3925 5.10697 11.3 5.19967L4.70999 11.7897C4.61728 11.8822 4.54373 11.9921 4.49355 12.113C4.44337 12.234 4.41754 12.3637 4.41754 12.4947C4.41754 12.6256 4.44337 12.7553 4.49355 12.8763C4.54373 12.9973 4.61728 13.1072 4.70999 13.1997L11.3 19.7897C11.3926 19.8823 11.5025 19.9557 11.6234 20.0058C11.7444 20.0559 11.8741 20.0817 12.005 20.0817C12.1359 20.0817 12.2656 20.0559 12.3865 20.0058C12.5075 19.9557 12.6174 19.8823 12.71 19.7897C12.8026 19.6971 12.876 19.5872 12.9261 19.4662C12.9762 19.3453 13.002 19.2156 13.002 19.0847C13.002 18.9537 12.9762 18.8241 12.9261 18.7031C12.876 18.5822 12.8026 18.4723 12.71 18.3797L7.82999 13.4997H19C19.55 13.4997 20 13.0497 20 12.4997C20 11.9497 19.55 11.4997 19 11.4997Z"
                fill="#202020"
              />
            </svg>
            <p className="text-[#202020] lg:md:text-[24px] font-[600]">
              Back
            </p>
          </div>
        </div>
      </Link>
      <div className="flex justify-center relative my-[100px]">
        {/* <div className="absolute inset-0 flex flex-col justify-between p-2">
          <div className="flex gap-2 items-center cursor-pointer w-[100px]" onClick={() => navigate(-1)}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 11.4997H7.82999L12.71 6.61967C13.1 6.22967 13.1 5.58967 12.71 5.19967C12.6175 5.10697 12.5076 5.03342 12.3866 4.98324C12.2656 4.93306 12.136 4.90723 12.005 4.90723C11.874 4.90723 11.7443 4.93306 11.6234 4.98324C11.5024 5.03342 11.3925 5.10697 11.3 5.19967L4.70999 11.7897C4.61728 11.8822 4.54373 11.9921 4.49355 12.113C4.44337 12.234 4.41754 12.3637 4.41754 12.4947C4.41754 12.6256 4.44337 12.7553 4.49355 12.8763C4.54373 12.9973 4.61728 13.1072 4.70999 13.1997L11.3 19.7897C11.3926 19.8823 11.5025 19.9557 11.6234 20.0058C11.7444 20.0559 11.8741 20.0817 12.005 20.0817C12.1359 20.0817 12.2656 20.0559 12.3865 20.0058C12.5075 19.9557 12.6174 19.8823 12.71 19.7897C12.8026 19.6971 12.876 19.5872 12.9261 19.4662C12.9762 19.3453 13.002 19.2156 13.002 19.0847C13.002 18.9537 12.9762 18.8241 12.9261 18.7031C12.876 18.5822 12.8026 18.4723 12.71 18.3797L7.82999 13.4997H19C19.55 13.4997 20 13.0497 20 12.4997C20 11.9497 19.55 11.4997 19 11.4997Z"
                fill="#000000"
              />
            </svg>
            <p className="text-black font-[600]">Back</p>
          </div>
        </div> */}

        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 xl:gap-10 lg:gap-5 gap-7">
          {blog.map((item, index) => (
            <Card
              key={index}
              image={item.img}
              title={item.title}
              link={item.link}
              id={item.blogId}


            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;
