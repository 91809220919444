import React from "react";
import { Link } from "react-router-dom";

const PromotionPopup = ({ onClose }) => {
    const handleClose = (e) => {
        e.stopPropagation();
        console.log("Closing promotion popup"); 
        onClose();
    };

    const handleLinkClick = () => {
        onClose(); 
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div
                className="relative bg-cover mt-20 bg-center rounded-lg w-[300px] md:w-[600px] h-[320px] md:h-[350px] flex flex-col justify-center text-center shadow-lg p-4"
                style={{
                    backgroundImage: "url('/assets/card.png')",
                }}
            >
               
                {/* Popup Content */}
                <div className="absolute inset-0 flex flex-col justify-center items-center text-white z-50 bg-opacity-50 rounded-lg p-2">
                <div className="absolute top-4 right-4">
                    <button
                        onClick={handleClose}
                        className="text-xl px-3 py-1 bg-[#234971] text-white rounded-full hover:bg-[#1f3a55] transition duration-200"
                    >
                        &times; 
                    </button>
                </div>

                    <h2 className="text-[28px] md:text-[32px] font-bold mb-4">Special Promotion!</h2>
                    <p className="text-white mb-6 text-[20px] md:text-[28px]">
                        Enjoy our exclusive offers available for a limited time!
                    </p>

                    <Link to={`/promotions`} onClick={handleLinkClick}>
                        <button className="flex items-center text-[13px] border md:text-[16px] justify-between md:pr-2 pr-1 md:pl-2 pl-2 md:h-[40px] mt-2 h-[35px] w-auto bg-white text-[#234971] hover:bg-[#234971] hover:text-white transition-all duration-300 rounded-[40px] font-[600]">
                            View Promotion
                            <img
                                src="./assets/hero/more.png"
                                alt=""
                                className="bg-[#234971] h-[20px] ml-2 md:h-[30px] rounded-full md:p-2 p-1"
                            />
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default PromotionPopup;
