import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-number-input";
import { getNames } from "country-list";
import PromotionselectDropdown from "../promotionselect";
import { jwtDecode } from "jwt-decode";
import { Link } from "react-router-dom";

const Promotions = () => {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    gender: "",
    address1: "",
    address2: "",
    postalCode: "",
    country: "",
    dob: null,
    requiredTests: [],
  });

  const countries = getNames();
  const modifiedCountries = [...countries, "United Kingdom"];

  const [book, setBook] = useState(false);
  const [book1, setBook1] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [message, setMessage] = useState("");

  const BookNow = () => {
    setBook(!book);
    setBook1(!book1);
  };

  useEffect(() => {
    if (book1) {
      document.body.classList.add("fixed-background");
    } else {
      document.body.classList.remove("fixed-background");
    }
    return () => document.body.classList.remove("fixed-background");
  }, [book1]);

  const openImage = (image) => {
    setSelectedImage(image);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneNumberChange = (value) => {
    setFormData({ ...formData, phoneNumber: value });
  };

  useEffect(() => {
    const token = localStorage.getItem("aqua");
    if (!token) {
      console.error("No token found");
      return;
    }

    const jwt = jwtDecode(token);
    console.log(jwt);

    fetch(`${baseUrl}/userInfo/getUserDetails`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch user details");
        }
      })
      .then((data) => {
        if (data && data.result) {
          setFormData({
            title: data.result.Title || "",
            firstName: data.result.FirstName || "",
            lastName: data.result.LastName || "",
            phoneNumber: data.result.PhoneNumber || "",
            email: data.result.Email || "",
            address1: data.result.ClinicAddress || "",
            clinicName: data.result.ClinicName || "",
          });
        } else {
          console.error("API returned undefined result:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });

    const fakeDatafetch = () => {
      setTimeout(() => {
        // setIsLoading(false);
      }, 1000);
    };
    fakeDatafetch();
  }, [baseUrl]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${baseUrl}/booking/promotions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);
      // Reset form or handle success (e.g., show a success message)
      setFormData({
        title: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        gender: "",
        address1: "",
        address2: "",
        postalCode: "",
        country: "",
        dob: null,
        requiredTests: "",
      });
      BookNow(); // Close the form modal after submission
    } catch (error) {
      console.error("Error:", error);
      // Handle error (e.g., show an error message)
    }
  };
  const handleViewFormClick = () => {
    setBook(!book);
    setBook1(!book1);
  };

  const handleGoBack = () => {
    window.history.back();
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchPromotionData = async () => {
      try {
        const response = await fetch(`${baseUrl}/promotion/get-promotions`);
        if (response.status === 200) {
          const data = await response.json();
          console.log("Fetched promotion data:", data);
          console.log("Promotion ID from data:", data.promotionId);

          setData(data.result);
          
        } else {
          throw new Error("Unable to fetch data");
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchPromotionData();
  }, []);

  return (
    <>
      <div className="relative min-h-auto ">
        <div className="pt-20 lg:pt-[140px]">
          <div className="w-full">
            <div className="flex justify-between xl:px-[120px]  px-3 my-4">
              <button
                onClick={handleGoBack}
                className="flex gap-2 items-center cursor-pointer w-[100px]"
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 11.4997H7.82999L12.71 6.61967C13.1 6.22967 13.1 5.58967 12.71 5.19967C12.6175 5.10697 12.5076 5.03342 12.3866 4.98324C12.2656 4.93306 12.136 4.90723 12.005 4.90723C11.874 4.90723 11.7443 4.93306 11.6234 4.98324C11.5024 5.03342 11.3925 5.10697 11.3 5.19967L4.70999 11.7897C4.61728 11.8822 4.54373 11.9921 4.49355 12.113C4.44337 12.234 4.41754 12.3637 4.41754 12.4947C4.41754 12.6256 4.44337 12.7553 4.49355 12.8763C4.54373 12.9973 4.61728 13.1072 4.70999 13.1997L11.3 19.7897C11.3926 19.8823 11.5025 19.9557 11.6234 20.0058C11.7444 20.0559 11.8741 20.0817 12.005 20.0817C12.1359 20.0817 12.2656 20.0559 12.3865 20.0058C12.5075 19.9557 12.6174 19.8823 12.71 19.7897C12.8026 19.6971 12.876 19.5872 12.9261 19.4662C12.9762 19.3453 13.002 19.2156 13.002 19.0847C13.002 18.9537 12.9762 18.8241 12.9261 18.7031C12.876 18.5822 12.8026 18.4723 12.71 18.3797L7.82999 13.4997H19C19.55 13.4997 20 13.0497 20 12.4997C20 11.9497 19.55 11.4997 19 11.4997Z"
                    fill="#202020"
                  />
                </svg>
                <p className="text-[#202020] hidden md:block font-[600] text-[14px] md:text-[22px]  ">
                  Back
                </p>
              </button>
              <p className="text-[#11485B] text-[14px] mt-4  md:text-[22px] text-center md:text-left font-semibold whitespace-nowrap mr-[80px] md:ml-10">
                Our Promotions
              </p>



              <div>
                <div>
                  {/* <img
                    src="../assets/logo3.png"
                    alt=""
                    className="w-[200px] hidden md:block"
                  /> */}
                </div>
                <div
                  onClick={BookNow}
                  className=" mt-2 ml-3 flex items-center justify-center h-[30px] md:h-[50px] w-auto px-3 py-2 bg-[#11485B] rounded-full cursor-pointer"
                >
                  <p className="font-[600] text-[#A3D284] text-[10px] md:text-[16px]">
                    Promotion Request
                  </p>
                </div>

                {book && (
                  <div className="absolute top-0 p-4 z-50 scroll left-0 w-full h-screen bg-black bg-opacity-[40%] grid place-content-center figtree px-3 md:px-0">
                    <div className="text-[#ffffff] border-[1px] border-white relative lg:stop-10 bg-[#11485B] h-auto z-50 xl:w-[900px] xl:h-[660px] lg:w-[700px] lg:h-[620px] md:h-[700px] min-w-[300px] mt-[190px] md:rounded-[30px] rounded-[20px] lg:px-10 px-5 py-2">
                      <div
                        className="absolute md:right-6 md:top-5 right-4 cursor-pointer"
                        onClick={handleViewFormClick}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                      <form onSubmit={handleSubmit}>
                        <p className="text-[14px] md:text-[18px] font-[600] md:py-1">
                          Enter the Patient Details
                        </p>
                        <div className="md:flex gap-3 md:mt-0">
                          <div className="inputbox text-black mt-4">
                            <select
                              name="title"
                              value={formData.title}
                              onChange={handleInputChange}
                              className="w-full h-12 px-4 border-2 rounded-md custom-select"
                              required
                            >
                              <option value="">Title</option>
                              <option value="Mr.">Mr.</option>
                              <option value="Mrs.">Mrs.</option>
                              <option value="Miss.">Miss.</option>
                              <option value="Dr.">Dr.</option>
                            </select>
                          </div>
                          <div className="inputbox mt-4">
                            <input
                              type="text"
                              name="firstName"
                              value={formData.firstName}
                              onChange={handleInputChange}
                              required
                            />
                            <span>First Name</span>
                            <i></i>
                          </div>
                        </div>
                        <div className="md:flex gap-3 md:mt-0">
                          <div className="inputbox mt-4">
                            <input
                              type="text"
                              name="lastName"
                              value={formData.lastName}
                              onChange={handleInputChange}
                              required
                            />
                            <span>Last Name</span>
                            <i></i>
                          </div>
                          <div className="inputbox custom-phone-input mt-4">
                            <PhoneInput
                              defaultCountry="GB"
                              name="phoneNumber"
                              value={formData.phoneNumber}
                              onChange={handlePhoneNumberChange}
                              placeholder="Phone Number*"
                              international
                              withCountryCallingCode
                            />
                          </div>
                        </div>
                        <div className="md:flex gap-3 md:mt-0">
                          <div className="inputbox mt-4">
                            <input
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleInputChange}
                              required
                            />
                            <span>Email</span>
                            <i></i>
                          </div>
                          <div className="inputbox mt-4">
                            <select
                              name="gender"
                              value={formData.gender}
                              onChange={handleInputChange}
                              className="custom-select"
                              required
                            >
                              <option value="">Select Gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                        <div className="inputbox mt-4">
                          <input
                            type="text"
                            name="address1"
                            value={formData.address1}
                            onChange={handleInputChange}
                            required
                          />
                          <span>Address Line1</span>
                          <i></i>
                        </div>
                        <div className="inputbox mt-8">
                          <input
                            type="text"
                            name="address2"
                            value={formData.address2}
                            onChange={handleInputChange}
                            required
                          />
                          <span>Address Line2</span>
                          <i></i>
                        </div>
                        <div className="md:flex gap-3 md:mt-0">
                          <div className="inputbox mt-4">
                            <input
                              type="text"
                              name="postalCode"
                              value={formData.postalCode}
                              onChange={handleInputChange}
                              required
                            />
                            <span>Postal Code</span>
                            <i></i>
                          </div>
                          <div className="inputbox mt-4">
                            <select
                              name="country"
                              value={formData.country}
                              onChange={handleInputChange}
                              className="custom-select"
                              required
                            >
                              <option value="">Select Country</option>
                              {modifiedCountries.map((country, index) => (
                                <option key={index} value={country}>
                                  {country}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="md:flex gap-3 md:mt-0">
                          <div className="inputboxDate">
                            <div className="mt-4 inputboxDate border-[1px] border-[#4ab0b0] rounded-[6px] z-20">
                              <DatePicker
                                selected={formData.dob}
                                onChange={(date) =>
                                  setFormData({ ...formData, dob: date })
                                }
                                placeholderText="Date of Birth"
                                className="w-full h-[40px] outline-none text-[#234971] block indent-5 rounded-[5px]"
                              />
                            </div>
                          </div>
                          <div className="inputbox mt-4">
                            <div className="select-wrapper">
                              <PromotionselectDropdown
                                formData={formData}
                                setFormData={setFormData}
                              />
                              <ul className="mt-2">
                                {Array.isArray(formData.requiredTests) &&
                                  formData.requiredTests.length > 0 &&
                                  formData.requiredTests.map((test) => (
                                    <li key={test.value}>{test.label}</li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-center">
                          <button
                            type="submit"
                            className="text-[#11485B] bg-[#ffffff] lg:w-[300px] p-2 w-auto h-[40px] rounded-[20px] flex justify-center items-center cursor-pointer"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
                {/* {book1 && (
                  <div className="absolute top-0 left-0 bg-black bg-opacity-[80%] w-full h-screen z-40"></div>
                )} */}
                {showMessagePopup && (
                  <div className="absolute top-0 p-4 z-50 scroll left-0 w-full h-screen bg-black bg-opacity-[40%] grid place-content-center figtree px-3 md:px-0">
                    <div className="text-[#ffffff] border-[1px] border-white relative lg:stop-10 bg-[#11485B] h-auto z-50 xl:w-[500px] lg:w-[400px] md:w-[300px] min-w-[200px] mt-[190px] md:rounded-[30px] rounded-[20px] lg:px-10 px-5 py-2">
                      <p className="text-center text-lg font-bold">{message}</p>
                      <div className="flex justify-center mt-4">
                        <button
                          onClick={() => setShowMessagePopup(false)}
                          className="text-[#11485B] bg-[#ffffff] p-2 rounded-[20px] cursor-pointer"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="absolute -mt-[80px] h-full w-full -z-10 hidden xl:block">
            <img
              src="./assets/bg_blue.png"
              alt=""
              className="object-fill h-full w-full absolute top-0 -z-20"
            />
            <img
              src="./assets/bg_green.png"
              alt=""
              className="object-fill h-full w-full absolute top-0 -z-40"
            />
          </div>
        </div>

        <div className="px-4 mt-4">
          <div className="relative px-3 pt-[40px]">
            <div className="overflow-hidden min-h-screen h-full relative">
              <div className="mb-8">
                <div className="gap-10 flex flex-wrap justify-center">
                  {data.map((card, index) => (
                    <div
                      key={index}
                      className="px-4"
                    // data-aos="zoom-in"
                    // data-aos-duration="1000"
                    >
                      <div className="relative h-[220px] md:h-[240px] xl:w-[268px]">
                        <img
                          className="h-full w-full rounded-[10px] object-cover"
                          src="/assets/card.png"
                          alt="service"
                        />
                        <div className="absolute top-0 px-4 h-[80%] overflow-hidden ml-3">
                          <p className="font-bold py-4 md:text-[18px] text-[#FFFFFF] mt-5 text-center">
                            {card.thumpTitle}
                          </p>
                        </div>
                        <div className="flex justify-center mt-3 md:mt-0 xl:mt-7 absolute bottom-5 w-full">
                          <Link
                            to={`/promotions/promotionview?promotionId=${card.promotionId}`}
                          >
                            <button className="flex items-center text-[10px] md:text-[16px] justify-between md:pr-2 pr-1 md:pl-6 pl-2 md:w-[170px] md:h-[45px] mt-2 xl:w-[150px] h-[35px] w-[100px] bg-white text-[#234971] hover:bg-[#234971] hover:text-white transition-all duration-300 rounded-[40px] font-[600]">
                              More info
                              <img
                                src="./assets/hero/more.png"
                                alt=""
                                className="bg-[#234971] h-[20px] md:h-[35px] rounded-full md:p-2 p-1"
                              />
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Promotions;
