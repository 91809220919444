import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const AboutUs = () => {

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  console.log(baseUrl)

  const backgroundImage = "url('/assets/aboutus/bg_hero.png')";
  const handleGoBack = () => {
    window.history.back();
  };
  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  
  const [openIndex, setOpenIndex] = useState(null);

  const [openIndex1, setOpenIndex1] = useState(null);
  const toggleAccordion1 = (index) => {
    setOpenIndex1((prevIndex) => (prevIndex === index ? null : index));
  };
  

  return (
    <div className="relative lg:pt-[140px] pt-[80px]">

      <div className="flex justify-between xl:px-[120px] px-3 my-4">
        <button
          onClick={handleGoBack}
          className="flex gap-4 items-center cursor-pointer w-[100px]"
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 11.4997H7.82999L12.71 6.61967C13.1 6.22967 13.1 5.58967 12.71 5.19967C12.6175 5.10697 12.5076 5.03342 12.3866 4.98324C12.2656 4.93306 12.136 4.90723 12.005 4.90723C11.874 4.90723 11.7443 4.93306 11.6234 4.98324C11.5024 5.03342 11.3925 5.10697 11.3 5.19967L4.70999 11.7897C4.61728 11.8822 4.54373 11.9921 4.49355 12.113C4.44337 12.234 4.41754 12.3637 4.41754 12.4947C4.41754 12.6256 4.44337 12.7553 4.49355 12.8763C4.54373 12.9973 4.61728 13.1072 4.70999 13.1997L11.3 19.7897C11.3926 19.8823 11.5025 19.9557 11.6234 20.0058C11.7444 20.0559 11.8741 20.0817 12.005 20.0817C12.1359 20.0817 12.2656 20.0559 12.3865 20.0058C12.5075 19.9557 12.6174 19.8823 12.71 19.7897C12.8026 19.6971 12.876 19.5872 12.9261 19.4662C12.9762 19.3453 13.002 19.2156 13.002 19.0847C13.002 18.9537 12.9762 18.8241 12.9261 18.7031C12.876 18.5822 12.8026 18.4723 12.71 18.3797L7.82999 13.4997H19C19.55 13.4997 20 13.0497 20 12.4997C20 11.9497 19.55 11.4997 19 11.4997Z"
              fill="#202020"
            />
          </svg>
          <p className="text-[#202020] font-[600]">Back</p>
        </button>
        <img src="../assets/logo3.png" alt="" className="w-[200px]" />
      </div>

      <div className=" relative  w-full">
<div className="h-auto">
        {/* 1  */}
        <div className="bg-[#139EB5] w-full">
          <div className="bg-white w-fit xl:mx-[120px] mx-3 px-2">
            <h1 className="text-[#11485B]  md:text-[30px] text-center font-[700]">AQUA Medical Laboratories</h1>
          </div>
        </div>
        <div className="relatisve w-full xl:px-[120px] px-3 mt-3">
          <div
            className="cursor-pointer relative"
            onClick={() => toggleAccordion1(1)}
          >
            <div className="h-12 w-full flex items-center">
              <p className="text-[#A3D284] md:mb-2 font-[600] md:text-[30px] ">
                Our Story
              </p>
            </div>
            <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3 ">
              <span
                className={`material-icons material-symbols-outlined ${openIndex1 === 1 ? "icon-expand-less" : "icon-expand-more"
                  }`}
              >
                {openIndex1 === 1 ? <div className="">
                  <svg width="20" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5903 13.0896C12.1685 13.5109 11.5966 13.7476 11.0003 13.7476C10.4041 13.7476 9.83221 13.5109 9.41034 13.0896L0.923339 4.60559C0.501446 4.1835 0.264508 3.6111 0.264649 3.01431C0.264789 2.41752 0.501996 1.84523 0.924089 1.42334C1.34618 1.00145 1.91858 0.764508 2.51537 0.764649C3.11216 0.764789 3.68445 1.002 4.10634 1.42409L11.0003 8.31809L17.8943 1.42409C18.3185 1.01403 18.8868 0.78697 19.4767 0.791817C20.0666 0.796663 20.6311 1.03303 21.0485 1.45C21.4658 1.86697 21.7027 2.43119 21.7081 3.02114C21.7135 3.61108 21.487 4.17954 21.0773 4.60409L12.5918 13.0911L12.5903 13.0896Z" fill="#202020" />
                      </svg>

                      
                    </div> :
                    <div>
                  <svg width="14" height="20" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0896 9.40966C13.5109 9.83154 13.7476 10.4034 13.7476 10.9997C13.7476 11.5959 13.5109 12.1678 13.0896 12.5897L4.60559 21.0767C4.1835 21.4986 3.6111 21.7355 3.01431 21.7354C2.41752 21.7352 1.84523 21.498 1.42334 21.0759C1.00145 20.6538 0.764508 20.0814 0.764648 19.4846C0.764789 18.8878 1.002 18.3156 1.42409 17.8937L8.31809 10.9997L1.42409 4.10566C1.01403 3.6815 0.78697 3.11325 0.791816 2.5233C0.796662 1.93336 1.03303 1.36891 1.45 0.951546C1.86697 0.534178 2.43119 0.297281 3.02113 0.291878C3.61108 0.286476 4.17954 0.513 4.60409 0.922661L13.0911 9.40816L13.0896 9.40966Z" fill="#202020" />
                      </svg>

                    </div>
                  }
              </span>
            </div>
          </div>
          <div
            className={`transition-all duration-500 ${openIndex1 === 1 ? "max-h-0" : "max-h-full"
              }`}
          >
            <div className="md:text-[18px] text-justify text-[16px] font-[400] text-[#234971] figtree">

              <p className="md:py-3 py-1  md:text-[14px] text-[12px] lg:text-[18px] font-[400]">
                Aqua Medical Laboratories was born out of a shared vision and a deep passion for transforming healthcare. Our journey began in Dubai, where Dr. Yusra Atrah served as a respected Lab Director for eight years. With a strong commitment to advancing integrative personalized medicine, Dr. Atrah recognized a critical need to bring the laboratory to the forefront, empowering patients to take control of their own health journey. This vision sparked the idea of establishing Aqua medical laboratories one that would not only provide cutting-edge diagnostic services but also place patient well-being at the center of its mission
              </p>
              <p className="md:py-3 py-1 md:text-[14px] text-[12px] lg:text-[18px] font-[400] ">Joining forces with Mr. Sameh Alshahaby, an expert in operations, project management, and the design and construction of medical facilities, their dream became a reality with the founding of Aqua Medical Laboratories in London, UK. Together, with addition of the esteemed Aqua lab family , they envisioned a laboratory that would go beyond traditional services, focusing on personalized care and innovative approaches to health management.</p>
              <p className="md:py-3 py-1 md:text-[14px] text-[12px] lg:text-[18px] font-[400] ">Aqua Medical Laboratories was just the beginning. With a broader vision of expanding into the wellness space, they laid the foundation for Aqua Health Clinic, a state-of-the-art facility dedicated to well-being and rejuvenation medicine. Their goal was to create a holistic healthcare experience that integrates advanced diagnostics with personalized treatment plans, helping patients achieve optimal health and vitality.</p>
              <p className="md:py-3 py-1 md:text-[14px] text-[12px] lg:text-[18px] font-[400] ">Today, Aqua Medical Laboratories and Aqua Health Clinic are not only serving patients in the UK but are also extending their operations to the Middle East and Africa.</p>
              <p className="md:py-3 py-1 md:text-[14px] text-[12px] lg:text-[18px] font-[400] ">At Aqua, we believe that true health begins with understanding and addressing each individual’s unique needs. Our dedicated team works tirelessly to provide accurate, timely, and personalized diagnostic services, helping you take charge of your health journey. Together, we’re building a future where healthcare is proactive, personalized, and empowering because your health is our priority.</p>

            </div>
          </div>
        </div>
        <div className="xl:px-[120px] px-3">
          <div className="relatisve w-full mt-3">
            <div
              className="cursor-pointer relative"
              onClick={() => toggleAccordion(2)}
            >
              <div className="h-12 w-full flex items-center">
                <p className="text-[#A3D284] md:mb-2 font-[600] md:text-[30px]">
                  Our Vision
                </p>
              </div>
              <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3 ">
                <span
                  className={`material-icons material-symbols-outlined ${openIndex === 2 ? "icon-expand-less" : "icon-expand-more"
                    }`}
                >
                  {openIndex === 2 ?
                    <div className="">
                      <svg width="14" height="20" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0896 9.40966C13.5109 9.83154 13.7476 10.4034 13.7476 10.9997C13.7476 11.5959 13.5109 12.1678 13.0896 12.5897L4.60559 21.0767C4.1835 21.4986 3.6111 21.7355 3.01431 21.7354C2.41752 21.7352 1.84523 21.498 1.42334 21.0759C1.00145 20.6538 0.764508 20.0814 0.764648 19.4846C0.764789 18.8878 1.002 18.3156 1.42409 17.8937L8.31809 10.9997L1.42409 4.10566C1.01403 3.6815 0.78697 3.11325 0.791816 2.5233C0.796662 1.93336 1.03303 1.36891 1.45 0.951546C1.86697 0.534178 2.43119 0.297281 3.02113 0.291878C3.61108 0.286476 4.17954 0.513 4.60409 0.922661L13.0911 9.40816L13.0896 9.40966Z" fill="#202020" />
                      </svg>
                    </div> :
                    <div>
                      <svg width="20" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5903 13.0896C12.1685 13.5109 11.5966 13.7476 11.0003 13.7476C10.4041 13.7476 9.83221 13.5109 9.41034 13.0896L0.923339 4.60559C0.501446 4.1835 0.264508 3.6111 0.264649 3.01431C0.264789 2.41752 0.501996 1.84523 0.924089 1.42334C1.34618 1.00145 1.91858 0.764508 2.51537 0.764649C3.11216 0.764789 3.68445 1.002 4.10634 1.42409L11.0003 8.31809L17.8943 1.42409C18.3185 1.01403 18.8868 0.78697 19.4767 0.791817C20.0666 0.796663 20.6311 1.03303 21.0485 1.45C21.4658 1.86697 21.7027 2.43119 21.7081 3.02114C21.7135 3.61108 21.487 4.17954 21.0773 4.60409L12.5918 13.0911L12.5903 13.0896Z" fill="#202020" />
                      </svg>

                    </div>}
                </span>
              </div>
            </div>
            <div
              className={`overflow-hidden transition-all duration-500 ${openIndex === 2 ? "max-h-full" : "max-h-0"
                }`}
            >
              <div className="md:flex justify-between w-full  gap-5 py-10">
                <div className="md:w-[60%] flex items-center">
                  <div className=" figtree">
                    {/* <p className=" md:text-[24px] text-[#a3d284] text-[18px] font-[700]">Our Vision</p> */}
                    <p className="md:text-[18px] text-[12px] font-[400] text-justify text-[#234971]">
                      Our vision at Aqua Medical Laboratories is to be a global leader in integrative and personalized medicine, transforming healthcare through innovative diagnostic solutions and tailored treatment plans. We aspire to create a future where every individual has access to precise, holistic care that addresses their unique needs, fostering a healthier and more vibrant world."
                    </p>
                  </div>
                </div>
                <div className="lg:w-[50%] flex justify-center items-center ">
                  <img src="./assets/service/Corporate.jpeg" alt="" className="rounded-[20px] h-[300px] lg:h-[400px] w-[400px] object-cover" />
                </div>
              </div>
            </div>
          </div>
          <div className="relatisve w-full mt-3">
            <div
              className="cursor-pointer relative"
              onClick={() => toggleAccordion(3)}
            >
              <div className="h-12 w-full flex items-center">
                <p className="text-[#A3D284] md:mb-2 font-[600] md:text-[30px]">
                  Our Mission
                </p>
              </div>
              <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3 ">
                <span
                  className={`material-icons material-symbols-outlined ${openIndex === 3 ? "icon-expand-less" : "icon-expand-more"
                    }`}
                >
                  {openIndex === 3 ?
                    <div className="">
                      <svg width="14" height="20" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0896 9.40966C13.5109 9.83154 13.7476 10.4034 13.7476 10.9997C13.7476 11.5959 13.5109 12.1678 13.0896 12.5897L4.60559 21.0767C4.1835 21.4986 3.6111 21.7355 3.01431 21.7354C2.41752 21.7352 1.84523 21.498 1.42334 21.0759C1.00145 20.6538 0.764508 20.0814 0.764648 19.4846C0.764789 18.8878 1.002 18.3156 1.42409 17.8937L8.31809 10.9997L1.42409 4.10566C1.01403 3.6815 0.78697 3.11325 0.791816 2.5233C0.796662 1.93336 1.03303 1.36891 1.45 0.951546C1.86697 0.534178 2.43119 0.297281 3.02113 0.291878C3.61108 0.286476 4.17954 0.513 4.60409 0.922661L13.0911 9.40816L13.0896 9.40966Z" fill="#202020" />
                      </svg>
                    </div> :
                    <div>
                      <svg width="20" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5903 13.0896C12.1685 13.5109 11.5966 13.7476 11.0003 13.7476C10.4041 13.7476 9.83221 13.5109 9.41034 13.0896L0.923339 4.60559C0.501446 4.1835 0.264508 3.6111 0.264649 3.01431C0.264789 2.41752 0.501996 1.84523 0.924089 1.42334C1.34618 1.00145 1.91858 0.764508 2.51537 0.764649C3.11216 0.764789 3.68445 1.002 4.10634 1.42409L11.0003 8.31809L17.8943 1.42409C18.3185 1.01403 18.8868 0.78697 19.4767 0.791817C20.0666 0.796663 20.6311 1.03303 21.0485 1.45C21.4658 1.86697 21.7027 2.43119 21.7081 3.02114C21.7135 3.61108 21.487 4.17954 21.0773 4.60409L12.5918 13.0911L12.5903 13.0896Z" fill="#202020" />
                      </svg>
                    </div>}
                </span>
              </div>
            </div>
            <div
              className={`overflow-hidden transition-all duration-500 ${openIndex === 3 ? "max-h-full" : "max-h-0"
                }`}
            >
              <div className="md:flex justify-between gap-5 w-full py-10">
                <div className="lg:w-[40%] flex justify-center items-center">
                  <img src="./assets/team12.png" alt="" className="h-[300px] lg:h-[400px] w-[400px] rounded-[20px]" />
                </div>
                <div className="md:w-[60%] flex items-center mt-3 md:mt-0">
                  <div className=" figtree">
                    {/* <p className="md:text-[24px] text-[18px] font-[700] text-[#a3d284]">Our Mission</p> */}
                    <p className=" md:text-[18px] text-[12px] font-[400] text-justify text-[#234971]">
                      Aqua Medical Laboratories is dedicated to pioneering integrative and personalized medicine by providing innovative diagnostic solutions and individualized care. We aim to enhance patient health and well-being through cutting-edge research, advanced technology, and a holistic approach that combines the best of traditional and modern medical practices. Our commitment is to deliver accurate, comprehensive, and compassionate services that empower patients and healthcare providers in their journey towards optimal health."
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 2  */}

</div>
        {/* SUbscribe  */}

        <div className="lg:flex justify-between xl:px-[120px] px-5">
          {/* <div className="md:text-[28px] text-[22px] font-[500] text-[#445158]">
            <p className="">
              Subscribe to our newsletter and never miss an update on the latest
              medical advancements, health tips, and exclusive offers.
            </p>
            <p className="mt-3">
              Join our community and stay connected with{" "}
              <span className="text-[#13304F] md:text-[32px] text-[26px] font-[600]">
                {" "}
                AQUA Medical Laboratory.
              </span>
            </p>
          </div> */}
          {/* <div className="lg:w-[50%]">
          <div className="">
            <p className="md:text-[32px] text-[28px] text-[#057F93] font-[700]">
              Subscribe Now!
            </p>
            <div className="border-[#445158] border rounded-[10px] md:h-[75px] h-[60px] w-full bg-[#F9FFF6] flex justify-between items-center">
              <input
                type="mail"
                className="pl-5 h-full rounded-[10px] md:w-[400px] bg-transparent outline-none"
                placeholder="Type your email here.."
              />
              <button className="text-[#00444F] font-[700] text-[32px] rounded-[10px] bg-[#D1F5B9] md:w-[165px] h-full w-[120px] ">
                Submit
              </button>
            </div>
            <p className="text-[12px] text-[#96A8B3] font-[400] mt-2">
              *Your privacy is important to us. We’ll never share your
              information.
            </p>
          </div>
        </div> */}
        </div>
        <div className="bg-[#139EB5] w-full h-[40px]">

        </div>
      </div>

      {/* <div className="bg-[#C4D6E0] text-[#202020] flex justify-center relative lg:bottom-[200px] md:bottom-[160px] bottom-10 w-full">
        <div className="py-10 xl:px-[220px]">
          <p className="xl:text-[48px] lg:text-[24px] text-[22px] font-[700] text-center underline underline-offset-8 decoration-[#139EB5]">Join our team</p>
          <p className="lg:text-[24px] text-[18px] text-center">Are you passionate about making a difference in healthcare? Join our dynamic team at AQUA Medical Laboratories and help us revolutionize medical diagnostics. Explore exciting career opportunities and grow with us!</p>
          <form onSubmit={handleSubmit}>
            <div className="md:flex gap-6 lg:px-20 px-5 mt-10">
              <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} className="h-[60px] outline-none bg-white w-full pl-5 text-[#234971]" placeholder="Full Name" />
              <input type="text" name="email" value={formData.email} onChange={handleChange} className="h-[60px] outline-none bg-white w-full pl-5 text-[#234971] mt-5 md:mt-0" placeholder="Email Address" />
            </div>
            <div className="md:flex gap-6 lg:px-20 px-5 mt-5">
              <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} className="h-[60px] outline-none bg-white w-full pl-5 text-[#234971]" placeholder="Phone Number" />
              <input type="file" name="resume" onChange={handleChange} className="h-[60px] outline-none bg-white w-full pl-5 text-[#234971] mt-5 md:mt-0" />
            </div>
            <div className="flex justify-center mt-5">
              <button type="submit" className="md:w-[280px] w-[200px] md:h-[60px] h-[40px] flex justify-center items-center gap-4 md:text-[20px] text-white bg-[#234971] hover:text-[#234971] hover:bg-white transition-all duration-500 hover:border hover:border-[#234971] font-[600] rounded-[50px]">
                Submit Application
              </button>
            </div>
            {message && <div className="text-center mt-4 text-green-500">{message}</div>}
            {errorMessage && <div className="text-center mt-4 text-red-500">{errorMessage}</div>}
          </form>
        </div>
      </div> */}
      {/* 1  */}

    </div>
  );
};

export default AboutUs;




{/* <img
        src="./assets/logo1.png"
        alt=""
        className="absolute inset-0 object-cover opacity-70  mx-auto my-auto"
      /> */}
{/* <div className="bg-[#E6F6FF] py-10 text-center relative">
        <Link to="/">
          <div className="xl:mx-[120px] md:ml-5 ml-3 absolute md:top-8">
            <div className="flex gap-4 items-center cursor-pointer md:w-[100px] ">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 11.4997H7.82999L12.71 6.61967C13.1 6.22967 13.1 5.58967 12.71 5.19967C12.6175 5.10697 12.5076 5.03342 12.3866 4.98324C12.2656 4.93306 12.136 4.90723 12.005 4.90723C11.874 4.90723 11.7443 4.93306 11.6234 4.98324C11.5024 5.03342 11.3925 5.10697 11.3 5.19967L4.70999 11.7897C4.61728 11.8822 4.54373 11.9921 4.49355 12.113C4.44337 12.234 4.41754 12.3637 4.41754 12.4947C4.41754 12.6256 4.44337 12.7553 4.49355 12.8763C4.54373 12.9973 4.61728 13.1072 4.70999 13.1997L11.3 19.7897C11.3926 19.8823 11.5025 19.9557 11.6234 20.0058C11.7444 20.0559 11.8741 20.0817 12.005 20.0817C12.1359 20.0817 12.2656 20.0559 12.3865 20.0058C12.5075 19.9557 12.6174 19.8823 12.71 19.7897C12.8026 19.6971 12.876 19.5872 12.9261 19.4662C12.9762 19.3453 13.002 19.2156 13.002 19.0847C13.002 18.9537 12.9762 18.8241 12.9261 18.7031C12.876 18.5822 12.8026 18.4723 12.71 18.3797L7.82999 13.4997H19C19.55 13.4997 20 13.0497 20 12.4997C20 11.9497 19.55 11.4997 19 11.4997Z"
                  fill="#202020"
                />
              </svg>
              <p className="text-[#202020] lg:md:text-[24px] font-[600]">
                Back
              </p>
            </div>
          </div>
        </Link>

        <p className="md:text-[24px] text-[18px] text-[#234971] font-[600]">AQUA Medical Laboratories</p>
        <p className="md:text-[18px] text-[16px] text-[#234971]">Testing the Wellness of Tomorrow </p>
        <p className="md:text-[18px] text-[16px] text-[#234971]">Navigating wellbeing with bespoke precision</p>
      </div> */}
{/* <div
        className="md:h-[80vh] h-[50vh] flex items-center xl:px-[120px] px-2 relative"
        style={{
          backgroundImage: backgroundImage,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="">
          <p className="text-[#234971] lg:text-[36px] text-[24px] font-[700] z-0"> AQUA Medical Laboratories </p>
          <p className="text-[#234971] lg:text-[24px] text-[18px] font-[500]">Testing the wellness of Tomorrow</p>
        </div>
      </div>
      <img
        src="./assets/blog/logo.png"
        alt=""
        className="absolute lg:top-20 lg:right-20 top-[120px] -z-10 right-0 lg:h-[600px] h-[200px] lg:w-[600px] w-[200px]"
      /> */}

{/* <div className="flex justify-center">
          <img src="./assets/aboutus/people.png" alt="" className="w-full" />
        </div>
        <div className="flex justify-center">
          <p className="lg:text-[36px] text-[14px] text-[#234971] font-[700] py-5">
            Navigating wellbeing with bespoke precision
          </p>
        </div> */}