import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TestForm from "../form";
import { jwtDecode } from "jwt-decode";
import DatePicker from "react-datepicker";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { getNames } from "country-list";
import axios from "axios";
import RequiredTestsDropdown from "../requesttest";
import ResuestSample from "../requestsample";
import { MdDelete, MdDeleteOutline } from "react-icons/md";

function OurService() {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;
  AOS.init();
  const [showAdditionalContent, setShowAdditionalContent] = useState(false);
  const [userInfo1, setUserInfo1] = useState({});

  const [packageInfo, setPackageInfo] = useState([]);
  const location = useLocation();
  const [showContent, setShowContent] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const categoryId = queryParams.get("id");
  const navigate = useNavigate();
  const tokenn = localStorage.getItem("aqua");
  const [book, setBook] = useState(false);
  const [book2, setBook2] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [popup, setPopup] = useState({
    visible: false,
    message: "",
    isError: false,
  });
  const [popup12, setPopup12] = useState({
    visible: false,
    message: "",
    isError: false,
  });
  const [loggedIn, setLoggedIn] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    gender: "",
    address1: "",
    address2: "",
    clinicName: "",
    postalCode: "",
    country: "",
    nhsNumber: "",
    requiredTests: [],
    TubesNumber: "",
    fasting: false,
    clinicComplain: "",
    additionalRemark: "",
    // bookingDate: null,
    dob: null,
    SerumTypes: [],
    Pregnant: false,
    patientname: "",
    patientnumber: "",
    patientemail: "",
    city: "",
    streetname: "",
    // isClickInOwner :''
  });

  //
  const [formData1, setFormData1] = useState({
    clinicName1: "",
    phoneNumber1: "",
    email1: "",
    address11: "",
    materials: [
      {
        materialName: "",
        qty: "",
        unit: "",
        manufacturerName: "",
        batchNumber: "",
        expiryDate: "",
      },
    ],
  });

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setFormData1((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePhoneNumberChange12 = (value) => {
    setFormData1((prevData) => ({
      ...prevData,
      phoneNumber1: value,
    }));
  };

  const addMaterialRow = () => {
    setFormData1((prevData) => ({
      ...prevData,
      materials: [
        ...(prevData.materials || []),
        {
          materialName: "",
          qty: "",
          unit: "",
          manufacturerName: "",
          batchNumber: "",
          expiryDate: "",
        },
      ],
    }));
  };

  const handleMaterialChange = (index, e) => {
    const { name, value } = e.target;
    setFormData1((prevData) => {
      const newMaterials = [...prevData.materials];
      newMaterials[index][name] = value;
      return { ...prevData, materials: newMaterials };
    });
  };

  const removeMaterialRow = (index) => {
    setFormData1((prevData) => ({
      ...prevData,
      materials: prevData.materials.filter((_, i) => i !== index),
    }));
  };

  const handleBooking1 = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${baseUrl}/booking/sampleRequest`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("aqua")}`,
        },
        body: JSON.stringify(formData1),
      });

      const result = await response.json();
      if (result.isSuccess) {
        showPopup12("Sample request submitted successfully!", false);
        setFormData1({
          clinicName1: "",
          phoneNumber1: "",
          email1: "",
          address11: "",
          materials: [
            {
              materialName: "",
              qty: "",
              unit: "",
              manufacturerName: "",
              batchNumber: "",
              expiryDate: "",
            },
          ],
        });
      } else {
        showPopup12("Failed to submit sample request.", true);
      }
    } catch (error) {
      console.error("Error submitting sample request:", error);
      showPopup12("An error occurred. Please try again.", true);
    }
  };

  const showPopup12 = (message, isError = false) => {
    setPopup12({
      visible: true,
      message,
      isError,
    });
    setBook2(!book2);
  };

  const closePopup12 = () => {
    setPopup12((prev) => ({
      ...prev,
      visible: false,
    }));
  };
  //

  // const userInfo = jwtDecode(tokenn);

  const countries = getNames();
  const modifiedCountries = [...countries, "United Kingdom"];

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/home/category?categoryId=${categoryId}`
        );
        if (!response.ok) {
          throw new Error("Unable to fetch data");
        }
        const data = await response.json();
        setPackageInfo(data);
      } catch (error) {
        console.error("Error fetching services:", error);
        setPackageInfo([]);
      }
    };

    fetchServices();
  }, [categoryId]);

  const handleLearnMoreClick = () => {
    if (categoryId === "7") {
      setShowAdditionalContent(true);
    }
  };

  // cus

  useEffect(() => {
    const token = localStorage.getItem("aqua");
    if (!token) {
      console.error("No token found");
      return;
    }

    const jwt = jwtDecode(token);
    console.log(jwt);

    fetch(`${baseUrl}/userInfo/getUserDetails`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch user details");
        }
      })
      .then((data) => {
        if (data && data.result) {
          setUserInfo1(data.result);
          setFormData({
            title: data.result.Title || "",
            firstName: data.result.FirstName || "",
            lastName: data.result.LastName || "",
            phoneNumber: data.result.PhoneNumber || "",
            email: data.result.Email || "",
            address1: data.result.ClinicAddress || "",
            clinicName: data.result.ClinicName || "",
          });
          setFormData1({
            phoneNumber1: data.result.PhoneNumber || "",
            email1: data.result.Email || "",
            address11: data.result.ClinicAddress || "",
            clinicName1: data.result.ClinicName || "",
          });
        } else {
          console.error("API returned undefined result:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });

    const fakeDatafetch = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    fakeDatafetch();
  }, [baseUrl]);

  const handleViewFormClick = () => {
    setBook(!book);
  };
  const handleViewFormClick2 = () => {
    setBook2(!book2);
  };
  // useEffect(() => {
  //   if (book) {
  //     document.body.classList.add("fixed-background");
  //   } else {
  //     document.body.classList.remove("fixed-background");
  //   }
  //   return () => document.body.classList.remove("fixed-background");
  // }, [book]);

  const closePopup = () => {
    setPopup({ ...popup, visible: false });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePhoneNumberChange = (phone) => {
    setFormData({ ...formData, phoneNumber: phone });
  };

  const handlePhoneNumberChange1 = (phone) => {
    setFormData({ ...formData, patientnumber: phone });
  };

  const handleBookingDateChange = (date) => {
    setFormData({ ...formData, bookingDate: date });
  };

  const handleDobChange = (date) => {
    setFormData({ ...formData, dob: date });
  };

  const handleChange12 = (e) => {
    const { name, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : prevData[name],
    }));
  };

  const handleDateChange = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = date.getHours() % 12 || 12;
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const amPm = date.getHours() >= 12 ? "PM" : "AM";

    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes} ${amPm}`;

    setFormData((prevState) => ({
      ...prevState,
      bookingDate: formattedDateTime,
    }));
  };

  const handleDateChange1 = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;

    setFormData((prevState) => ({
      ...prevState,
      dob: formattedDate,
    }));
  };

  const handleBooking = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${baseUrl}/booking/addcustomized`,
        formData
      );

      if (response.data.success) {
        setBook(false);

        setPopup({
          visible: true,
          message: "Request successfully submitted!",
          isError: false,
        });

        setFormData({
          title: "",
          firstName: "",
          lastName: "",
          phoneNumber: "",
          email: "",
          gender: "",
          address1: "",
          address2: "",
          clinicName: "",
          postalCode: "",
          country: "",
          nhsNumber: "",
          requiredTests: "",
          serumTypes: "",
          fastingOrPregnant: "",
          clinicComplain: "",
          additionalRemark: "",
          city: "",
          streetname: "",
          // bookingDate: null,
          dob: null,
        });
      } else {
        setPopup({
          visible: true,
          message: "Failed to submit booking. Please try again.",
          isError: true,
        });
      }
    } catch (error) {
      console.error(error);
      setPopup({
        visible: true,
        message: "Failed to submit booking. Please try again.",
        isError: true,
      });
    }
  };

  //

  const handleGoBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const token = localStorage.getItem("aqua");
    console.log(token);
    if (token != null) {
      const userInfo = jwtDecode(token);
      setLoggedIn(true);
    }
  }, []);

  return (
    <>
      <div className="relative min-h-auto ">
        <div className="pt-20 lg:pt-[140px]">
          {packageInfo.map((item, index) => (
            <div className="w-full">
              <div className="flex justify-between xl:px-[120px]  px-3 my-4">
                <button
                  onClick={handleGoBack}
                  className="flex gap-2 items-center cursor-pointer w-[100px]"
                >
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 11.4997H7.82999L12.71 6.61967C13.1 6.22967 13.1 5.58967 12.71 5.19967C12.6175 5.10697 12.5076 5.03342 12.3866 4.98324C12.2656 4.93306 12.136 4.90723 12.005 4.90723C11.874 4.90723 11.7443 4.93306 11.6234 4.98324C11.5024 5.03342 11.3925 5.10697 11.3 5.19967L4.70999 11.7897C4.61728 11.8822 4.54373 11.9921 4.49355 12.113C4.44337 12.234 4.41754 12.3637 4.41754 12.4947C4.41754 12.6256 4.44337 12.7553 4.49355 12.8763C4.54373 12.9973 4.61728 13.1072 4.70999 13.1997L11.3 19.7897C11.3926 19.8823 11.5025 19.9557 11.6234 20.0058C11.7444 20.0559 11.8741 20.0817 12.005 20.0817C12.1359 20.0817 12.2656 20.0559 12.3865 20.0058C12.5075 19.9557 12.6174 19.8823 12.71 19.7897C12.8026 19.6971 12.876 19.5872 12.9261 19.4662C12.9762 19.3453 13.002 19.2156 13.002 19.0847C13.002 18.9537 12.9762 18.8241 12.9261 18.7031C12.876 18.5822 12.8026 18.4723 12.71 18.3797L7.82999 13.4997H19C19.55 13.4997 20 13.0497 20 12.4997C20 11.9497 19.55 11.4997 19 11.4997Z"
                      fill="#202020"
                    />
                  </svg>
                  <p className="text-[#202020] hidden md:block font-[600] text-[14px] md:text-[22px]  ">
                    Back
                  </p>
                </button>
                {categoryId !== "7" && (
                  <p className="text-[#11485B] text-[14px] text-center md:text-left mr-[90px] md:ml-10 md:text-[22px] font-[600]    text-ellipsis">
                    {item.title}
                  </p>
                )}

                <img
                  src="../assets/logo3.png"
                  alt=""
                  className="w-[200px] hidden md:block"
                />
              </div>
            </div>
          ))}
          {categoryId === "7" ? (
            <></>
          ) : (
            <div className="absolute -mt-[80px] h-full w-full -z-10 hidden xl:block">
              <img
                src="./assets/bg_blue.png"
                alt=""
                className="object-fill h-full w-full absolute top-0 -z-20"
              />
              <img
                src="./assets/bg_green.png"
                alt=""
                className="object-fill h-full w-full absolute top-0 -z-40"
              />
            </div>
          )}
        </div>
        {categoryId === "7" ? (
          <>
            {packageInfo.map((item, index) => (
              <div className="w-full bg-[#139EB5]">
                <div key={index} className="lg:flex w-full">
                  <div className="flex flex-col md:flexs-row">
                    <div className="bg-white w-fit xl:mx-[120px] mx-3 px-2">
                      <p className="text-[#11485B]  md:text-[22px] text-center font-[700]">
                        {item.title}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="p-4 center  bg-white xl:px-[120px] px-3">
              <p className="  md:text-[20px] text-[#a3d284]  font-semibold">
                Early Detection. Better Outcomes.
              </p>
              <p className=" mt-4  text-[#234971]">
                At Aqua Medical Laboratories, we are committed to providing
                advanced and reliable cancer screening services designed to
                detect potential health issues at the earliest stage. Our
                comprehensive screening packages are tailored to meet the needs
                of individuals seeking proactive measures for their health and
                well-being.
              </p>
              <p className=" mt-4 font-bold text-[#a3d284]">
                Why Choose Our Cancer Screening Services?
              </p>
              <ul className="list-disc list-style-[#a3d284] text-[#234971] mt-2 ml-5">
                <li>
                  <span className="font-bold text-[#a3d284]">
                    Cutting-Edge Technology:
                  </span>{" "}
                  We utilize the latest advancements in medical technology to
                  ensure accurate and timely detection of cancer. Our
                  state-of-the-art laboratories are equipped with the most
                  advanced diagnostic tools available, providing you with the
                  best possible care.
                </li>
                <li>
                  <span className="font-bold text-[#a3d284]">
                    Expert Medical Team:{" "}
                  </span>{" "}
                  Our team of experienced and highly skilled medical
                  professionals is dedicated to delivering exceptional service.
                  From pathologists to laboratory technicians, our experts are
                  committed to providing precise and comprehensive screening
                  results.
                </li>
                <li>
                  <span className="font-bold text-[#a3d284]">
                    Personalized Screening Packages:{" "}
                  </span>
                  We understand that each individual is unique, and so are their
                  health needs. Our personalized cancer screening packages are
                  designed to cater to various risk factors and health profiles,
                  ensuring you receive the most relevant and effective
                  screening.
                </li>
              </ul>
              <p className="text-[#a3d284] mt-4 font-bold">
                The Screening Process:
              </p>
              <ol className=" text-[#234971] mt-2 ml-5">
                <li>
                  {" "}
                  <span className="font-bold text-[#a3d284]">
                    Step 1 : Consultation{" "}
                  </span>{" "}
                  Your screening journey begins with a consultation with our
                  medical professionals to assess your risk factors, family
                  history, and any symptoms you may have.
                </li>
                <li>
                  <span className="font-bold text-[#a3d284]">
                    {" "}
                    Step 2 : Customized Screening Plan{" "}
                  </span>{" "}
                  Based on your consultation, we will develop a personalized
                  screening plan tailored to your specific needs and risk
                  profile.
                </li>
                <li>
                  {" "}
                  <span className="font-bold text-[#a3d284]">
                    Step 3 : Comprehensive Testing{" "}
                  </span>{" "}
                  Undergo a series of blood tests conducted by our expert team,
                  using the latest medical technology to detect specific tumor
                  markers.
                </li>
                <li>
                  {" "}
                  <span className="font-bold text-[#a3d284]">
                    Step 4 :Detailed Results and Follow-Up{" "}
                  </span>{" "}
                  Receive detailed results and recommendations from our
                  specialists. If necessary, we will guide you through the next
                  steps for further diagnostics or treatment options.
                </li>
              </ol>
              <div className="flex items-center justify-center ">
                <button
                  onClick={handleLearnMoreClick}
                  className="flex items-center text-[10px] md:text-[16px] justify-between md:pr-2 pr-1 md:pl-6 pl-2 md:w-[170px] md:h-[45px] mt-2 xl:w-[150px] h-[35px] w-[100px] bg-[#234971] text-[#ffff] hover:bg-[#234971] hover:text-white transition-all duration-300 rounded-[40px] font-[600]"
                >
                  {showAdditionalContent ? "View test" : "View Test"}
                  <img
                    src="./assets/hero/more.png"
                    alt=""
                    className="bg-[#234971] h-[20px] md:h-[35px] rounded-full md:p-2 p-1"
                  />
                </button>
              </div>
            </div>
            {showAdditionalContent && (
              <div className="px-4 mt-4">
                <div className="relative  px-3 pt-[40px]">
                  <div className="overflow-hidden min-h-screen h-full relative">
                    {/* <img
                      src="./assets/logo1.png"
                      alt=""
                      className="absolute inset-0 object-cover opacity-70 mx-auto my-auto"
                    /> */}
                    {packageInfo.map((item, index) => (
                      <div key={index} className="mb-8">
                        <div className="gap-10 flex flex-wrap justify-center">
                          {item.serviceDetails.map((detail, detailIndex) => (
                            <div
                              key={detailIndex}
                              className="px-4"
                              data-aos="zoom-in"
                              data-aos-duration="1000"
                            >
                              <div className="relative h-[320px] md:h-[340px] xl:w-[368px]">
                                <img
                                  className="h-full w-full rounded-[10px] object-cover"
                                  src="/assets/card.png"
                                  alt="service"
                                />
                                <div className="absolute top-0 px-4 h-[80%] overflow-hidden">
                                  <p className="font-bold py-4 md:text-[18px] text-[#FFFFFF]">
                                    {detail.serviceName}
                                  </p>
                                  <div className="flex py-2 mt-4">
                                    <p className="font-bold text-[#FFFFFF] flex-shrink-0">
                                      Sample Type:
                                    </p>
                                    <span className="font-normal ml-2 text-[#FFFFFF] flex-1">
                                      {detail.type}
                                    </span>
                                  </div>
                                  <div className="flex py-2">
                                    <p className="font-bold text-justify text-[#FFFFFF]">
                                      Fasting:
                                    </p>
                                    <span className="font-normal ml-2 text-[#FFFFFF] truncate-two-lines">
                                      {detail.fasting}
                                    </span>
                                  </div>
                                </div>
                                <div className="flex justify-center mt-3 md:mt-0 xl:mt-7 absolute bottom-3 w-full">
                                  <Link
                                    to={`/ourservice/packageview?subId=${detail.subId}`}
                                  >
                                    <button className="flex items-center text-[10px] md:text-[16px] justify-between md:pr-2 pr-1 md:pl-6 pl-2 md:w-[170px] md:h-[45px] mt-2 xl:w-[150px] h-[35px] w-[100px] bg-white text-[#234971] hover:bg-[#234971] hover:text-white transition-all duration-300 rounded-[40px] font-[600]">
                                      More info
                                      <img
                                        src="./assets/hero/more.png"
                                        alt=""
                                        className="bg-[#234971] h-[20px] md:h-[35px] rounded-full md:p-2 p-1"
                                      />
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="center">
            <div className="relative xl:px-[80px] px-3 pt-[80px]">
              <div className="overflow-hidden min-h-screen h-full relative">
                {/* <img
                  src="./assets/logo1.png"
                  alt=""
                  className="absolute inset-0 object-cover opacity-70 mx-auto my-auto"
                /> */}

                {packageInfo.map((item, index) => (
                  <>
                    <div key={index} className="mb-8">
                      {item.serviceDetails.length > 0 ? (
                        <div className="gap-10 flex flex-wrap justify-center">
                          {item.serviceDetails.map((detail, detailIndex) => (
                            <div
                              key={detailIndex}
                              className="px-5"
                              data-aos="zoom-in"
                              data-aos-duration="1000"
                            >
                              <div className="relative h-[240px] md:h-[320px] md:h-[200px] xl:w-[368px]">
                                <img
                                  className="h-full w-full rounded-[10px] object-cover"
                                  src="/assets/card.png"
                                  alt="service"
                                />
                                <div className="absolute top-0 px-4 h-[80%] overflow-hidden">
                                  <p className="font-bold py-4 md:text-[18px] text-[#FFFFFF]">
                                    {detail.serviceName}
                                  </p>
                                  <div className="flex py-2 mt-4">
                                    <p className="font-bold text-[#FFFFFF] flex-shrink-0">
                                      Sample Type:
                                    </p>
                                    <span className="font-normal ml-2 text-[#FFFFFF] flex-1">
                                      {detail.type}
                                    </span>
                                  </div>
                                  <div className="flex py-2">
                                    <p className="font-bold text-justify text-[#FFFFFF]">
                                      Fasting:
                                    </p>
                                    <span className="font-normal ml-2 text-[#FFFFFF] truncate-two-lines">
                                      {detail.fasting}
                                    </span>
                                  </div>
                                </div>
                                <div className="flex justify-center mt-3 md:mt-0 xl:mt-7 absolute bottom-3 w-full">
                                  <Link
                                    to={`/ourservice/packageview?subId=${detail.subId}`}
                                  >
                                    <button className="flex items-center text-[13px] md:text-[16px] justify-between md:pr-2 pr-1 md:pl-2 pl-2 md:w-[130px] md:h-[40px] mt-2 xl:w-[150px] h-[35px] w-[100px] bg-white text-[#234971] hover:bg-[#234971] hover:text-white transition-all duration-300 rounded-[40px] font-[600]">
                                      More info
                                      <img
                                        src="./assets/hero/more.png"
                                        alt=""
                                        className="bg-[#234971] h-[20px] md:h-[30px] rounded-full md:p-2 p-1"
                                      />
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="flex flex-col items-center  justify-center min-h-screen ">
                          <div className="mb-60">
                            {/* <img
                            src="/assets/custom1.png"
                            alt="Service Coming Soon"
                            className=""
                          /> */}

                            {!book && !book2 && (
                              <div className="flex justify-center">
                                <img
                                  src="./assets/animate_logo.png"
                                  className="h-[300px] w-[300px] animate-logoRotateY"
                                  alt=""
                                />
                              </div>
                            )}

                            <div
                              className={`flex flex-col items-center justify-center p-4 mt-10  ${
                                book
                                  ? ""
                                  : book2
                                  ? ""
                                  : "bg-white  rounded-lg shadow-lg"
                              }`}
                            >
                              {!book && !book2 && (
                                <div className="flex flex-col items-center justify-center p-4 mt-10   ">
                                  <div>
                                    <p className="text-[10px] md:text-[20px] mb-4 text-[#234971]">
                                      Dear user, To download our Test Request
                                      Form (TRF), please click the{" "}
                                      <span className="font-bold">
                                        {" "}
                                        "Download Form"
                                      </span>{" "}
                                      button. After filling it out, kindly send
                                      it to info@aquamedlabs.com.
                                    </p>
                                    <p className="text-[10px] md:text-[20px] mb-4 text-[#234971]">
                                      If you prefer to complete the form online,
                                      please click the{" "}
                                      <span className="font-bold">
                                        {" "}
                                        "View Form"{" "}
                                      </span>{" "}
                                      button to access it directly.{" "}
                                    </p>
                                  </div>

                                  <div className="flex gap-10 mt-4">
                                    <a
                                      href="/assets/Medical Laboratory Test Request Form (TRF).pdf"
                                      download="Medical_Laboratory_Test_Request_Form.pdf"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="flex gap-2 items-center text-[10px] md:text-[18px] justify-between pr-2 lg:pl-4  pl-2 lg:w-auto lg:h-[40px] h-[35px] w-auto bg-[#ffffff] text-[#234971] hover:bg-[#234971] hover:text-[#ffffff] border-2 hover:border-[#ffffff] transition-all duration-300 rounded-[40px] font-[600]"
                                    >
                                      Download Form
                                      <div className="flex  ">
                                        {" "}
                                        <img
                                          src="./assets/hero/more.png"
                                          alt=""
                                          className="bg-[#234971] h-[20px] md:h-[30px] rounded-full md:p-2 p-1"
                                        />
                                      </div>
                                    </a>
                                    <div>
                                      <button
                                        onClick={handleViewFormClick}
                                        className="flex  gap-2 items-center text-[10px] md:text-[18px] justify-between pr-2 lg:pl-4 pl-2 lg:w-auto lg:h-[40px] h-[35px] w-auto bg-[#ffffff] text-[#234971] hover:bg-[#234971] hover:text-[#ffffff] border-2 hover:border-[#ffffff] transition-all duration-300 rounded-[40px] font-[600]"
                                      >
                                        View Form
                                        <img
                                          src="./assets/hero/more.png"
                                          alt=""
                                          className="bg-[#234971] lg:h-[30px] h-[20px] rounded-full lg:p-2 p-1"
                                        />
                                      </button>
                                    </div>
                                    <div>
                                      {userInfo1.IsClinicOwner === "yes" &&
                                        loggedIn && (
                                          <button
                                            onClick={handleViewFormClick2}
                                            className="flex  gap-2 items-center text-[10px] md:text-[18px] justify-between pr-2 lg:pl-4 pl-2 lg:w-auto lg:h-[40px] h-[35px] w-auto bg-[#ffffff] text-[#234971] hover:bg-[#234971] hover:text-[#ffffff] border-2 hover:border-[#ffffff] transition-all duration-300 rounded-[40px] font-[600]"
                                          >
                                            Supply Request
                                            <img
                                              src="./assets/hero/more.png"
                                              alt=""
                                              className="bg-[#234971] lg:h-[30px] h-[20px] rounded-full lg:p-2 p-1"
                                            />
                                          </button>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {book && (
                                // <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-[80%]  figtree px-3 md:px-0">
                                <div className=" lg:mx-[80px] mx-5 text-[#ffffff] border-[1px] border-white relative   bg-[#11485B] h-auto  md:rounded-[30px] rounded-[20px] lg:px-10 px-5 py-2">
                                  <form onSubmit={handleBooking}>
                                    <div
                                      className="absolute md:right-6 md:top-5 right-4  cursor-pointer"
                                      onClick={handleViewFormClick}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        className="w-4 h-4"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M6 18L18 6M6 6l12 12"
                                        />
                                      </svg>
                                    </div>

                                    {userInfo1.IsClinicOwner === "no" ||
                                      (!loggedIn && (
                                        <>
                                          <p className=" text-[14px] md:text-[18px] font-[600] md:py-1">
                                            Enter the Patient Details
                                          </p>
                                          <p className=" text-[14px] md:text-[18px]  font-[300] my-2 text-justify">
                                            Our administrative team will contact
                                            you using the details provided.
                                            Please ensure all information
                                            entered is accurate.
                                          </p>
                                        </>
                                      ))}
                                    {userInfo1.IsClinicOwner === "yes" &&
                                      loggedIn && (
                                        <p className=" text-[14px] md:text-[18px] font-[600] md:py-1">
                                          Business Details
                                        </p>
                                      )}
                                    <div className="md:flex gap-3 md:mt-7">
                                      <div className="inputbox text-black">
                                        <select
                                          name="title"
                                          id="title"
                                          value={formData.title}
                                          onChange={handleChange}
                                          className="w-full h-12 px-4 border-2 rounded-md custom-select"
                                          required
                                        >
                                          <option value="">Title*</option>
                                          <option value="Mr.">Mr.</option>
                                          <option value="Mrs.">Mrs.</option>
                                          <option value="Miss.">Miss.</option>
                                          <option value="Dr.">Dr.</option>
                                        </select>
                                      </div>
                                      <div className="inputbox">
                                        <input
                                          type="text"
                                          name="firstName"
                                          value={formData.firstName}
                                          onChange={handleChange}
                                          required
                                        />
                                        <span className="">First Name*</span>
                                        <i></i>
                                      </div>
                                      <div className="inputbox">
                                        <input
                                          type="text"
                                          name="lastName"
                                          value={formData.lastName}
                                          onChange={handleChange}
                                          required
                                        />
                                        <span>Last Name*</span>
                                        <i></i>
                                      </div>
                                    </div>

                                    <div className="md:flex gap-3 md:mt-2">
                                      <div className="inputbox custom-phone-input">
                                        <PhoneInput
                                          defaultCountry="GB"
                                          value={formData.phoneNumber}
                                          onChange={handlePhoneNumberChange}
                                          name="phoneNumber"
                                          placeholder="Phone Number*"
                                          international
                                          withCountryCallingCode
                                          // containerClass="custom-phone-input"
                                        />
                                      </div>

                                      <div className="inputbox">
                                        <input
                                          type="email"
                                          name="email"
                                          value={formData.email}
                                          onChange={handleChange}
                                          required
                                        />
                                        <span> Email*</span>
                                        <i></i>
                                      </div>

                                      {userInfo1.IsClinicOwner === "yes" &&
                                        loggedIn && (
                                          <div className="inputbox">
                                            <input
                                              type="text"
                                              name="address1"
                                              value={formData.address1}
                                              onChange={handleChange}
                                              required
                                            />
                                            <span>Business Address*</span>
                                            <i></i>
                                          </div>
                                        )}
                                    </div>

                                    {userInfo1.IsClinicOwner === "yes" &&
                                      loggedIn && (
                                        <>
                                          <p className=" text-[14px] md:text-[18px] font-[600] md:py-1">
                                            Enter the Patient Details
                                          </p>

                                          <div className="md:flex gap-3 md:mt-7">
                                            <div className="inputbox">
                                              <input
                                                type="text"
                                                name="patientname"
                                                value={formData.patientname}
                                                onChange={handleChange}
                                                required
                                              />
                                              <span className="">
                                                Patient Name*
                                              </span>
                                              <i></i>
                                            </div>
                                            <div className="inputbox custom-phone-input">
                                              <PhoneInput
                                                defaultCountry="GB"
                                                value={formData.patientnumber}
                                                onChange={
                                                  handlePhoneNumberChange1
                                                }
                                                name="patientnumber"
                                                placeholder="Phone Number*"
                                                international
                                                withCountryCallingCode
                                                // containerClass="custom-phone-input"
                                              />
                                            </div>
                                          </div>
                                        </>
                                      )}

                                    <div className="md:flex gap-3 md:mt-2">
                                      {userInfo1.IsClinicOwner === "yes" &&
                                        loggedIn && (
                                          <div className="inputbox">
                                            <input
                                              type="email"
                                              name="patientemail"
                                              value={formData.patientemail}
                                              onChange={handleChange}
                                              required
                                            />
                                            <span> Patient Email*</span>
                                            <i></i>
                                          </div>
                                        )}

                                      <div className="inputbox">
                                        <input
                                          type="text"
                                          name="address2"
                                          value={formData.address2}
                                          onChange={handleChange}
                                          required
                                        />
                                        <span> Patient Address* </span>
                                        <i></i>
                                      </div>
                                    </div>

                                    <div className="md:flex gap-3 md:mt-2">
                                      <div className="inputbox">
                                        <input
                                          type="text"
                                          name="streetname"
                                          value={formData.streetname}
                                          onChange={handleChange}
                                          required
                                        />
                                        <span>Street Name and Number*</span>
                                        <i></i>
                                      </div>

                                      <div className="inputbox">
                                        <input
                                          type="text"
                                          name="city"
                                          value={formData.city}
                                          onChange={handleChange}
                                          required
                                        />
                                        <span>City*</span>
                                        <i></i>
                                      </div>
                                    </div>
                                    <div className="md:flex gap-3 md:mt-2">
                                      <div className="inputbox">
                                        <input
                                          type="text"
                                          name="postalCode"
                                          value={formData.postalCode}
                                          onChange={handleChange}
                                          required
                                        />
                                        <span>Postal Code*</span>
                                        <i></i>
                                      </div>

                                      <div className="inputbox z-10">
                                        <div className="select-wrapper">
                                          <select
                                            name="country"
                                            value={formData.country}
                                            onChange={handleChange}
                                            id="country"
                                            className="w-full custom-select h-12 px-4 rounded-md"
                                            required
                                          >
                                            <option value="">
                                              Select Country*
                                            </option>
                                            {modifiedCountries.map(
                                              (country, index) => (
                                                <option
                                                  key={index}
                                                  value={country}
                                                >
                                                  {country}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="md:flex gap-3 md:mt-2">
                                      <div className="inputbox">
                                        <div className="select-wrapper">
                                          <select
                                            name="gender"
                                            value={formData.gender}
                                            onChange={handleChange}
                                            className="custom-select"
                                            required
                                          >
                                            <option value="" className="">
                                              Select Gender*
                                            </option>
                                            <option value="Male">Male</option>
                                            <option value="Female">
                                              Female
                                            </option>
                                            <option value="Other">Other</option>
                                          </select>
                                          <i className="highlight"></i>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="md:flex gap-3 md:mt-2">
                                      {/* <div className="inputbox">
                                      <input type="text" name="postalCode"
                                        value={formData.postalCode}
                                        onChange={handleChange} required />
                                      <span>Postal Code*</span>
                                      <i></i>
                                    </div> */}

                                      {/* <div className="inputbox z-10">
                                      <div className="select-wrapper">
                                        <select
                                          name="country"
                                          value={formData.country}
                                          onChange={handleChange}
                                          id="country"
                                          className="w-full custom-select h-12 px-4 rounded-md"
                                          required
                                        >
                                          <option value="">Select Country*</option>
                                          {countries.map((country, index) => (
                                            <option key={index} value={country}>
                                              {country}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div> */}
                                    </div>
                                    <div className="md:flex gap-3">
                                      <div className="inputbox">
                                        <input
                                          type="text"
                                          name="nhsNumber"
                                          value={formData.nhsNumber}
                                          onChange={handleChange}
                                          required
                                        />
                                        <span>Patient NHS number</span>
                                        <i></i>
                                      </div>

                                      <div className="inputbox ">
                                        <div className="select-wrapper ">
                                          <RequiredTestsDropdown
                                            formData={formData}
                                            setFormData={setFormData}
                                          />
                                          <ul>
                                            {Array.isArray(
                                              formData.requiredTests
                                            ) &&
                                              formData.requiredTests.length >
                                                0 &&
                                              formData.requiredTests.map(
                                                (test) => (
                                                  <li key={test.value}>
                                                    {test.label}
                                                  </li>
                                                )
                                              )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    {userInfo1.IsClinicOwner === "yes" &&
                                      loggedIn && (
                                        <div className="md:flex gap-3 md:mt-2 ">
                                          <div className="inputbox">
                                            <div className="!w-full">
                                              <input
                                                type="text"
                                                name="TubesNumber"
                                                value={formData.TubesNumber}
                                                onChange={handleChange}
                                                required
                                              />
                                              <span> Tubes Number </span>
                                              <i></i>
                                            </div>
                                          </div>

                                          <div className="inputbox">
                                            <div className="select-wrapper">
                                              <ResuestSample
                                                formData={formData}
                                                setFormData={setFormData}
                                              />
                                              <ul>
                                                {Array.isArray(
                                                  formData.SerumTypes
                                                ) &&
                                                  formData.SerumTypes.length >
                                                    0 &&
                                                  formData.SerumTypes.map(
                                                    (test) => (
                                                      <li key={test.value}>
                                                        {test.label}
                                                      </li>
                                                    )
                                                  )}
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="border-[1px] border-[#4ab0b0] rounded-[6px] w-full h-[48px]">
                                            <div className="flex items-center h-full justify-around">
                                              <div className="flex items-center gap-4">
                                                <label
                                                  htmlFor="fasting"
                                                  className=""
                                                >
                                                  <p className="">Fasting </p>
                                                </label>
                                                <input
                                                  type="checkbox"
                                                  id="fasting"
                                                  name="fasting"
                                                  checked={formData.fasting}
                                                  onChange={handleChange12}
                                                  className="form-checkbox h-5 w-5"
                                                />
                                              </div>

                                              <div className="flex items-center gap-4">
                                                <label
                                                  htmlFor="Pregnant"
                                                  className=""
                                                >
                                                  <p className="">Pregnant </p>
                                                </label>
                                                <input
                                                  type="checkbox"
                                                  id="Pregnant"
                                                  name="Pregnant"
                                                  checked={formData.Pregnant}
                                                  onChange={handleChange12}
                                                  className="form-checkbox h-5 w-5"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                    <div className="md:flex gap-3 md:mt-2 ">
                                      <div className="inputbox">
                                        <div className="!w-full">
                                          <input
                                            type="text"
                                            name="clinicComplain"
                                            value={formData.clinicComplain}
                                            onChange={handleChange}
                                            required
                                          />
                                          <span> Clinical Complain </span>
                                          <i></i>
                                        </div>
                                      </div>

                                      <div className="inputbox">
                                        <div className="!w-full">
                                          <input
                                            type="text"
                                            name="additionalRemark"
                                            value={formData.additionalRemark}
                                            onChange={handleChange}
                                            required
                                          />
                                          <span> Additional remark </span>
                                          <i></i>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="md:flex gap-3 md:mt-2 ">
                                      {/* {userInfo1.IsClinicOwner === "yes" && loggedIn && (

                                      <div className="inputboxDate border-[1px] border-[#4ab0b0] rounded-[6px]">
                                        <div className="!w-full">
                                          <DatePicker
                                            name="bookingDate"
                                            selected={formData.bookingDate}
                                            onChange={handleDateChange}
                                            showTimeSelect
                                            showTimeSelectOnly={false}
                                            timeIntervals={15}
                                            timeFormat="h:mm aa"
                                            dateFormat="dd-MM-yyyy h:mm aa"
                                            placeholderText="Collection date and time"
                                            className="!w-[280px] bg-transparent outline-none xl:text-[18px]  text-[14px]"
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={50}
                                            popperClassName="custom-datepicker"

                                          />
                                        </div>
                                      </div>
                                    )} */}

                                      <div className="inputboxDate border-[1px] border-[#4ab0b0] rounded-[6px]">
                                        <DatePicker
                                          name="dob"
                                          selected={
                                            formData.dob
                                              ? new Date(
                                                  formData.dob
                                                    .split("-")
                                                    .reverse()
                                                    .join("-")
                                                )
                                              : null
                                          } // Convert 'dd-MM-yyyy' string back to Date object
                                          onChange={handleDateChange1}
                                          dateFormat="dd-MM-yyyy"
                                          placeholderText="DOB(dd-mm-yyyy)"
                                          className="w-full h-[40px] md:h-[50px] outline-none mb-2 text-[#234971] block indent-5 rounded-[5px]"
                                          showYearDropdown
                                          scrollableYearDropdown
                                          yearDropdownItemNumber={50}
                                          popperClassName="custom-datepicker"
                                        />
                                      </div>
                                    </div>

                                    <div className="flex justify-center">
                                      <button
                                        type="submit"
                                        className="text-[#11485B] bg-[#ffffff] lg:w-[300px] p-2 w-auto h-[40px] rounded-[20px] flex justify-center items-center cursor-pointer"
                                      >
                                        <p className="text-[12px]">
                                          Request Quote
                                        </p>
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                // {/* </div> */}
                              )}

                              {/* form 2 */}
                              {book2 && (
                                <div className="lg:mx-[80px]  text-[#ffffff] border-[1px] border-white relative bg-[#11485B] h-auto lg:min-w-[800px] md:w-[600px] w-full md:rounded-[30px] rounded-[20px] py-6 shadow-lg">
                                  <h2 className="text-center text-2xl font-bold mb-5">
                                    Booking Form
                                  </h2>
                                  <form onSubmit={handleBooking1}>
                                    <div className="lg:px-10 px-5 ">
                                      <div
                                        className="absolute md:right-6 md:top-5 top-8 right-4 cursor-pointer"
                                        onClick={closePopup12}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth="2"
                                          stroke="currentColor"
                                          className="w-4 h-4"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6 18L18 6M6 6l12 12"
                                          />
                                        </svg>
                                      </div>

                                      {/* Clinic and Phone Number */}
                                      <div className="md:flex gap-3 md:mt-7 mt-5 ">
                                        <div className="inputbox flex-1">
                                          <input
                                            type="text"
                                            name="clinicName1"
                                            value={formData1.clinicName1}
                                            onChange={handleChange1}
                                            required
                                            className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                                          />
                                          <span className="text-sm text-gray-300">
                                            Business Name*
                                          </span>
                                        </div>
                                        <div className="inputbox custom-phone-input flex-1">
                                          <PhoneInput
                                            defaultCountry="GB"
                                            value={formData1.phoneNumber1}
                                            onChange={handlePhoneNumberChange12}
                                            name="phoneNumber1"
                                            placeholder="Phone Number*"
                                            international
                                            withCountryCallingCode
                                            className="w-full p-2 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                                          />
                                        </div>
                                      </div>

                                      {/* Email and Address */}
                                      <div className="md:flex gap-3 md:mt-2">
                                        <div className="inputbox mt-7 md:mt-4 flex-1">
                                          <input
                                            type="email"
                                            name="email1"
                                            value={formData1.email1}
                                            onChange={handleChange1}
                                            required
                                            className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                                          />
                                          <span className="text-sm text-gray-300">
                                            Email*
                                          </span>
                                        </div>
                                        <div className="inputbox mt-7 md:mt-4 flex-1">
                                          <input
                                            type="text"
                                            name="address11"
                                            value={formData1.address11}
                                            onChange={handleChange1}
                                            required
                                            className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                                          />
                                          <span className="text-sm text-gray-300">
                                            Business Address*
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    {/* <div className="flex justify-center mt-6">
                                      <button
                                        type="button"
                                        onClick={addMaterialRow}
                                        className="text-[#11485B] bg-[#ffffff] text-[14px] lg:text-[18px] w-[280px] p-2 md:h-[40px] rounded-[5px] hover:bg-[#0ca0b0] font-[600]"
                                      >
                                        Add Supply Request
                                      </button>
                                    </div> */}

                                    {/* Materials Table */}
                                    <div className="lg:px-5 px-5">
                                      {(formData1.materials || []).map(
                                        (material, index) => (
                                          <div className="bg-[#0F4651] shadow-lg shadow-cyan-500/50 lg:px-5 px-5 mt-5 pb-5 rounded-[10px] ">
                                            <div className="flex justify-end w-full">
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  removeMaterialRow(index)
                                                }
                                                className="md:ml-2 mt-6"
                                              >
                                                <MdDeleteOutline className="text-[30px]" />
                                              </button>
                                            </div>
                                            <div
                                              key={index}
                                              className="md:flex gap-3 md:mt-2"
                                            >
                                              <div className="inputbox mt-4 flex-1">
                                                <input
                                                  type="text"
                                                  name="materialName"
                                                  value={material.materialName}
                                                  onChange={(e) =>
                                                    handleMaterialChange(
                                                      index,
                                                      e
                                                    )
                                                  }
                                                  required
                                                  className="w-full p-2  rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                                                />
                                                <span className="text-sm text-gray-300">
                                                  Material Name
                                                </span>
                                              </div>
                                              <div className="inputbox mt-4 flex-1">
                                                <input
                                                  type="text"
                                                  name="qty"
                                                  value={material.qty}
                                                  onChange={(e) =>
                                                    handleMaterialChange(
                                                      index,
                                                      e
                                                    )
                                                  }
                                                  required
                                                  className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                                                />
                                                <span className="text-sm text-gray-300">
                                                  Qty
                                                </span>
                                              </div>
                                            </div>
                                            <div className="md:flex gap-3 md:mt-2">
                                              <div className="inputbox mt-4 flex-1">
                                                <input
                                                  type="text"
                                                  name="unit"
                                                  value={material.unit}
                                                  onChange={(e) =>
                                                    handleMaterialChange(
                                                      index,
                                                      e
                                                    )
                                                  }
                                                  required
                                                  className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                                                />
                                                <span className="text-sm text-gray-300">
                                                  Unit
                                                </span>
                                              </div>
                                              <div className="inputbox mt-4 flex-1">
                                                <input
                                                  type="text"
                                                  name="manufacturerName"
                                                  value={
                                                    material.manufacturerName
                                                  }
                                                  onChange={(e) =>
                                                    handleMaterialChange(
                                                      index,
                                                      e
                                                    )
                                                  }
                                                  required
                                                  className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                                                />
                                                <span className="text-sm text-gray-300">
                                                  Current Stock
                                                </span>
                                              </div>
                                            <div className="md:flex gap-3 md:mt-2">
                                              <div className="inputbox mt-4 flex-1">
                                                <input
                                                  type="text"
                                                  name="batchNumber"
                                                  value={material.batchNumber}
                                                  onChange={(e) =>
                                                    handleMaterialChange(
                                                      index,
                                                      e
                                                    )
                                                  }
                                                  required
                                                  className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                                                />
                                                <span className="text-sm text-gray-300">
                                                  Expected Delivery{" "}
                                                </span>
                                              </div>
                                              <div className="inputbox mt-4 flex-1">
                                                <input
                                                  type="text"
                                                  name="expiryDate"
                                                  value={material.expiryDate}
                                                  onChange={(e) =>
                                                    handleMaterialChange(
                                                      index,
                                                      e
                                                    )
                                                  }
                                                  required
                                                  className="appearance-none focus:text-black w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                                                  onFocus={(e) =>
                                                    (e.target.type = "date")
                                                  }
                                                  onBlur={(e) => {
                                                    if (!e.target.value)
                                                      e.target.type = "text";
                                                  }}
                                                  placeholder=""
                                                />
                                                <span className="text-sm text-gray-300">
                                                  Expiry Date
                                                </span>
                                              </div>
                                            </div>
                                            <div className="md:flex gap-3 md:mt-2"></div>
                                          </div>
                                          <div key={index} className="md:flex gap-3 md:mt-2">
                                            <div className="inputbox mt-4 flex-1">
                                              <input
                                                type="text"
                                                name="materialName"
                                                value={material.materialName}
                                                onChange={(e) => handleMaterialChange(index, e)}
                                                required
                                                className="w-full p-2  rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                                              />
                                              <span className="text-sm text-gray-300">Material Name</span>
                                            </div>
                                            <div className="inputbox mt-4 flex-1">
                                              <input
                                                type="text"
                                                name="qty"
                                                value={material.qty}
                                                onChange={(e) => handleMaterialChange(index, e)}
                                                required
                                                className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                                              />
                                              <span className="text-sm text-gray-300">Qty</span>
                                            </div>
                                          </div>
                                          <div className="md:flex gap-3 md:mt-2">
                                            <div className="inputbox mt-4 flex-1">
                                              <input
                                                type="text"
                                                name="unit"
                                                value={material.unit}
                                                onChange={(e) => handleMaterialChange(index, e)}
                                                required
                                                className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                                              />
                                              <span className="text-sm text-gray-300">Unit(Eg:Box,Pack,Pieces)</span>
                                            </div>
                                            <div className="inputbox mt-4 flex-1">
                                              <input
                                                type="text"
                                                name="manufacturerName"
                                                value={material.manufacturerName}
                                                onChange={(e) => handleMaterialChange(index, e)}
                                                required
                                                className="w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                                              />
                                              <span className="text-sm text-gray-300">Current Stock</span>
                                            </div>
                                          </div>
                                          <div className="md:flex gap-3 md:mt-2">
                                            <div className="inputbox mt-4 flex-1">
                                              <input
                                                type="text"
                                                name="batchNumber"
                                                value={material.batchNumber}
                                                onChange={(e) => handleMaterialChange(index, e)}
                                                required
                                                className="appearance-none focus:text-black w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                                                onFocus={(e) => (e.target.type = "date")}
                                                onBlur={(e) => {
                                                  if (!e.target.value) e.target.type = "text";
                                                }}
                                                placeholder=""
                                              />
                                              <span className="text-sm text-gray-300">Expected Delivery Date </span>
                                            </div>
                                            <div className="inputbox mt-4 flex-1">
                                              <input
                                                type="text"
                                                name="expiryDate"
                                                value={material.expiryDate}
                                                onChange={(e) => handleMaterialChange(index, e)}
                                                required
                                                className="appearance-none focus:text-black w-full p-2 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                                                onFocus={(e) => (e.target.type = "date")}
                                                onBlur={(e) => {
                                                  if (!e.target.value) e.target.type = "text";
                                                }}
                                                placeholder=""
                                              />
                                              <span className="text-sm text-gray-300">Current Expiry Date</span>
                                            </div>
                                          </div>
                                          <div className="md:flex gap-3 md:mt-2">

                                          </div>
                                        </div>
                                      ))}

                                    </div>

                                    <div className="flex justify-center mt-6">
                                      <button
                                        type="button"
                                        onClick={addMaterialRow}
                                        className="text-[#11485B] bg-[#ffffff] text-[14px] lg:text-[18px] w-[280px] p-2 md:h-[40px] rounded-[5px] hover:bg-[#0ca0b0] font-[600]"
                                      >
                                        {formData1?.materials?.length > 0
                                          ? "Add More Supply "
                                          : "Add Supply Request"}
                                      </button>
                                    </div>

                                    {formData1?.materials?.length > 0 && (
                                      <div className="flex justify-center mt-6">
                                        <button
                                          type="submit"
                                          className="text-[#11485B] text-[14px] lg:text-[18px] bg-[#ffffff] w-[280px] p-2 md:h-[40px] rounded-[5px] hover:bg-[#0ca0b0] font-[600]"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    )}
                                  </form>
                                </div>
                              )}
                              </div>
                            </div>
                          </div>
                      )}

                      {popup.visible && (
                        <div
                          className={`absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-75`}
                        >
                          <div
                            className={`bg-${
                              popup.isError ? "red" : "green"
                            }-500 text-white justify-center  p-4 rounded`}
                          >
                            <p> {popup.message}</p>

                            {!popup.isError && (
                              <p>
                                Team will contact you for further information.
                              </p>
                            )}
                            <button
                              className="mt-2 px-4 py-2 bg-white text-black rounded "
                              onClick={closePopup}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        
        )}
        {popup12.visible && (
          <div
            className={`absolute top-0 z-50 left-0 w-full h-screen flex items-center justify-center bg-black bg-opacity-75`}
          >
            <div
              className={`bg-${
                popup12.isError ? "red" : "green"
              }-500 text-white justify-center p-4 rounded`}
            >
              <p>{popup12.message}</p>
              {!popup12.isError && <p>Team will contact you sortly.</p>}
              <button
                className="mt-2 px-4 py-2 bg-white text-black rounded"
                onClick={closePopup12}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default OurService;
