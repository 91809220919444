import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Footer() {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(`${baseUrl}/home/services`);
        if (response.status === 200) {
          const data = await response.json();
          // console.log('Fetched data:', data);
          setImages(data);
        } else {
          throw new Error('Unable to fetch data');
        }
      } catch (err) {
        console.error(err);
        setImages([]);
      }
    };

    fetchServices();
  }, []);

  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for accordion sections
  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const navigate = useNavigate();

  const backgroundImage = "url('/assets/footerbg.jpeg')"

  const scrollToDoctor = () => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById("doctor");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 200);
  };


  const scrollToTeam = () => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById("ourteam");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 200);
  };
  const scrollToService = () => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById("service");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 200);
  };

  const scrollToBlog = () => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById("blog");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 200);
  };

  const scrollToPartner = () => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById("partner");
      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;

        window.scrollTo({
          top: elementPosition - 150,
          behavior: 'smooth'
        });
      }
    }, 200);
  };
  const scrollContact = () => {
    navigate("/");
    setTimeout(() => {
      const element = document.getElementById("contact");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 200);

  };
  return (



    <div className="overflow-hidden md:h-[780px] lg:h-[540px] relative"
      style={{ backgroundImage: backgroundImage, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >

      <div className="hidden md:flex justify-between items-center xl:px-20 bg-black bg-opacity-[50%] h-full relative w-full">
        <div className="md:absolute lg:relative lg:bottom-0 bottom-[100px]">
          <div className=" md:px-10 lg:px-8 hidden md:flex xl:gap-12 lg:gap-1 md:gap-6 tracking-[0.5px] relative">
            <div className="xl:w-[30%]  lg:w-[25%] md:w-[30%]">
              <p className="xl:text-[20px] md:text-[20px] text-[20px] font-[700] text-[#FFFFFF] ">Quick links</p>
              <ul className='text-[16px] font-[400] text-[#FFFFFF] lg:text-start cursor-pointer'>
                <Link to="/aboutus">
                  <li className=' md:py-[3px] lg:py-[6px] hover:text-[#A3D284]'>
                    About us
                  </li>
                </Link>
                <li onClick={scrollToService} className=' md:py-[3px] lg:py-[6px] hover:text-[#A3D284]'>
                  Our Services
                </li>
                <li onClick={scrollToBlog} className=' md:py-[3px] lg:py-[6px] hover:text-[#A3D284]'>
                  Blogs
                </li>
                <li onClick={scrollToTeam} className=' md:py-[3px] lg:py-[6px] hover:text-[#A3D284]'>
                  Our Team
                </li>
                <li onClick={scrollToDoctor} className=' md:py-[3px] lg:py-[6px] hover:text-[#A3D284]'>
                  Doctors
                </li>
                <li onClick={scrollToPartner} className=' md:py-[3px] lg:py-[6px] hover:text-[#A3D284]'>
                  Our Partners
                </li>
                <li onClick={scrollContact} className=' md:py-[3px] lg:py-[6px] hover:text-[#A3D284]'>
                  Contact Us
                </li>
              </ul>
            </div>
            <div className="lg:w-[60%] ml-4  md:w-[50%] xl:w-[100%] ">
              <p className="xl:text-[20px] ml-2 md:text-[20px] text-[20px] font-[700] text-[#FFFFFF] ">Our Services</p>
              <ul className='text-[16px] font-[400] text-[#FFFFFF] lg:text-start cursor-pointer'>
                {images.map((item, idx) => (
                  <Link key={idx} to={`/ourservice?id=${item.categoryId}`}
                  >
                    <li className='py-[4px] md:py-[3px] lg:py-[6px] hover:text-[#A3D284]'>
                      {item.title}
                    </li>
                  </Link>
                ))}
              </ul>
            </div>
            {/*  */}
            <div className="w-[30%] lg:w-[45%]  lg:ml-2 xl:ml-0 ml-0">
              <p className="xl:text-[20px] md:text-[20px] text-[20px] font-[700] text-[#FFFFFF] ">Legal</p>
              <ul className='text-[16px] font-[400] text-[#FFFFFF] lg:text-start cursor-pointer'>
                <a href="/privacyandpolicy" target="_blank" rel="noopener noreferrer">

                  <li className='py-[10px] md:py-[3px] lg:py-[6px] hover:text-[#A3D284]'>
                    Privacy Policy
                  </li>
                </a>
                <a href="/termscondition" target="_blank" rel="noopener noreferrer">

                  <li className='py-[10px] md:py-[3px] lg:py-[6px] hover:text-[#A3D284]'>
                    Terms & Conditions
                  </li>

                </a>

                <a href="/legalNotice" target="_blank" rel="noopener noreferrer">

                  <li className='py-[10px] md:py-[3px] lg:py-[6px] hover:text-[#A3D284]'>
                    Legal Notice
                  </li>
                </a>

                <a href="/dataProtection" target="_blank" rel="noopener noreferrer">

                  <li className='py-[10px] md:py-[3px] lg:py-[6px] hover:text-[#A3D284]'>
                    Data Protection
                  </li>

                </a>
              </ul>
            </div>
          </div>
          <div className="absolute -bottom-10 flex justify-center w-full">
            <p className="lg:hidden text-white text-[10px] text-center font-[400]">© 2024 AquaLab. All rights reserved.</p>

          </div>

        </div>
        {/* <div className="">
          <img src="./assets/logo12.png" alt="" className='w-[130px]' />
        </div> */}
        <div className="h-[300px] md:absolute lg:relative lg:top-0 top-4 self-start lg:w-[25%] w-full lg:h-full flex justify-center items-start mt-10 py-5 md:py-0">
          <div className="flex justify-around w-full lg:block items-center">
            <div className="flex justify-censter">
              <div className="flex gap-5 lg:gap-0 justify-center lg:flex-col items-center">
                <img src="../assets/animate_logo.png" alt="" className='xl:h-[150px] xl:w-[150px] lg:h-[120px] lg:w-[120px] h-[100px] w-[100px]' />
                <p className="xl:text-[30px] md:text-[20px] font-[600] text-[#ffffff] text-center">AQUA MEDICAL <br /> LABORATORIES</p>
                <p className="text-white text-[10px] text-center font-[400] hidden lg:block">© 2024 Aqua Medical Laboratories. All rights reserved.</p>
              </div>
            </div>
            <div className="mt-3">

              <p className="text-[16px] font-[600] text-[#ffffff] mt-2">Follow Us </p>
              <div className="flex gap-5 justify-between w-[180px]   my-2 -ml-[175px]">
                <img src="./assets/logo12.png" alt="" className='w-[160px]' />

                <div className="cursor-pointer">
                  <a target='_blank' href=" https://www.instagram.com/aquamedicallaboratories?igsh=OWZhZHQ5dTMyZWRo&utm_source=qr">
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.90263 0.625C3.90775 0.625 0.625 3.90325 0.625 7.90263V18.0974C0.625 22.0923 3.90325 25.375 7.90263 25.375H18.0974C22.0923 25.375 25.375 22.0968 25.375 18.0974V7.90375C25.375 3.90775 22.0968 0.625 18.0974 0.625H7.90263ZM7.90263 2.875H18.0974C18.7579 2.87411 19.412 3.00355 20.0224 3.2559C20.6328 3.50824 21.1874 3.87854 21.6544 4.34558C22.1215 4.81261 22.4918 5.3672 22.7441 5.97759C22.9965 6.58797 23.1259 7.24214 23.125 7.90263V18.0974C23.1259 18.7579 22.9965 19.412 22.7441 20.0224C22.4918 20.6328 22.1215 21.1874 21.6544 21.6544C21.1874 22.1215 20.6328 22.4918 20.0224 22.7441C19.412 22.9965 18.7579 23.1259 18.0974 23.125H7.90375C7.24317 23.126 6.58888 22.9967 5.97836 22.7444C5.36785 22.4921 4.81313 22.1219 4.34597 21.6548C3.87882 21.1878 3.50842 20.6331 3.256 20.0227C3.00359 19.4122 2.87411 18.758 2.875 18.0974V7.90375C2.87396 7.24317 3.00329 6.58888 3.25557 5.97836C3.50785 5.36785 3.87813 4.81313 4.34518 4.34597C4.81223 3.87882 5.36687 3.50842 5.97732 3.256C6.58778 3.00359 7.24204 2.87411 7.90263 2.875ZM19.6443 5.3365C19.5102 5.3359 19.3774 5.36186 19.2535 5.41287C19.1296 5.46388 19.017 5.53894 18.9222 5.6337C18.8274 5.72847 18.7524 5.84107 18.7014 5.965C18.6504 6.08893 18.6244 6.22173 18.625 6.35575C18.625 6.92275 19.0772 7.375 19.6443 7.375C19.7784 7.37574 19.9113 7.3499 20.0354 7.29895C20.1594 7.24801 20.2721 7.17298 20.367 7.0782C20.4619 6.98342 20.5371 6.87077 20.5881 6.74677C20.6392 6.62276 20.6652 6.48986 20.6646 6.35575C20.6652 6.22164 20.6392 6.08874 20.5881 5.96473C20.5371 5.84073 20.4619 5.72808 20.367 5.6333C20.2721 5.53852 20.1594 5.46349 20.0354 5.41255C19.9113 5.3616 19.7784 5.33575 19.6443 5.3365ZM13 6.25C9.2875 6.25 6.25 9.2875 6.25 13C6.25 16.7125 9.2875 19.75 13 19.75C16.7125 19.75 19.75 16.7125 19.75 13C19.75 9.2875 16.7125 6.25 13 6.25ZM13 8.5C15.5009 8.5 17.5 10.4991 17.5 13C17.5 15.5009 15.5009 17.5 13 17.5C10.4991 17.5 8.5 15.5009 8.5 13C8.5 10.4991 10.4991 8.5 13 8.5Z" fill="#C4D6E0" />
                    </svg>
                  </a>
                </div>
                <div className="cursor-pointer">
                  <a target='_blank' href=" https://www.facebook.com/share/dVjXUnbfBAipVmNo/?mibextid=LQQJ4d">

                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14 0.5C6.557 0.5 0.5 6.557 0.5 14C0.5 21.443 6.557 27.5 14 27.5C21.443 27.5 27.5 21.443 27.5 14C27.5 6.557 21.443 0.5 14 0.5ZM14 2.75C20.2269 2.75 25.25 7.77313 25.25 14C25.2527 16.6932 24.287 19.2976 22.529 21.3379C20.771 23.3782 18.338 24.7184 15.674 25.1139V17.2805H18.878L19.3809 14.0259H15.674V12.2484C15.674 10.8984 16.1184 9.698 17.3818 9.698H19.4124V6.8585C19.0558 6.81013 18.3009 6.7055 16.8744 6.7055C13.8954 6.7055 12.1494 8.27825 12.1494 11.8625V14.0259H9.08713V17.2805H12.1494V25.0858C9.52222 24.6533 7.13439 23.3009 5.41253 21.2701C3.69066 19.2394 2.74695 16.6625 2.75 14C2.75 7.77313 7.77313 2.75 14 2.75Z" fill="#C4D6E0" />
                    </svg>
                  </a>
                </div>
                <div className="cursor-pointer">
                  <a target='_blank' href="https://x.com/aquamedlabs?t=Sr3kp-DHs6ZuKbxu85RKXQ&s=09">


                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.2737 10.601L23.2023 0.4375H21.0872L13.3313 9.26055L7.14125 0.4375H0L9.3626 13.7808L0 24.4375H2.11504L10.3002 15.1181L16.8388 24.4375H23.98M2.8784 1.9994H6.12769L21.0856 22.9523H17.8355" fill="#E9EDEA" />
                    </svg>

                  </a>

                </div>
                <div className="cursor-pointer">
                  <a target='_blank' href="https://www.linkedin.com/company/aqua-medical-laboratories">

                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.4375 0.625C2.69322 0.630302 1.98092 0.92832 1.45462 1.45462C0.92832 1.98092 0.630302 2.69322 0.625 3.4375V22.5625C0.625 24.1004 1.89963 25.375 3.4375 25.375H22.5625C24.1004 25.375 25.375 24.1004 25.375 22.5625V3.4375C25.375 1.89963 24.1004 0.625 22.5625 0.625H3.4375ZM3.4375 2.875H22.5625C22.8831 2.875 23.125 3.11687 23.125 3.4375V22.5625C23.1265 22.6368 23.113 22.7106 23.0853 22.7796C23.0576 22.8485 23.0162 22.9111 22.9637 22.9637C22.9111 23.0162 22.8485 23.0576 22.7796 23.0853C22.7106 23.113 22.6368 23.1265 22.5625 23.125H3.4375C3.36317 23.1267 3.28927 23.1133 3.22025 23.0856C3.15124 23.058 3.08856 23.0166 3.03599 22.964C2.98341 22.9114 2.94204 22.8488 2.91437 22.7797C2.8867 22.7107 2.87331 22.6368 2.875 22.5625V3.4375C2.875 3.11687 3.11687 2.875 3.4375 2.875ZM6.74275 4.80888C6.48494 4.80266 6.22849 4.84806 5.98849 4.94243C5.74849 5.03679 5.52978 5.1782 5.34524 5.35834C5.16071 5.53848 5.01406 5.75371 4.91394 5.99137C4.81381 6.22902 4.76223 6.4843 4.76223 6.74219C4.76223 7.00007 4.81381 7.25535 4.91394 7.49301C5.01406 7.73067 5.16071 7.94589 5.34524 8.12603C5.52978 8.30617 5.74849 8.44759 5.98849 8.54195C6.22849 8.63631 6.48494 8.68172 6.74275 8.6755C7.24742 8.66333 7.72733 8.45429 8.07996 8.09305C8.43259 7.73181 8.62998 7.24701 8.62998 6.74219C8.62998 6.23737 8.43259 5.75256 8.07996 5.39132C7.72733 5.03008 7.24742 4.82105 6.74275 4.80888ZM16.9015 9.94112C15.2894 9.94112 14.2172 10.8243 13.774 11.6635H13.7031V10.1875H10.5385V20.875H13.8438V15.601C13.8438 14.2083 14.1205 12.8594 15.8474 12.8594C17.5484 12.8594 17.5709 14.4321 17.5709 15.6719V20.875H20.875V15.0036C20.875 12.1304 20.263 9.94112 16.9015 9.94112ZM5.125 10.1875V20.875H8.46513V10.1875H5.125Z" fill="#C4D6E0" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="w-full h-full bg-black bg-opacity-[50%] px-5 pb-20 md:hidden">
        <div className="flex justify-center p-3">
          <div className="flex gap-5 lg:gap-0 justify-center items-center">
            <img src="../assets/animate_logo.png" alt="" className='h-[80px] w-[80px]' />
            <p className="text-[20px] font-[600] text-[#ffffff] text-center">AQUA MEDICAL <br /> LABORATORIES</p>
            <p className="text-white text-[10px] text-center font-[400] hidden lg:block">© 2024 Aqua Medical Laboratories. All rights reserved.</p>
          </div>
        </div>
        <div className="relative w-full ">
          <div
            className="cursor-pointer relative"
            onClick={() => toggleAccordion(1)}
          >
            <div className="h-12 w-full flex items-center">
              <p className="text-[#ffffff] md:mb-2 font-[600] text-[20px]">
                Quick links
              </p>
            </div>
            <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3">
              <span
                className={`material-icons material-symbols-outlined ${openIndex === 1 ? "icon-expand-less" : "icon-expand-more"
                  }`}
              >
                {openIndex === 1 ? "-" : "+"}
              </span>
            </div>
          </div>
          <div
            className={`overflow-hidden transition-all duration-500 ${openIndex === 1 ? "max-h-[400px]" : "max-h-0"
              }`}
          >
            <ul className='text-[16px] font-[400] text-[#FFFFFF] lg:text-start cursor-pointer'>
              <Link to="/aboutus">
                <li className='py-[4px] hover:text-[#A3D284]'>
                  About us
                </li>
              </Link>
              <li onClick={scrollToService} className='py-[4px] hover:text-[#A3D284]'>
                Our Services
              </li>
              <li onClick={scrollToBlog} className='py-[4px] hover:text-[#A3D284]'>
                Blogs
              </li>
              <li onClick={scrollToTeam} className='py-[4px] hover:text-[#A3D284]'>
                Our Team
              </li>
              <li onClick={scrollToDoctor} className='py-[4px] hover:text-[#A3D284]'>
                Doctors
              </li>
              <li onClick={scrollToPartner} className='py-[4px] hover:text-[#A3D284]'>
                Our Partners
              </li>
              <li onClick={scrollContact} className='py-[4px] hover:text-[#A3D284]'>
                Contact Us
              </li>
            </ul>
          </div>
        </div>

        <div className="relative w-full ">
          <div
            className="cursor-pointer relative"
            onClick={() => toggleAccordion(2)}
          >
            <div className="h-12 w-full flex items-center">
              <p className="text-[#ffffff] md:mb-2 font-[600] text-[20px]">
                Our Services
              </p>
            </div>
            <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3">
              <span
                className={`material-icons material-symbols-outlined ${openIndex === 2 ? "icon-expand-less" : "icon-expand-more"
                  }`}
              >
                {openIndex === 2 ? "-" : "+"}
              </span>
            </div>
          </div>
          <div
            className={`overflow-hidden transition-all duration-500 ${openIndex === 2 ? "max-h-auto" : "max-h-0"
              }`}
          >
            <ul className='text-[16px] font-[400] text-[#FFFFFF] lg:text-start cursor-pointer'>
              {images.map((item, idx) => (
                <Link key={idx} to={`/ourservice?id=${item.categoryId}`}
                >
                  <li className='py-[4px] md:py-[6px] hover:text-[#A3D284]'>
                    {item.title}
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        </div>

        <div className="relative w-full ">
          <div
            className="cursor-pointer relative"
            onClick={() => toggleAccordion(3)}
          >
            <div className="h-12 w-full flex items-center">
              <p className="text-[#ffffff] md:mb-2 font-[600] text-[20px]">
                Legal
              </p>
            </div>
            <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3">
              <span
                className={`material-icons material-symbols-outlined ${openIndex === 3 ? "icon-expand-less" : "icon-expand-more"
                  }`}
              >
                {openIndex === 3 ? "-" : "+"}
              </span>
            </div>
          </div>
          <div
            className={`overflow-hidden transition-all duration-500 ${openIndex === 3 ? "max-h-60" : "max-h-0"
              }`}
          >
            <ul className='text-[16px] font-[400] text-[#FFFFFF] lg:text-start cursor-pointer'>
              <a href="/privacyandpolicy" target="_blank" rel="noopener noreferrer">
                <li className='py-[4px] md:py-[6px] hover:text-[#A3D284]'>
                  Privacy Policy
                </li>
              </a>
              <a href="/termscondition" target="_blank" rel="noopener noreferrer">

                <li className='py-[4px] md:py-[6px] hover:text-[#A3D284]'>
                  Terms & Conditions
                </li>
              </a>

              <a href="/legalNotice" target="_blank" rel="noopener noreferrer">


                <li className='py-[4px] md:py-[6px] hover:text-[#A3D284]'>
                  Legal Notice
                </li>

              </a>

              <a href="/dataProtection" target="_blank" rel="noopener noreferrer">

                <li className='py-[4px] md:py-[6px] hover:text-[#A3D284]'>
                  Data Protection
                </li>

              </a>            </ul>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="mt-3">
            <p className="text-[16px] font-[600] text-[#ffffff] text-center">Follow Us </p>
            <div className="flex justify-center mt-2 ">
              <img src="./assets/logo12.png" alt="" className='w-[150px]' />
            </div>
            <div className="flex gap-5 justify-between w-[180px] my-2">

              <div className="cursor-pointer">
                <a target='_blank' href=" https://www.instagram.com/aquamedicallaboratories?igsh=OWZhZHQ5dTMyZWRo&utm_source=qr">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.90263 0.625C3.90775 0.625 0.625 3.90325 0.625 7.90263V18.0974C0.625 22.0923 3.90325 25.375 7.90263 25.375H18.0974C22.0923 25.375 25.375 22.0968 25.375 18.0974V7.90375C25.375 3.90775 22.0968 0.625 18.0974 0.625H7.90263ZM7.90263 2.875H18.0974C18.7579 2.87411 19.412 3.00355 20.0224 3.2559C20.6328 3.50824 21.1874 3.87854 21.6544 4.34558C22.1215 4.81261 22.4918 5.3672 22.7441 5.97759C22.9965 6.58797 23.1259 7.24214 23.125 7.90263V18.0974C23.1259 18.7579 22.9965 19.412 22.7441 20.0224C22.4918 20.6328 22.1215 21.1874 21.6544 21.6544C21.1874 22.1215 20.6328 22.4918 20.0224 22.7441C19.412 22.9965 18.7579 23.1259 18.0974 23.125H7.90375C7.24317 23.126 6.58888 22.9967 5.97836 22.7444C5.36785 22.4921 4.81313 22.1219 4.34597 21.6548C3.87882 21.1878 3.50842 20.6331 3.256 20.0227C3.00359 19.4122 2.87411 18.758 2.875 18.0974V7.90375C2.87396 7.24317 3.00329 6.58888 3.25557 5.97836C3.50785 5.36785 3.87813 4.81313 4.34518 4.34597C4.81223 3.87882 5.36687 3.50842 5.97732 3.256C6.58778 3.00359 7.24204 2.87411 7.90263 2.875ZM19.6443 5.3365C19.5102 5.3359 19.3774 5.36186 19.2535 5.41287C19.1296 5.46388 19.017 5.53894 18.9222 5.6337C18.8274 5.72847 18.7524 5.84107 18.7014 5.965C18.6504 6.08893 18.6244 6.22173 18.625 6.35575C18.625 6.92275 19.0772 7.375 19.6443 7.375C19.7784 7.37574 19.9113 7.3499 20.0354 7.29895C20.1594 7.24801 20.2721 7.17298 20.367 7.0782C20.4619 6.98342 20.5371 6.87077 20.5881 6.74677C20.6392 6.62276 20.6652 6.48986 20.6646 6.35575C20.6652 6.22164 20.6392 6.08874 20.5881 5.96473C20.5371 5.84073 20.4619 5.72808 20.367 5.6333C20.2721 5.53852 20.1594 5.46349 20.0354 5.41255C19.9113 5.3616 19.7784 5.33575 19.6443 5.3365ZM13 6.25C9.2875 6.25 6.25 9.2875 6.25 13C6.25 16.7125 9.2875 19.75 13 19.75C16.7125 19.75 19.75 16.7125 19.75 13C19.75 9.2875 16.7125 6.25 13 6.25ZM13 8.5C15.5009 8.5 17.5 10.4991 17.5 13C17.5 15.5009 15.5009 17.5 13 17.5C10.4991 17.5 8.5 15.5009 8.5 13C8.5 10.4991 10.4991 8.5 13 8.5Z" fill="#C4D6E0" />
                  </svg>
                </a>
              </div>
              <div className="cursor-pointer">
                <a target='_blank' href=" https://www.facebook.com/share/dVjXUnbfBAipVmNo/?mibextid=LQQJ4d">

                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 0.5C6.557 0.5 0.5 6.557 0.5 14C0.5 21.443 6.557 27.5 14 27.5C21.443 27.5 27.5 21.443 27.5 14C27.5 6.557 21.443 0.5 14 0.5ZM14 2.75C20.2269 2.75 25.25 7.77313 25.25 14C25.2527 16.6932 24.287 19.2976 22.529 21.3379C20.771 23.3782 18.338 24.7184 15.674 25.1139V17.2805H18.878L19.3809 14.0259H15.674V12.2484C15.674 10.8984 16.1184 9.698 17.3818 9.698H19.4124V6.8585C19.0558 6.81013 18.3009 6.7055 16.8744 6.7055C13.8954 6.7055 12.1494 8.27825 12.1494 11.8625V14.0259H9.08713V17.2805H12.1494V25.0858C9.52222 24.6533 7.13439 23.3009 5.41253 21.2701C3.69066 19.2394 2.74695 16.6625 2.75 14C2.75 7.77313 7.77313 2.75 14 2.75Z" fill="#C4D6E0" />
                  </svg>
                </a>
              </div>
              <div className="cursor-pointer">
                <a target='_blank' href="https://x.com/aquamedlabs?t=Sr3kp-DHs6ZuKbxu85RKXQ&s=09">

                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.2737 10.601L23.2023 0.4375H21.0872L13.3313 9.26055L7.14125 0.4375H0L9.3626 13.7808L0 24.4375H2.11504L10.3002 15.1181L16.8388 24.4375H23.98M2.8784 1.9994H6.12769L21.0856 22.9523H17.8355" fill="#E9EDEA" />
                  </svg>

                </a>
              </div>
              <div className="cursor-pointer">
                <a target='_blank' href="https://www.linkedin.com/company/aqua-medical-laboratories">

                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.4375 0.625C2.69322 0.630302 1.98092 0.92832 1.45462 1.45462C0.92832 1.98092 0.630302 2.69322 0.625 3.4375V22.5625C0.625 24.1004 1.89963 25.375 3.4375 25.375H22.5625C24.1004 25.375 25.375 24.1004 25.375 22.5625V3.4375C25.375 1.89963 24.1004 0.625 22.5625 0.625H3.4375ZM3.4375 2.875H22.5625C22.8831 2.875 23.125 3.11687 23.125 3.4375V22.5625C23.1265 22.6368 23.113 22.7106 23.0853 22.7796C23.0576 22.8485 23.0162 22.9111 22.9637 22.9637C22.9111 23.0162 22.8485 23.0576 22.7796 23.0853C22.7106 23.113 22.6368 23.1265 22.5625 23.125H3.4375C3.36317 23.1267 3.28927 23.1133 3.22025 23.0856C3.15124 23.058 3.08856 23.0166 3.03599 22.964C2.98341 22.9114 2.94204 22.8488 2.91437 22.7797C2.8867 22.7107 2.87331 22.6368 2.875 22.5625V3.4375C2.875 3.11687 3.11687 2.875 3.4375 2.875ZM6.74275 4.80888C6.48494 4.80266 6.22849 4.84806 5.98849 4.94243C5.74849 5.03679 5.52978 5.1782 5.34524 5.35834C5.16071 5.53848 5.01406 5.75371 4.91394 5.99137C4.81381 6.22902 4.76223 6.4843 4.76223 6.74219C4.76223 7.00007 4.81381 7.25535 4.91394 7.49301C5.01406 7.73067 5.16071 7.94589 5.34524 8.12603C5.52978 8.30617 5.74849 8.44759 5.98849 8.54195C6.22849 8.63631 6.48494 8.68172 6.74275 8.6755C7.24742 8.66333 7.72733 8.45429 8.07996 8.09305C8.43259 7.73181 8.62998 7.24701 8.62998 6.74219C8.62998 6.23737 8.43259 5.75256 8.07996 5.39132C7.72733 5.03008 7.24742 4.82105 6.74275 4.80888ZM16.9015 9.94112C15.2894 9.94112 14.2172 10.8243 13.774 11.6635H13.7031V10.1875H10.5385V20.875H13.8438V15.601C13.8438 14.2083 14.1205 12.8594 15.8474 12.8594C17.5484 12.8594 17.5709 14.4321 17.5709 15.6719V20.875H20.875V15.0036C20.875 12.1304 20.263 9.94112 16.9015 9.94112ZM5.125 10.1875V20.875H8.46513V10.1875H5.125Z" fill="#C4D6E0" />
                  </svg>
                </a>
              </div>
            </div>
            <p className="text-white text-[10px] text-center font-[400]">© 2024 AquaLab. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer







{/* <div className="absolute top-0 left-0 w-full h-full bg-[#000000] bg-opacity-[70%] md:flex">
        <div className="lg:w-[30%] md:w-[s40%] lg:h-full flex justify-center items-center py-5 md:py-0">
          <div className="">
            <div className="flex justify-center">
              <img src="../assets/footer/logo.png" alt="" className='h-[100px] w-[100px] lg:h-full lg:w-full' />
            </div>
            <div className="flex justify-between w-[130px] lg:my-5 my-2">

              <div className="cursor-pointer">
                <a target='_blank' href=" https://www.instagram.com/aquamedicallaboratories?igsh=OWZhZHQ5dTMyZWRo&utm_source=qr">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.90263 0.625C3.90775 0.625 0.625 3.90325 0.625 7.90263V18.0974C0.625 22.0923 3.90325 25.375 7.90263 25.375H18.0974C22.0923 25.375 25.375 22.0968 25.375 18.0974V7.90375C25.375 3.90775 22.0968 0.625 18.0974 0.625H7.90263ZM7.90263 2.875H18.0974C18.7579 2.87411 19.412 3.00355 20.0224 3.2559C20.6328 3.50824 21.1874 3.87854 21.6544 4.34558C22.1215 4.81261 22.4918 5.3672 22.7441 5.97759C22.9965 6.58797 23.1259 7.24214 23.125 7.90263V18.0974C23.1259 18.7579 22.9965 19.412 22.7441 20.0224C22.4918 20.6328 22.1215 21.1874 21.6544 21.6544C21.1874 22.1215 20.6328 22.4918 20.0224 22.7441C19.412 22.9965 18.7579 23.1259 18.0974 23.125H7.90375C7.24317 23.126 6.58888 22.9967 5.97836 22.7444C5.36785 22.4921 4.81313 22.1219 4.34597 21.6548C3.87882 21.1878 3.50842 20.6331 3.256 20.0227C3.00359 19.4122 2.87411 18.758 2.875 18.0974V7.90375C2.87396 7.24317 3.00329 6.58888 3.25557 5.97836C3.50785 5.36785 3.87813 4.81313 4.34518 4.34597C4.81223 3.87882 5.36687 3.50842 5.97732 3.256C6.58778 3.00359 7.24204 2.87411 7.90263 2.875ZM19.6443 5.3365C19.5102 5.3359 19.3774 5.36186 19.2535 5.41287C19.1296 5.46388 19.017 5.53894 18.9222 5.6337C18.8274 5.72847 18.7524 5.84107 18.7014 5.965C18.6504 6.08893 18.6244 6.22173 18.625 6.35575C18.625 6.92275 19.0772 7.375 19.6443 7.375C19.7784 7.37574 19.9113 7.3499 20.0354 7.29895C20.1594 7.24801 20.2721 7.17298 20.367 7.0782C20.4619 6.98342 20.5371 6.87077 20.5881 6.74677C20.6392 6.62276 20.6652 6.48986 20.6646 6.35575C20.6652 6.22164 20.6392 6.08874 20.5881 5.96473C20.5371 5.84073 20.4619 5.72808 20.367 5.6333C20.2721 5.53852 20.1594 5.46349 20.0354 5.41255C19.9113 5.3616 19.7784 5.33575 19.6443 5.3365ZM13 6.25C9.2875 6.25 6.25 9.2875 6.25 13C6.25 16.7125 9.2875 19.75 13 19.75C16.7125 19.75 19.75 16.7125 19.75 13C19.75 9.2875 16.7125 6.25 13 6.25ZM13 8.5C15.5009 8.5 17.5 10.4991 17.5 13C17.5 15.5009 15.5009 17.5 13 17.5C10.4991 17.5 8.5 15.5009 8.5 13C8.5 10.4991 10.4991 8.5 13 8.5Z" fill="#C4D6E0" />
                  </svg>
                </a>
              </div>
              <div className="">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 0.5C6.557 0.5 0.5 6.557 0.5 14C0.5 21.443 6.557 27.5 14 27.5C21.443 27.5 27.5 21.443 27.5 14C27.5 6.557 21.443 0.5 14 0.5ZM14 2.75C20.2269 2.75 25.25 7.77313 25.25 14C25.2527 16.6932 24.287 19.2976 22.529 21.3379C20.771 23.3782 18.338 24.7184 15.674 25.1139V17.2805H18.878L19.3809 14.0259H15.674V12.2484C15.674 10.8984 16.1184 9.698 17.3818 9.698H19.4124V6.8585C19.0558 6.81013 18.3009 6.7055 16.8744 6.7055C13.8954 6.7055 12.1494 8.27825 12.1494 11.8625V14.0259H9.08713V17.2805H12.1494V25.0858C9.52222 24.6533 7.13439 23.3009 5.41253 21.2701C3.69066 19.2394 2.74695 16.6625 2.75 14C2.75 7.77313 7.77313 2.75 14 2.75Z" fill="#C4D6E0" />
                </svg>
              </div>
              <div className="">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.4375 0.625C2.69322 0.630302 1.98092 0.92832 1.45462 1.45462C0.92832 1.98092 0.630302 2.69322 0.625 3.4375V22.5625C0.625 24.1004 1.89963 25.375 3.4375 25.375H22.5625C24.1004 25.375 25.375 24.1004 25.375 22.5625V3.4375C25.375 1.89963 24.1004 0.625 22.5625 0.625H3.4375ZM3.4375 2.875H22.5625C22.8831 2.875 23.125 3.11687 23.125 3.4375V22.5625C23.1265 22.6368 23.113 22.7106 23.0853 22.7796C23.0576 22.8485 23.0162 22.9111 22.9637 22.9637C22.9111 23.0162 22.8485 23.0576 22.7796 23.0853C22.7106 23.113 22.6368 23.1265 22.5625 23.125H3.4375C3.36317 23.1267 3.28927 23.1133 3.22025 23.0856C3.15124 23.058 3.08856 23.0166 3.03599 22.964C2.98341 22.9114 2.94204 22.8488 2.91437 22.7797C2.8867 22.7107 2.87331 22.6368 2.875 22.5625V3.4375C2.875 3.11687 3.11687 2.875 3.4375 2.875ZM6.74275 4.80888C6.48494 4.80266 6.22849 4.84806 5.98849 4.94243C5.74849 5.03679 5.52978 5.1782 5.34524 5.35834C5.16071 5.53848 5.01406 5.75371 4.91394 5.99137C4.81381 6.22902 4.76223 6.4843 4.76223 6.74219C4.76223 7.00007 4.81381 7.25535 4.91394 7.49301C5.01406 7.73067 5.16071 7.94589 5.34524 8.12603C5.52978 8.30617 5.74849 8.44759 5.98849 8.54195C6.22849 8.63631 6.48494 8.68172 6.74275 8.6755C7.24742 8.66333 7.72733 8.45429 8.07996 8.09305C8.43259 7.73181 8.62998 7.24701 8.62998 6.74219C8.62998 6.23737 8.43259 5.75256 8.07996 5.39132C7.72733 5.03008 7.24742 4.82105 6.74275 4.80888ZM16.9015 9.94112C15.2894 9.94112 14.2172 10.8243 13.774 11.6635H13.7031V10.1875H10.5385V20.875H13.8438V15.601C13.8438 14.2083 14.1205 12.8594 15.8474 12.8594C17.5484 12.8594 17.5709 14.4321 17.5709 15.6719V20.875H20.875V15.0036C20.875 12.1304 20.263 9.94112 16.9015 9.94112ZM5.125 10.1875V20.875H8.46513V10.1875H5.125Z" fill="#C4D6E0" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="xl:w-[7s0%] w-full md:h-full px-2 lg:px-0 place-content-center">
          <div className="lg:flex lg:items-center xl:gap-10 justify-between h-full xl:pr-10">
            <div className=" px-5 md:px-0 md:flex gap-6 justify-around w-full">
              <div className=" mr-10">
                <p className="xl:text-[24px] md:text-[18px]  text-[18px] font-[700] text-[#FFFFFF] ">Quick links</p>
                <ul className='xl:text-[24px] md:text-[18px] text-[18px] font-[400] text-[#FFFFFF] lg:text-start cursor-pointer'>
                  <Link to="/aboutus">
                    <li className='py-[10px] md:py-[4px] hover:text-[#A3D284]'>
                      About us
                    </li>
                  </Link>
                  <li onClick={scrollToService} className='py-[10px] md:py-[4px] hover:text-[#A3D284]'>
                    Our Services
                  </li>
                  <li onClick={scrollToBlog} className='py-[10px] md:py-[4px] hover:text-[#A3D284]'>
                    Blogs
                  </li>
                  <li onClick={scrollToDoctor} className='py-[10px] md:py-[4px] hover:text-[#A3D284]'>
                    Doctors
                  </li>
                  <li onClick={scrollToPartner} className='py-[10px] md:py-[4px] hover:text-[#A3D284]'>
                    Our Partners
                  </li>
                  <li onClick={scrollContact} className='py-[10px] md:py-[4px] hover:text-[#A3D284]'>
                    Contact Us
                  </li>
                </ul>
              </div>
              <div className="">
                <p className="xl:text-[24px] md:text-[18px] text-[18px] font-[700] text-[#FFFFFF] ">Policies</p>
                <ul className='xl:text-[24px] md:text-[18px] text-[18px] font-[400] text-[#FFFFFF] lg:text-start cursor-pointer'>
                  <Link to="/aboutus">
                    <li className='py-[10px] md:py-[4px] hover:text-[#A3D284]'>
                      Privacy Policy
                    </li>
                  </Link>
                  <li onClick={scrollToService} className='py-[10px] md:py-[4px] hover:text-[#A3D284]'>
                    Terms & Conditions
                  </li>
                </ul>
              </div>
            </div>

            <div className="flex justify-center">
              <div className="asbsolute text-center">
                <p className="lg:text-[20px] text-[16px] text-[#FFFFFF] font-[600] pb-2">Subscribe to Newsletter</p>
                <div className="relative">
                  <input type="text" className='md:w-[385px] pr-[100px] lg:h-[45px] w-[200px] h-[40px] bg-[#D9D9D9] outline-0 pl-9 rounded-[25px]' placeholder='Email' />
                  <div className="absolute lg:top-4 top-[14px] left-3">
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196667 15.0217 0.000666667 14.5507 0 14V2C0 1.45 0.196 0.979333 0.588 0.588C0.98 0.196666 1.45067 0.000666667 2 0H18C18.55 0 19.021 0.196 19.413 0.588C19.805 0.98 20.0007 1.45067 20 2V14C20 14.55 19.8043 15.021 19.413 15.413C19.0217 15.805 18.5507 16.0007 18 16H2ZM10 9L2 4V14H18V4L10 9ZM10 7L18 2H2L10 7ZM2 4V2V14V4Z" fill="#234971" fill-opacity="0.7" />
                    </svg>
                  </div>
                  <button className='absolute right-1 top-[3px] ml-3 bg-[#A3D284] lg:w-[80px] lg:h-[40px] w-[60px] h-[35px] text-[#234971] rounded-[20px] lg:text-[18px] text-[16px]'>Send</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className='absolute bottom-12 lg:bottom-20 xl:bottom-14 text-white lg:text-[14px] text-[10px] text-center font-[400]'>
        <p>
          © 2024 Aqua Medical Laboratories. All rights reserved. Content, including text, images, and logos, may not be reproduced or distributed without permission.
        </p>
      </div> */}