import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css"; // Import the default styles
function OurTeam() {
  const navigate = useNavigate();
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  console.log(baseUrl)
  const [formData, setFormData] = useState({
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    resume: null,
  });
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [teamData, setTeamData] = useState([]);

  useEffect(() => {
    const fetchDoctorData = async () => {
      try {
        const response = await fetch(`${baseUrl}/home/getallteam`);
        if (response.status === 200) {
          const data = await response.json();
          console.log("Fetched DOC data:", data);
          setTeamData(data);
        } else {
          throw new Error("Unable to fetch data");
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchDoctorData();
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'resume' ? files[0] : value,
    });
  };





  const handlePhoneNumberChange = (phoneNumber) => {
    setFormData({ ...formData, phoneNumber });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('title', formData.title);

    form.append('firstName', formData.firstName);
    form.append('lastName', formData.lastName);

    form.append('email', formData.email);
    form.append('phoneNumber', formData.phoneNumber);
    form.append('resume', formData.resume);

    try {
      const response = await axios.post(`${baseUrl}/career/submitt`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage(response.data.message);
      setErrorMessage('');
      setFormData({
        firstName: '',
        lastName:'',
        email: '',
        phoneNumber: '',
        resume: null,
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage('Error submitting form. Please try again.');
      setMessage('');
    }
  };




  return (
    <div className='pt-20 lg:pt-[140px] pb-20 h-auto relative'>
      <div className="absolute -mt-[5px] h-full w-full -z-10 hidden xl:block">
        <img src="./assets/bg_blue.png" alt="" className="object-fill h-full w-full absolute top-0 -z-20" />
        <img src="./assets/bg_green.png" alt="" className="object-fill h-full w-full absolute top-0 -z-40" />
      </div>
      <div className="xl:px-[120px] px-5"
      >
        <button onClick={() => navigate(-1)} className="flex gap-4 items-center cursor-pointer w-[100px] ">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 11.4997H7.82999L12.71 6.61967C13.1 6.22967 13.1 5.58967 12.71 5.19967C12.6175 5.10697 12.5076 5.03342 12.3866 4.98324C12.2656 4.93306 12.136 4.90723 12.005 4.90723C11.874 4.90723 11.7443 4.93306 11.6234 4.98324C11.5024 5.03342 11.3925 5.10697 11.3 5.19967L4.70999 11.7897C4.61728 11.8822 4.54373 11.9921 4.49355 12.113C4.44337 12.234 4.41754 12.3637 4.41754 12.4947C4.41754 12.6256 4.44337 12.7553 4.49355 12.8763C4.54373 12.9973 4.61728 13.1072 4.70999 13.1997L11.3 19.7897C11.3926 19.8823 11.5025 19.9557 11.6234 20.0058C11.7444 20.0559 11.8741 20.0817 12.005 20.0817C12.1359 20.0817 12.2656 20.0559 12.3865 20.0058C12.5075 19.9557 12.6174 19.8823 12.71 19.7897C12.8026 19.6971 12.876 19.5872 12.9261 19.4662C12.9762 19.3453 13.002 19.2156 13.002 19.0847C13.002 18.9537 12.9762 18.8241 12.9261 18.7031C12.876 18.5822 12.8026 18.4723 12.71 18.3797L7.82999 13.4997H19C19.55 13.4997 20 13.0497 20 12.4997C20 11.9497 19.55 11.4997 19 11.4997Z"
              fill="#202020"
            />
          </svg>
          <p className="text-[#202020] lg:md:text-[24px] font-[600]">
            Back
          </p>
        </button>
      </div>
      <p
        className="text-[#234971] md:text-[36px] text-[24px] font-[600] flex justify-center relative z-10"
      >
        MEET OUR TEAM
      </p>
      <div className="text-black pb-20 lg:pb-0 relative bg-cover flex flex-wrap gap-5 lg:justify-between justify-center">
        <div className="xl:px-20 md:px-5 px-2 flex w-full justify-center pb-5">
          <div className="pt-5 grid md:grid-cols-3 xl:gap-20 gap-10">
            {/* Repeatable Team Member Card */}
            {teamData.map((item, index) => (

              <Link to={`/teamProfile?id=${item.id}`} key={index}>

                <div className="relative group overflow-hidden rounded-xl">
                  <img
                    src={item.img}
                    alt="Team Member"
                    className="object-cover lg:h-[500px] lg:w-[360px] h-[300px] w-[230px] md:h-[340px] md:w-[260px] transition-transform duration-300 ease-in-out transform group-hover:scale-105"
                  />
                  <div className="absolute top-0 left-0 h-full w-full flex items-end bg-gradient-to-b from-transparent via-[#000] to-[#000] opacity-0 group-hover:opacity-70 transition-opacity duration-300 ease-in-out z-10">
                    <div className="p-4">
                      <p className="text-[#ffffff] md:text-[20px] text-[16px] font-[600]">
                        {item.name}
                      </p>
                      <p className="text-[#ffffff] md:text-[16px] text-[12px] font-[400]">
                        {item.position}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-[#C4D6E0] text-[#202020]  flex justify-center relative top-8  w-full">
        <div className="py-10 xl:px-[220px]">
          <p className="xl:text-[48px] lg:text-[24px] text-[22px] font-[700] text-center underline underline-offset-8 decoration-[#139EB5]">Join our team</p>
          <p className="lg:text-[24px] text-[18px] text-center">Are you passionate about making a difference in healthcare? Join our dynamic team at AQUA Medical Laboratories and help us revolutionize medical diagnostics. Explore exciting career opportunities and grow with us!</p>
          <form onSubmit={handleSubmit}>
            <div className="md:flex gap-6 lg:px-20 px-5 mt-10">
              <select
                value={formData.title}
                onChange={handleChange}
                name="title"
                placeholder="Title"
                id="title"
                className="h-[60px] outline-none bg-white w-full pl-5 text-[#234971]" required
              >
                <option value="" disabled>
                  Title*
                </option>
                <option value="Mr.">Mr.</option>
                <option value="Mrs.">Mrs.</option>
                <option value="Miss.">Miss.</option>
                <option value="Dr.">Dr.</option>


              </select>

              <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} className="h-[60px] outline-none bg-white w-full pl-5 text-[#234971]" placeholder="First Name" />

              <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} className="h-[60px] outline-none bg-white w-full pl-5 text-[#234971]" placeholder="Last Name" />
              <input type="text" name="email" value={formData.email} onChange={handleChange} className="h-[60px] outline-none bg-white w-full pl-5 text-[#234971] mt-5 md:mt-0" placeholder="Email Address" />
            </div>
            <div className="md:flex gap-6 lg:px-20 px-5 mt-5">
              <PhoneInput
                defaultCountry="GB"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="Phone Number*"
                className="h-[60px] outline-none bg-white w-full pl-5 text-[#234971] mt-5 md:mt-0" international
                withCountryCallingCode
              />
              <div >
                <input
                  type="file"
                  name="resume"
                  id="fileInput"
                  onChange={handleChange} 
                  className="hidden" 
                />
                <label
                  htmlFor="fileInput"
                  className="h-[60px] bg-white border border-[#234971] text-[#234971] w-[400px] pl-5 pr-5 flex items-center justify-between rounded-lg cursor-pointer"  // Adjust width here
                  >
                  {formData.resume ? formData.resume.name : "Upload Resume"} 
                  <span className="ml-2 bg-[#234971] text-white px-4 py-2 rounded-lg">
                    Browse
                  </span>
                </label>
              </div>



            </div>
            <div className="flex justify-center mt-5">
              <button type="submit" className="md:w-[280px] w-[200px] md:h-[60px] h-[40px] flex justify-center items-center gap-4 md:text-[20px] text-white bg-[#234971] hover:text-[#234971] hover:bg-white transition-all duration-500 hover:border hover:border-[#234971] font-[600] rounded-[50px]">
                Submit Application
              </button>
            </div>
            {message && <div className="text-center mt-4 text-green-500">{message}</div>}
            {errorMessage && <div className="text-center mt-4 text-red-500">{errorMessage}</div>}
          </form>
        </div>
      </div>
    </div>
  )
}

export default OurTeam
