import React from 'react'

function DataProtection() {
    const handleGoBack = () => {
        window.history.back();
    };
    return (
        <>
            <div className=" lg:pt-[140px] pt-20 relative">
                <div className="flex justify-between xl:px-[120px] px-3 my-4">
                    <button
                        onClick={handleGoBack}
                        className="flex gap-4 items-center cursor-pointer w-[100px]"
                    >
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M19 11.4997H7.82999L12.71 6.61967C13.1 6.22967 13.1 5.58967 12.71 5.19967C12.6175 5.10697 12.5076 5.03342 12.3866 4.98324C12.2656 4.93306 12.136 4.90723 12.005 4.90723C11.874 4.90723 11.7443 4.93306 11.6234 4.98324C11.5024 5.03342 11.3925 5.10697 11.3 5.19967L4.70999 11.7897C4.61728 11.8822 4.54373 11.9921 4.49355 12.113C4.44337 12.234 4.41754 12.3637 4.41754 12.4947C4.41754 12.6256 4.44337 12.7553 4.49355 12.8763C4.54373 12.9973 4.61728 13.1072 4.70999 13.1997L11.3 19.7897C11.3926 19.8823 11.5025 19.9557 11.6234 20.0058C11.7444 20.0559 11.8741 20.0817 12.005 20.0817C12.1359 20.0817 12.2656 20.0559 12.3865 20.0058C12.5075 19.9557 12.6174 19.8823 12.71 19.7897C12.8026 19.6971 12.876 19.5872 12.9261 19.4662C12.9762 19.3453 13.002 19.2156 13.002 19.0847C13.002 18.9537 12.9762 18.8241 12.9261 18.7031C12.876 18.5822 12.8026 18.4723 12.71 18.3797L7.82999 13.4997H19C19.55 13.4997 20 13.0497 20 12.4997C20 11.9497 19.55 11.4997 19 11.4997Z"
                                fill="#202020"
                            />
                        </svg>
                        <p className="text-[#202020] font-[600]">Back</p>
                    </button>
                    <img src="../assets/logo3.png" alt="" className="w-[200px]" />
                </div>
                <div className="flex flex-col md:flexs-row justify-between items-start md:items-center">
                    <div className="bg-[#139EB5] w-full">
                        <div className="bg-white w-fit xl:mx-[120px] mx-3 px-2">
                            <p className="text-[#11485B]  md:text-[22px] text-center font-[700]">
                                Data Protection
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mt-4 xl:px-[120px] px-3">
                    {/* <p className="text-[14px] md:text-[20px] font-normal mt-2 rich-content">At Aqua Medical Laboratory, protecting your privacy is our top priority. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our services. By accessing or using our website and services, you agree to the terms outlined in this policy.</p> */}
                    <div className="">
                        <p className="text-[14px] md:text-[20px] text-[#83d284]  font-semibold py-3">
                            Public Statement
                        </p>
                        <p className="text-[14px] md:text-[20px] rich-content">
                            At Aqua Medical Laboratories,  We prioritize what matters most to our customers, especially the sensitive data involved in diagnostics, and are committed to ensuring the security of your personal information. Our dedication to upholding the highest standards of data protection, in line with regulations such as the GDPR, demonstrates our unwavering focus on earning and maintaining your trust. Safeguarding your information is central to our values, reflecting our belief in protecting your confidence in our brand. At Aqua Medical Laboratories, data protection is not just a priority it is a fundamental commitment we proudly uphold.
                        </p>

                        <p className="text-[14px] md:text-[20px] text-[#83d284]  font-semibold py-3">
                            Where does Aqua Medical Laboratories stand with GDPR?
                        </p>
                        <p className="text-[14px] md:text-[20px] rich-content">
                            Aqua Medical Laboratories is fully committed to GDPR compliance across all of its entities. Whether it's patient data, employee records, customer information, supplier details, or any other personal data within our business operations, we prioritize adhering to GDPR obligations and ethical principles.
                        </p>
                        <p className="text-[14px] md:text-[20px] rich-content mt-3">
                            Our dedicated team of data protection specialists, led by the Our Data Protection Officer, ensures that best practices are applied consistently throughout Aqua Labs, with continuous improvements. You can trust that your personal data is managed with the highest care and strict adherence to GDPR standards.
                        </p>

                    </div>

                    <div className="">
                        <p className="text-[14px] md:text-[20px] text-[#83d284]  font-semibold py-3">
                            Data processing:
                        </p>
                        {/* <p className="text-[14px] md:text-[20px] rich-content">
                            We use the collected information for:
                        </p> */}
                        <p className="text-[14px] md:text-[20px] rich-content mt-3">
                            In accordance with GDPR, Aqua Medical Laboratories is required to collect and process personal data only for specific and legitimate purposes. We maintain a comprehensive record of all processing activities (ROPA), which outlines the purpose and legal basis for each operation. This record is regularly updated to reflect changing work practices. When we engage third parties to process personal data on our behalf, we ensure strict due diligence and establish contractual data processing agreements to protect the rights of data subjects.
                        </p>
                    </div>

                    <div className="">
                        <p className="text-[14px] md:text-[20px] text-[#83d284]  font-semibold py-3">
                            Transparency:
                        </p>

                        <p className="text-[14px] md:text-[20px] rich-content mt-3">
                            GDPR mandates that appropriate measures are in place to manage personal data throughout its lifecycle. Aqua Medical Laboratories has implemented controls to:
                            - Ensure that only the minimum necessary amount of personal data is collected;
                            - Allow personal data to be corrected and kept up-to-date; and
                            - Dispose of personal data when it is no longer needed or relevant.
                        </p>
                        <p className="text-[14px] md:text-[20px] rich-content mt-3">
                            Aqua Medical Laboratories is fully transparent regarding how personal data is processed by the Group. Personal data is mapped to allow for the collection, storage, usage, modification, or deletion in accordance with GDPR requirements and requests from data subjects.
                        </p>
                    </div>

                    <div className="">
                        <p className="text-[14px] md:text-[20px] text-[#83d284]  font-semibold py-3">
                            Data security:
                        </p>

                        <p className="text-[14px] md:text-[20px] rich-content mt-3">
                            Data security is of paramount importance at Aqua Medical Laboratories. We continuously assess and strengthen our security systems, organizational measures, and work practices to minimize vulnerabilities. By implementing stringent security controls, we ensure that personal data is always accessible for business use while safeguarding it against malicious threats.
                        </p>
                    </div>


                    <div className="">
                        <p className="text-[14px] md:text-[20px] text-[#83d284]  font-semibold py-3">
                            What's Next?
                        </p>
                        <p className="text-[14px] md:text-[20px] rich-content">
                            At Aqua Medical Laboratories, delivering an exceptional customer experience is a top priority. We are committed to making operational changes that comply with GDPR and country-specific health legislation. Our dedication extends to keeping all of our clients, patients, providers, and local regulatory authorities informed throughout these developments.
                        </p>

                        <p className="mt-3 text-[14px] md:text-[20px] rich-content">For more information about our compliance journey, please contact us at <span  className='text-[#83d284] '> info@aquamedlabs.com</span> </p>
                    </div>
                </div>
            </div>
      <div className="bg-[#139EB5] w-full h-[40px]"></div>

        </>
    )
}

export default DataProtection
