import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { getNames } from "country-list";
import DatePicker from "react-datepicker";
import PromotionselectDropdown from "../promotionselect";
import { useLocation } from "react-router-dom";

import { FaChevronUp, FaChevronDown } from 'react-icons/fa'; // Importing the icons


function PromotionView() {
  const handleGoBack = () => {
    window.history.back();
  };

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;
  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    gender: "",
    address1: "",
    address2: "",
    postalCode: "",
    country: "",
    dob: null,
    requiredTests: [],
  });

  const countries = getNames();
  const modifiedCountries = [...countries, "United Kingdom"];
  const [book, setBook] = useState(false);
  const [book1, setBook1] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const handlePhoneNumberChange = (value) => {
    setFormData({ ...formData, phoneNumber: value });
  };
  const BookNow = () => {
    setBook(!book);
    setBook1(!book1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${baseUrl}/booking/promotions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);
      // Reset form or handle success (e.g., show a success message)
      setFormData({
        title: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        gender: "",
        address1: "",
        address2: "",
        postalCode: "",
        country: "",
        dob: null,
        requiredTests: "",
      });
      BookNow(); // Close the form modal after submission
    } catch (error) {
      console.error("Error:", error);
      // Handle error (e.g., show an error message)
    }
  };
  const handleViewFormClick = () => {
    setBook(!book);
    setBook1(!book1);
  };

  const awarenessData = {
    imageUrl: "/assets/img-4.jpeg",
    title: "This October: Let’s Turn Aqua Pink for Breast Cancer Awareness!",
    subtitle: "Early detection and prevention can save lives:",
    description:
      "In support of Breast Cancer Awareness Month, Aqua Medical Laboratories is offering 15% off our Early Detection Factor profile of tests - available until 28th November.",
    offerTitle: "Our Offer",
    offerDetails: [
      { text: "15% off on Early Detection Factor profile", isBold: true },
      { text: "Includes comprehensive screening of genetic markers" },
      { text: "Insightful recommendations for lifestyle changes" },
      { text: "Offer valid until 28th November", isBold: true },
    ],
    closingText: "Take control of your health.",
    closingDescription:
      "Understanding your genetic risk and making simple adjustments today could change your future. Together, we’ll turn Aqua pink this month, raising awareness and taking action in the fight against breast cancer.",
    ctaText: "Book your test today - early detection is key.",
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const promotionId = queryParams.get("promotionId");

  console.log("Promotion ID:", promotionId);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchPromotionData = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/promotion/getPromotionsById?promotionId=${promotionId}`
        );
        if (response.status === 200) {
          const data = await response.json();
          console.log("Fetched promotionById data:", data);
          setData(data.result);
        } else {
          throw new Error("Unable to fetch data");
        }
      } catch (err) {
        console.error("Error fetching promotion data:", err);
      }
    };

    fetchPromotionData();
  }, [promotionId]);




  return (
    <div className=" lg:pt-[140px] pt-20 relative">
      <div className="flex justify-between xl:px-[120px] px-3 my-4">
        <button
          onClick={handleGoBack}
          className="flex gap-4 items-center cursor-pointer w-[100px]"
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 11.4997H7.82999L12.71 6.61967C13.1 6.22967 13.1 5.58967 12.71 5.19967C12.6175 5.10697 12.5076 5.03342 12.3866 4.98324C12.2656 4.93306 12.136 4.90723 12.005 4.90723C11.874 4.90723 11.7443 4.93306 11.6234 4.98324C11.5024 5.03342 11.3925 5.10697 11.3 5.19967L4.70999 11.7897C4.61728 11.8822 4.54373 11.9921 4.49355 12.113C4.44337 12.234 4.41754 12.3637 4.41754 12.4947C4.41754 12.6256 4.44337 12.7553 4.49355 12.8763C4.54373 12.9973 4.61728 13.1072 4.70999 13.1997L11.3 19.7897C11.3926 19.8823 11.5025 19.9557 11.6234 20.0058C11.7444 20.0559 11.8741 20.0817 12.005 20.0817C12.1359 20.0817 12.2656 20.0559 12.3865 20.0058C12.5075 19.9557 12.6174 19.8823 12.71 19.7897C12.8026 19.6971 12.876 19.5872 12.9261 19.4662C12.9762 19.3453 13.002 19.2156 13.002 19.0847C13.002 18.9537 12.9762 18.8241 12.9261 18.7031C12.876 18.5822 12.8026 18.4723 12.71 18.3797L7.82999 13.4997H19C19.55 13.4997 20 13.0497 20 12.4997C20 11.9497 19.55 11.4997 19 11.4997Z"
              fill="#202020"
            />
          </svg>
          <p className="text-[#202020] font-[600]">Back</p>
        </button>
        <img src="../assets/logo3.png" alt="" className="w-[200px]" />
      </div>

      <div className="mt-5">
        <div className="flex flex-col md:flexs-row justify-between items-start md:items-center">
          <div className="bg-[#139EB5] w-full">
            <div className="bg-white w-fit xl:mx-[120px] mx-3 px-2">
              <p className="text-[#11485B]  md:text-[22px] text-center font-[700]">
                {data.thumpTitle}
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-end mr-5 md:mr-10">
          <div
            onClick={BookNow}
            className=" mt-2 ml-3 flex items-center justify-center h-[30px] md:h-[50px] px-3 py-2 bg-[#11485B] rounded-full cursor-pointer w-[120px] md:w-[200px]"
          >
            <p className="font-[600] text-[#A3D284] text-[10px] md:text-[16px]">
              Promotion Request
            </p>
          </div>
        </div>
        {book && (
          <div className="absolute top-0 p-4 z-50 scroll left-0 w-full h-screen bg-black bg-opacity-[40%] grid place-content-center figtree px-3 md:px-0">
            <div className="text-[#ffffff] border-[1px] border-white relative lg:stop-10 bg-[#11485B] h-auto z-50 xl:w-[900px] xl:h-[660px] lg:w-[700px] lg:h-[620px] md:h-[700px] min-w-[300px] mt-[190px] md:rounded-[30px] rounded-[20px] lg:px-10 px-5 py-2">
              <div
                className="absolute md:right-6 md:top-5 right-4 cursor-pointer"
                onClick={handleViewFormClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              <form onSubmit={handleSubmit}>
                <p className="text-[14px] md:text-[18px] font-[600] md:py-1">
                  Enter the Patient Details
                </p>
                <div className="md:flex gap-3 md:mt-0">
                  <div className="inputbox text-black mt-4">
                    <select
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                      className="w-full h-12 px-4 border-2 rounded-md custom-select"
                      required
                    >
                      <option value="">Title</option>
                      <option value="Mr.">Mr.</option>
                      <option value="Mrs.">Mrs.</option>
                      <option value="Miss.">Miss.</option>
                      <option value="Dr.">Dr.</option>
                    </select>
                  </div>
                  <div className="inputbox mt-4">
                    <input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      required
                    />
                    <span>First Name</span>
                    <i></i>
                  </div>
                </div>
                <div className="md:flex gap-3 md:mt-0">
                  <div className="inputbox mt-4">
                    <input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      required
                    />
                    <span>Last Name</span>
                    <i></i>
                  </div>
                  <div className="inputbox custom-phone-input mt-4">
                    <PhoneInput
                      defaultCountry="GB"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handlePhoneNumberChange}
                      placeholder="Phone Number*"
                      international
                      withCountryCallingCode
                    />
                  </div>
                </div>
                <div className="md:flex gap-3 md:mt-0">
                  <div className="inputbox mt-4">
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                    <span>Email</span>
                    <i></i>
                  </div>
                  <div className="inputbox mt-4">
                    <select
                      name="gender"
                      value={formData.gender}
                      onChange={handleInputChange}
                      className="custom-select"
                      required
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="inputbox mt-4">
                  <input
                    type="text"
                    name="address1"
                    value={formData.address1}
                    onChange={handleInputChange}
                    required
                  />
                  <span>Address Line1</span>
                  <i></i>
                </div>
                <div className="inputbox mt-8">
                  <input
                    type="text"
                    name="address2"
                    value={formData.address2}
                    onChange={handleInputChange}
                    required
                  />
                  <span>Address Line2</span>
                  <i></i>
                </div>
                <div className="md:flex gap-3 md:mt-0">
                  <div className="inputbox mt-4">
                    <input
                      type="text"
                      name="postalCode"
                      value={formData.postalCode}
                      onChange={handleInputChange}
                      required
                    />
                    <span>Postal Code</span>
                    <i></i>
                  </div>
                  <div className="inputbox mt-4">
                    <select
                      name="country"
                      value={formData.country}
                      onChange={handleInputChange}
                      className="custom-select"
                      required
                    >
                      <option value="">Select Country</option>
                      {modifiedCountries.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="md:flex gap-3 md:mt-0">
                  <div className="inputboxDate">
                    <div className="mt-4 inputboxDate border-[1px] border-[#4ab0b0] rounded-[6px] z-20">
                      <DatePicker
                        selected={formData.dob}
                        onChange={(date) =>
                          setFormData({ ...formData, dob: date })
                        }
                        placeholderText="Date of Birth"
                        className="w-full h-[40px] outline-none text-[#234971] block indent-5 rounded-[5px]"
                      />
                    </div>
                  </div>
                  <div className="inputbox mt-4">
                    <div className="select-wrapper">
                      <PromotionselectDropdown
                        formData={formData}
                        setFormData={setFormData}
                      />
                      <ul className="mt-2">
                        {Array.isArray(formData.requiredTests) &&
                          formData.requiredTests.length > 0 &&
                          formData.requiredTests.map((test) => (
                            <li key={test.value}>{test.label}</li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="text-[#11485B] bg-[#ffffff] lg:w-[300px] p-2 w-auto h-[40px] rounded-[20px] flex justify-center items-center cursor-pointer"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        <div className="mt-4 xl:px-[120px] px-3">
          <img src={data.imageUrl} alt="" className="w-full" />

          <p className="text-[14px] md:text-[20px] text-[#83d284] py-2 font-semibold">
            {data.title}
          </p>

          {data.subtitles &&
            data.descriptions &&
            data.subtitles.map((subtitle, index) => (
              <div key={index}>
                <p className="text-[14px] md:text-[20px] font-bold mt-3 text-[#A3D284]">
                  {subtitle}
                </p>
                <p className="font-normal text-[14px] md:text-[20px] rich-content">
                  {data.descriptions[index]}
                </p>
              </div>
            ))}

          <p className="text-[14px] md:text-[20px] font-bold mt-3 text-[#A3D284]">
            {data.offerTitle}
          </p>

          <ul className="text-[14px] md:text-[20px] rich-content">
            {data.offerDetails?.map((offer, index) => (
              <li key={index}>
                <span className={offer.isBold ? "font-bold" : ""}>
                  {offer.text}
                </span>
              </li>
            ))}
          </ul>



          <ul className="text-[14px] md:text-[20px] mt-3 rich-content">
            {data.data1?.map((item, index) => (
              <div key={index}>
                {/* Title with the icon */}
                <div
                  className="flex items-center justify-between py-1 ml-[-20px] cursor-pointer"
                  onClick={() => handleToggle(index)} // Toggle on click
                >
                  {/* Title Text */}
                  <p className="font-semibold text-[#A3D284] flex-grow">
                    {item.name}
                  </p>

                  {/* Chevron Icon */}
                  {openIndex === index ? (
                    <FaChevronUp className=" text-lg" />
                  ) : (
                    <FaChevronDown className=" text-lg" />
                  )}
                </div>



                {/* Dropdown content that opens/closes based on the selected item */}
                {openIndex === index && (
                  <div className="mb-4 p-4 border border-gray-300 rounded-lg shadow-md">
                    <p className="py-1"><strong>Time:</strong> {item.time}</p>
                    <p className="py-1"><strong>Category:</strong> {item.category}</p>
                    <p className="py-1"><strong>Description:</strong> {item.description}</p>
                    <p className="py-1"><strong>Methodology:</strong> {item.methodology}</p>
                    <p className="py-1"><strong>Cost:</strong> {item.cost}</p>
                  </div>
                )}
              </div>
            ))}
          </ul>


          export default DropdownList;

          <p className="text-[14px] md:text-[20px] font-bold mt-4 text-[#A3D284]">
            {data.closingText}
            <p className="font-normal rich-content">
              {data.closingDescription}
            </p>
          </p>

          <p className="text-[14px] md:text-[20px] font-bold mt-3 rich-content">
            {data.ctaText}
          </p>
        </div>
      </div>

      <div className="bg-[#139EB5] w-full h-[40px]"></div>
    </div>
  );
}

export default PromotionView;

{/* <div class="overflow-x-auto mt-5">
  <table class="min-w-full border border-gray-300 rich-content">
    <tbody>
      <tr class="font-normal hover:bg-gray-100">
        <td class="border border-gray-300 px-2 py-2">1</td>
        <td class="border border-gray-300 px-2 py-2">21 Working days</td>
        <td class="border border-gray-300 px-2 py-2">
          BRCA1 & BRCA2 gene analysis
        </td>
        <td class="border border-gray-300 px-2 py-2">Oncology</td>
        <td class="border border-gray-300 px-2 py-2">
          Provide histopathological report of patient (in primary cases) and
          relevant family history in case of first line relative screening
        </td>
        <td class="border border-gray-300 px-2 py-2">
          Next Generation Sequencing
        </td>
        <td class="border border-gray-300 px-2 py-2">Pounds 270</td>
      </tr>
      <tr class="hover:bg-gray-100">
        <td class="border border-gray-300 px-2 py-2">2</td>
        <td class="border border-gray-300 px-2 py-2">21 working days</td>
        <td class="border border-gray-300 px-2 py-2">
          Comprehensive tumor panel
        </td>
        <td class="border border-gray-300 px-2 py-2">Oncology</td>
        <td class="border border-gray-300 px-2 py-2">
          Confirmatory clinical diagnosis of malignancy is a mandatory
          requirement. Additionally, we require histopathology report for solid
        </td>
        <td class="border border-gray-300 px-2 py-2">
          Next generation sequencing
        </td>
        <td class="border border-gray-300 px-2 py-2">Pounds 490</td>
      </tr>
      <tr class="hover:bg-gray-100">
        <td class="border border-gray-300 px-2 py-2">3</td>
        <td class="border border-gray-300 px-2 py-2">2-3 weeks</td>
        <td class="border border-gray-300 px-2 py-2">
          Circulating tumor cells
        </td>
        <td class="border border-gray-300 px-2 py-2">Oncology</td>
        <td class="border border-gray-300 px-2 py-2">
          Liquid base (blood test)
        </td>
        <td class="border border-gray-300 px-2 py-2">Low cytometry</td>
        <td class="border border-gray-300 px-2 py-2">1150 pounds</td>
      </tr>
      <tr class="hover:bg-gray-100">
        <td class="border border-gray-300 px-2 py-2">4</td>
        <td class="border border-gray-300 px-2 py-2">5-10 days</td>
        <td class="border border-gray-300 px-2 py-2">
          Serum breast tumor screening
        </td>
        <td class="border border-gray-300 px-2 py-2">Oncology</td>
        <td class="border border-gray-300 px-2 py-2">
          Screening blood test (CA 125, CA15-3, CEA)
        </td>
        <td class="border border-gray-300 px-2 py-2">ELISA</td>
        <td class="border border-gray-300 px-2 py-2">130 pounds</td>
      </tr>
    </tbody>
  </table>
</div>; */}

{
  /* <div className="mt-4 xl:px-[120px] px-3">
<img src={awarenessData.imageUrl} alt="" className="w-full" />

<p className="text-[14px] md:text-[20px] text-[#83d284] py-2 font-semibold">
  {awarenessData.title}
</p>

<p className="text-[14px] md:text-[20px] font-bold mt-3 text-[#A3D284]">
  {awarenessData.subtitle}
  <p className="font-normal rich-content">
    {awarenessData.description}
  </p>
</p>

<p className="text-[14px] md:text-[20px] font-bold mt-3 text-[#A3D284]">
  Genetic markers
  <p className="font-normal rich-content">
    It may be as simple as making key lifestyle changes that could
    dramatically reduce your risk and save lives.
  </p>
</p>

<p className="text-[14px] md:text-[20px] font-bold mt-3 text-[#A3D284]">
  {awarenessData.offerTitle}
</p>

<ul className="text-[14px] md:text-[20px] rich-content">
  {awarenessData.offerDetails.map((offer, index) => (
    <li key={index}>
      <span className={offer.isBold ? "font-bold" : ""}>
        {offer.text}
      </span>
    </li>
  ))}
</ul>

<p className="text-[14px] md:text-[20px] font-bold mt-4 text-[#A3D284]">
  {awarenessData.closingText}
  <p className="font-normal rich-content">
    {awarenessData.closingDescription}
  </p>
</p>

<p className="text-[14px] md:text-[20px] font-bold mt-3 rich-content">
  {awarenessData.ctaText}
</p>
</div> */
}
