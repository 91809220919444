import React, { useEffect, useState } from "react";
import axios from "axios";

const Feedback = () => {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const [feedback, setFeedback] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    feedback: ""
  });
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const Open = () => {
    setFeedback(!feedback);
  };

  useEffect(() => {
    const fakeDataFetch = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    };
    fakeDataFetch();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/feedback/feedbackform`, formData);
      setShowPopup(true);
      setMessage("Feedback submitted successfully!");
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        feedback: ""
      });
    } catch (error) {
      console.error("Error submitting feedback:", error);
      setMessage("Error submitting feedback. Please try again.");
    }
  };

  return (
    <div>
      <div
        className="absolute md:bottom-[30px] bottom-2 right-3 md:right-5 bg-fixed z-20  feed"
      // onClick={Open}
      >
        <div className="bg-[#A3D284] md:p-3 p-2 rounded-full bg-fixed cursor-pointer flex">
          <p className="pr-2 md:text-[16px] text-[10px]">

            <a href="https://forms.office.com/e/H8g8c6dWj4" target="_blank" rel="noopener noreferrer" className="text-[#303030]">
              Write your feedback
            </a>
          </p>
          <svg className="hidden md:block" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_773_2195)">
              <path d="M6.75 1.5L6 2.25V4.5405C6.49851 4.48599 7.00149 4.48599 7.5 4.5405V3H21V10.5H18.4395L16.5 12.4395V10.5H13.4595C13.514 10.9985 13.514 11.5015 13.4595 12H15V14.25L16.281 14.781L19.0605 12H21.75L22.5 11.25V2.25L21.75 1.5H6.75Z" fill="#303030" />
              <path fillRule="evenodd" clipRule="evenodd" d="M9.6255 15.6435C10.5714 15.0243 11.2921 14.1163 11.6805 13.0545C12.0689 11.9928 12.1041 10.834 11.7811 9.75066C11.458 8.66727 10.7938 7.71711 9.88734 7.04151C8.98087 6.36592 7.88054 6.00098 6.75 6.00098C5.61946 6.00098 4.51913 6.36592 3.61266 7.04151C2.70619 7.71711 2.04202 8.66727 1.71894 9.75066C1.39586 10.834 1.43113 11.9928 1.81951 13.0545C2.20789 14.1163 2.92862 15.0243 3.8745 15.6435C2.71596 16.189 1.7365 17.0528 1.05045 18.1341C0.364399 19.2153 7.23459e-05 20.4694 0 21.75L0 22.5H1.5V21.75C1.54604 20.3883 2.11933 19.0978 3.09887 18.1508C4.0784 17.2038 5.38755 16.6745 6.75 16.6745C8.11245 16.6745 9.4216 17.2038 10.4011 18.1508C11.3807 19.0978 11.954 20.3883 12 21.75V22.5H13.5V21.75C13.4999 20.4694 13.1356 19.2153 12.4496 18.1341C11.7635 17.0528 10.784 16.189 9.6255 15.6435ZM6.75 15C5.75544 15 4.80161 14.6049 4.09835 13.9016C3.39509 13.1984 3 12.2445 3 11.25C3 10.2554 3.39509 9.30158 4.09835 8.59832C4.80161 7.89506 5.75544 7.49997 6.75 7.49997C7.74456 7.49997 8.69839 7.89506 9.40165 8.59832C10.1049 9.30158 10.5 10.2554 10.5 11.25C10.5 12.2445 10.1049 13.1984 9.40165 13.9016C8.69839 14.6049 7.74456 15 6.75 15Z" fill="#303030" />
            </g>
            <defs>
              <clipPath id="clip0_773_2195">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <svg className="md:hidden" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.05869 0.383333L4.53369 0.908333V2.51168C4.88265 2.47353 5.23473 2.47353 5.58369 2.51168V1.43333H15.0337V6.68333H13.2413L11.8837 8.04098V6.68333H9.75534C9.7935 7.03229 9.7935 7.38437 9.75534 7.73333H10.8337V9.30833L11.7304 9.68003L13.676 7.73333H15.5587L16.0837 7.20833V0.908333L15.5587 0.383333H5.05869Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.07135 10.2838C7.73346 9.85033 8.23797 9.21473 8.50984 8.47152C8.7817 7.72831 8.80639 6.91719 8.58024 6.15881C8.35408 5.40044 7.88916 4.73533 7.25464 4.26241C6.62011 3.7895 5.84987 3.53404 5.0585 3.53404C4.26712 3.53404 3.49688 3.7895 2.86236 4.26241C2.22783 4.73533 1.76291 5.40044 1.53676 6.15881C1.3106 6.91719 1.33529 7.72831 1.60715 8.47152C1.87902 9.21473 2.38353 9.85033 3.04565 10.2838C2.23467 10.6656 1.54904 11.2703 1.06881 12.0272C0.588575 12.7841 0.333547 13.662 0.333496 14.5583L0.333496 15.0833H1.3835V14.5583C1.41572 13.6052 1.81703 12.7018 2.5027 12.0389C3.18838 11.376 4.10478 11.0055 5.0585 11.0055C6.01221 11.0055 6.92861 11.376 7.61429 12.0389C8.29997 12.7018 8.70127 13.6052 8.7335 14.5583V15.0833H9.7835V14.5583C9.78345 13.662 9.52842 12.7841 9.04818 12.0272C8.56795 11.2703 7.88232 10.6656 7.07135 10.2838ZM5.0585 9.83333C4.3623 9.83333 3.69462 9.55677 3.20234 9.06449C2.71006 8.57221 2.4335 7.90453 2.4335 7.20833C2.4335 6.51214 2.71006 5.84446 3.20234 5.35218C3.69462 4.85989 4.3623 4.58333 5.0585 4.58333C5.75469 4.58333 6.42237 4.85989 6.91465 5.35218C7.40693 5.84446 7.6835 6.51214 7.6835 7.20833C7.6835 7.90453 7.40693 8.57221 6.91465 9.06449C6.42237 9.55677 5.75469 9.83333 5.0585 9.83333Z" fill="black" />
          </svg>
        </div>
      </div>

      {/* {feedback && (
        <div className="absolute top-0 left-0 w-full h-screen bg-black bg-fixed z-20 feed bg-opacity-[30%]">
          <div className="flex justify-center items-center h-full">
            <div className="md:w-[500px] w-[300px] md:h-[550px] h-[350px] bg-white rounded-[25px]">
              <div className="flex justify-between items-center h-[15%] md:px-10 px-5 border-b-[1px]  border-[#000000]">
                <p className="text-[#000000] md:text-[28px] text-[20px] font-[600]">Customer Feedback</p>
                <div className="" onClick={Open}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.25" y="0.25" width="23.5" height="23.5" stroke="black" strokeWidth="0.5" />
                    <path d="M16.875 7.125L7.125 16.875M7.125 7.125L16.875 16.875" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
              <form className="md:mx-10 mx-5 mt-2" onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="pl-5 bg-[#C4D6E0] w-full md:h-[60px] h-[35px] outline-none mt-2"
                  placeholder="Full Name"
                />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="pl-5 bg-[#C4D6E0] w-full md:h-[60px] h-[35px] outline-none mt-2"
                  placeholder="E-mail Address"
                />
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="pl-5 bg-[#C4D6E0] w-full md:h-[60px] h-[35px] outline-none mt-2"
                  placeholder="Phone Number"
                />
                <textarea
                  name="feedback"
                  value={formData.feedback}
                  onChange={handleChange}
                  className="md:h-[160px] h-[90px] w-full bg-[#C4D6E0] outline-none md:p-5 p-3 mt-2"
                  placeholder="Tell us your feedback..."
                ></textarea>
                <div className="flex justify-center mt-1 md:mt-3">
                  <button
                    type="submit"
                    className="bg-[#234971] md:h-[50px] h-[40px] md:w-[150px] w-[120px] rounded-[10px] text-white"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-30">
          <div className="bg-white p-6 rounded-md shadow-md text-center">
            <p>{message}</p>
            <button onClick={() => setShowPopup(false)} className="mt-4 bg-blue-500 text-white py-2 px-4 rounded">
              Close
            </button>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Feedback;
