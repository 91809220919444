import React from 'react';

const Loader = () => {

    return (
        <div className="block w-full h-full fixed top-0 left-0 z-50">
            <div className="inset-0 flex items-center justify-center bg-white ">
                <div className="container">
                    <div className='container '>
                        <img src="./assets/blog/logo.png" alt="" className="box-one " />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Loader;