import React, { useState } from 'react';
import { Link } from "react-router-dom";


const Card = ({ image, title, link, id }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative overflow-hidden cursor-pointer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-aos="zoom-in-up"  data-aos-duration="1000"

    >
      <img src={image} alt="" className="  md:w-[350px] md:h-[400px] lg:w-[300px] lg:h-[300px] xl:w-[350px] xl:h-[400px] w-[260px] h-[260px] object-cover rounded-xl transition-transform duration-500 ease-out" />
      <div
        className={`absolute left-0 right-0 mx-auto bg-[#f1f1f1] md:p-6 p-3 shadow-lg rounded-lg transition-all duration-500 ease-out ${isHovered ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
          }`}
        style={{
          bottom: isHovered ? '0' : '-9rem',
          backgroundImage: "url('/assets/card.png')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <span className="block  mb-1 text-[#ffff] text-[12px] md:text-[16px] xl:text-[18px]">{title}</span>
        <div className="flex items-center  justify-center">
          <Link to={`/blogpost?id=${id}`}>
            <button className="flex items-center  justify-center text-[10px] md:text-[16px] mt-2 bg-white text-[#234971] hover:bg-[#234971] hover:text-white transition-all duration-300 rounded-full font-[600] py-2 px-4">
              More info
              <img
                src="./assets/hero/more.png"
                alt="More info"
                className="bg-[#234971] h-[20px] md:h-[35px] rounded-full md:p-2 p-1 ml-2"
              />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card;
