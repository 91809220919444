import React from 'react'

function PrivacyPolicy() {
    const handleGoBack = () => {
        window.history.back();
    };
    return (
        <>
            <div className=" lg:pt-[140px] pt-20 relative">
                <div className="flex justify-between xl:px-[120px] px-3 my-4">
                    <button
                        onClick={handleGoBack}
                        className="flex gap-4 items-center cursor-pointer w-[100px]"
                    >
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M19 11.4997H7.82999L12.71 6.61967C13.1 6.22967 13.1 5.58967 12.71 5.19967C12.6175 5.10697 12.5076 5.03342 12.3866 4.98324C12.2656 4.93306 12.136 4.90723 12.005 4.90723C11.874 4.90723 11.7443 4.93306 11.6234 4.98324C11.5024 5.03342 11.3925 5.10697 11.3 5.19967L4.70999 11.7897C4.61728 11.8822 4.54373 11.9921 4.49355 12.113C4.44337 12.234 4.41754 12.3637 4.41754 12.4947C4.41754 12.6256 4.44337 12.7553 4.49355 12.8763C4.54373 12.9973 4.61728 13.1072 4.70999 13.1997L11.3 19.7897C11.3926 19.8823 11.5025 19.9557 11.6234 20.0058C11.7444 20.0559 11.8741 20.0817 12.005 20.0817C12.1359 20.0817 12.2656 20.0559 12.3865 20.0058C12.5075 19.9557 12.6174 19.8823 12.71 19.7897C12.8026 19.6971 12.876 19.5872 12.9261 19.4662C12.9762 19.3453 13.002 19.2156 13.002 19.0847C13.002 18.9537 12.9762 18.8241 12.9261 18.7031C12.876 18.5822 12.8026 18.4723 12.71 18.3797L7.82999 13.4997H19C19.55 13.4997 20 13.0497 20 12.4997C20 11.9497 19.55 11.4997 19 11.4997Z"
                                fill="#202020"
                            />
                        </svg>
                        <p className="text-[#202020] font-[600]">Back</p>
                    </button>
                    <img src="../assets/logo3.png" alt="" className="w-[200px]" />
                </div>
                <div className="flex flex-col md:flexs-row justify-between items-start md:items-center">
                    <div className="bg-[#139EB5] w-full">
                        <div className="bg-white w-fit xl:mx-[120px] mx-3 px-2">
                            <p className="text-[#11485B]  md:text-[22px] text-center font-[700]">
                                Privacy Policy
                            </p>
                        </div>
                    </div>
                </div>
                <div className="mt-4 xl:px-[120px] px-3">
                    <p className="text-[14px] md:text-[20px] font-normal mt-2 rich-content">At Aqua Medical Laboratory, protecting your privacy is our top priority. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our services. By accessing or using our website and services, you agree to the terms outlined in this policy.</p>
                    <div className="">
                        <p className="text-[14px] md:text-[20px] text-[#83d284]  font-semibold py-3">
                            1. Information We Collect
                        </p>
                        <p className="text-[14px] md:text-[20px] rich-content">
                            We may collect the following information from you:
                        </p>
                        <ul className='list-disc text-[14px] md:text-[20px] rich-content'>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>Personal Information:</span> Name, contact details (email, phone number, address), date of birth, and any other personal data required for lab services.
                            </li>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>Medical Information:</span> Test results, medical history, and any other health-related data necessary to provide you with diagnostic services.
                            </li>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>Usage Data: </span>Information about how you interact with our website, including IP address, browser type, and pages visited.
                            </li>
                        </ul>
                    </div>

                    <div className="">
                        <p className="text-[14px] md:text-[20px] text-[#83d284]  font-semibold py-3">
                            2. How We Use Your Information

                        </p>
                        <p className="text-[14px] md:text-[20px] rich-content">
                            We use the collected information for:
                        </p>
                        <ul className='list-disc text-[14px] md:text-[20px] rich-content'>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>Providing Services:</span> To process and manage your lab requests, schedule appointments, and deliver accurate test results.
                            </li>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'> Communication:</span>  To enhance your experience by analyzing website usage patterns and improving service delivery.
                            </li>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>Improving Services: </span>Information about how you interact with our website, including IP address, browser type, and pages visited.
                            </li>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>Legal Compliance: </span>To comply with any applicable laws or legal obligations, including healthcare regulations.
                            </li>

                        </ul>
                    </div>

                    <div className="">
                        <p className="text-[14px] md:text-[20px] text-[#83d284]  font-semibold py-3">
                            3. How We Protect Your Information
                        </p>
                        <p className="text-[14px] md:text-[20px] rich-content">
                            We implement a variety of security measures to ensure the safety of your personal and medical information:
                        </p>



                        <ul className='list-disc text-[14px] md:text-[20px] rich-content'>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>Data Encryption:</span> All sensitive information is encrypted both in transit and at rest.
                            </li>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>Restricted Access:</span> Only authorized personnel have access to your information to perform necessary services.
                            </li>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>  Regular Audits: </span> We conduct routine security audits to prevent unauthorized access or data breaches.
                            </li>
                        </ul>
                    </div>

                    <div className="">
                        <p className="text-[14px] md:text-[20px] text-[#83d284]  font-semibold py-3">
                            4. Your Rights
                        </p>
                        <ul className='list-disc text-[14px] md:text-[20px] rich-content'>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>Access and Correction: </span> You may request access to your personal data and have it corrected if inaccurate.
                            </li>
                            <li className='mt-3'>
                                <span className='text-[#A3D284]'>Deletion:</span>You may request that we delete your personal information, except when required by law to retain it
                            </li>
                        </ul>
                    </div>
                    <div className="">
                        <p className="text-[14px] md:text-[20px] text-[#83d284]  font-semibold py-3">
                            5. Contact Us
                        </p>
                        <ul className='list-disc text-[14px] md:text-[20px] rich-content'>
                            <p className="text-[14px] md:text-[20px] rich-content">
                                If you have any questions or concerns about this Privacy Policy, or if you wish to exercise any of your rights, please contact us at:
                            </p>
                            <p className="text-[#83d284] mt-4"><span className='text-[#234971]'>Email:</span> info@aquamedlabs.com</p>

                            <p className="text-[#83d284] mt-2"><span className='text-[#234971]'>Phone:</span>  +44 2038797131</p>
                        </ul>
                    </div>
                </div>
                <div className="bg-[#139EB5] w-full h-[40px]"></div>
            </div>
        </>
    )
}

export default PrivacyPolicy
