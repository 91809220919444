import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { getNames } from "country-list";



function PackageView() {
  const [selectedDOB, setSelectedDOB] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const dropdownRef = useRef(null);
  const [data, setData] = useState({
    phoneNumber: "",
    gender: "",
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    address1: '',
  });
  const countries = getNames();
  const modifiedCountries = [...countries, 'United Kingdom'];
  


  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;
  const [token, setToken] = useState(null);
  const [packageView, setPackageView] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subId = queryParams.get("subId");
  const [cartItems, setCartItems] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [popup, setPopup] = useState(false);
  const [popup1, setPopup1] = useState(false);
  const [book, setBook] = useState(false);

  useEffect(() => {
    if (book || popup) {
      document.body.classList.add("fixed-background");
    } else {
      document.body.classList.remove("fixed-background");
    }
    return () => document.body.classList.remove("fixed-background");
  }, [book, popup]);

  useEffect(() => {
    let timer;
    if (popup) {
      timer = setTimeout(() => {
        setPopup(false);
      }, 4000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popup]);

  const BookNow = () => {
    setBook(!book);
    if (popup) {
      setPopup(false);
    }
  };

  const handleGoBack = () => {
    window.history.back();
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchServices = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${baseUrl}/home/viewcategory?subId=${subId}`
        );
        if (!response.ok) {
          throw new Error("Unable to fetch data");
        }
        const data = await response.json();
        setPackageView(data);
        console.log("Package data:", data);
      } catch (error) {
        console.error("Error fetching services:", error);
        setPackageView([]);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, [subId]);

  const addToCart = async (subId, serviceId) => {
    if (!token) {
      const currentPath = location.pathname + location.search;
      localStorage.setItem("redirectPath", currentPath);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
      return;
    }

    const jwt = jwtDecode(token);
    const email = jwt.email;

    try {
      const payload = {
        email: email,
        serviceId: Number(serviceId),
        subId: Number(subId),
      };

      const response = await axios.post(`${baseUrl}/cart/addcart`, payload, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });

      if (response.data.isSuccess) {
        setSuccessMessage(response.data.message);
        setErrorMessage("");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setErrorMessage(response.data.message);
        setSuccessMessage("");
      }
    } catch (error) {
      console.error(
        "Error adding item to cart:",
        error.response ? error.response.data.message : error.message
      );

      if (error.response) {
        if (error.response.status === 400) {
          setErrorMessage(error.response.data.message);
          setSuccessMessage("");
        } else {
          setErrorMessage("An unexpected error occurred. Please try again.");
          setSuccessMessage("");
        }
      } else {
        setErrorMessage("An unexpected error occurred. Please try again.");
        setSuccessMessage("");
      }
    }
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  // const options = [
  //   { value: "homeService", label: "Home Service" },
  //   { value: "labService", label: "Lab Service" },
  // ];



  const handleDateChange = (date) => {
    if (date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      const hours = date.getHours() % 12 || 12;
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const amPm = date.getHours() >= 12 ? 'PM' : 'AM';

      const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes} ${amPm}`;

      setSelectedTime(formattedDateTime);

      console.log("Formatted DateTime:", formattedDateTime);
    } else {
      console.error("Invalid date selected:", date);
    }
  };


  const handleDateChange1 = (date) => {
    if (date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
      const year = date.getFullYear();

      // Format the date to DD-MM-YYYY
      const formattedDate = `${day}-${month}-${year}`;

      // Set the formatted date string in the state
      setSelectedDOB(formattedDate);

      console.log("Formatted DOB:", formattedDate);
    } else {
      console.error("Invalid date selected:", date);
    }
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("aqua");
    setToken(storedToken);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("aqua");
    if (!token) {
      console.error("No token found");
      return;
    }

    const jwt = jwtDecode(token);
    console.log(jwt);

    fetch(`${baseUrl}/userInfo/getUserDetails`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch user details");
        }
      })
      .then((data) => {
        if (data && data.result) {
          // setUserInfo1(data.result)
          setData({
            title: data.result.Title || '',
            firstName: data.result.FirstName || '',
            lastName: data.result.LastName || '',
            phoneNumber: data.result.PhoneNumber || '',
            email: data.result.Email || '',
            address1: data.result.ClinicAddress || '',
            clinicName: data.result.ClinicName || '',
          });
        } else {
          console.error("API returned undefined result:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });

    const fakeDatafetch = () => {
      setTimeout(() => {
        // setIsLoading(false);
      }, 1000);
    };
    fakeDatafetch();
  }, [baseUrl]);



  const handleBooking = async (event) => {
    event.preventDefault();

    const service = packageView[0].serviceDetails[0];
    const service1 = packageView[0];
    const addressLine2 = event.target.elements["address2"]?.value;
    const postalCode = event.target.elements["postalcode"]?.value;
    console.log("Address Line 2:", addressLine2);

    console.log("Postal Code:", postalCode);

    const bookingData = {

      patientfirstname: data.firstName || '',
      patienttitle: data.title || '',
      patientlastname: data.lastName || '',
      patientemail: data.email || '',
    
      gender: data.gender,
      patientNumber: data.phoneNumber,
      Patientdob: selectedDOB,
      // bookingDate: selectedTime,
      // address: event.target.elements["address"]?.value || '',
      Country: event.target.elements["country"]?.value || '',
      subId: service.subId,
      TestName: service.serviceName,
      Fasting: service.fasting,
      type: service.type,
      testpack: service1.title,
      addressLine1: data.address1,
      addressLine2: addressLine2,
      postalCode: postalCode,
    };

    try {
      const headers = {
        "Content-Type": "application/json",
      };

      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      const response = await axios.post(
        `${baseUrl}/booking/bookservice`,
        bookingData,
        { headers }
      );

      if (response.data.isSuccess) {
        setPopup(true);
        setBook(false)
        event.target.reset();
        setSelectedOption("");
      } else {
        setPopup1(true);
      }
    } catch (error) {
      console.error(
        "Error creating booking:",
        error.response ? error.response.data.message : error.message
      );
      setPopup1(true);
    } finally {
      setTimeout(() => {
        setPopup(false);
        setPopup1(false);
      }, 4000);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handlePhoneNumberChange = (phoneNumber) => {
    setData({ ...data, phoneNumber });
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <div className=" lg:pt-[140px] pt-20 relative">
      <div className="flex justify-between xl:px-[120px] px-3 my-4">
        <button
          onClick={handleGoBack}
          className="flex gap-4 items-center cursor-pointer w-[100px]"
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 11.4997H7.82999L12.71 6.61967C13.1 6.22967 13.1 5.58967 12.71 5.19967C12.6175 5.10697 12.5076 5.03342 12.3866 4.98324C12.2656 4.93306 12.136 4.90723 12.005 4.90723C11.874 4.90723 11.7443 4.93306 11.6234 4.98324C11.5024 5.03342 11.3925 5.10697 11.3 5.19967L4.70999 11.7897C4.61728 11.8822 4.54373 11.9921 4.49355 12.113C4.44337 12.234 4.41754 12.3637 4.41754 12.4947C4.41754 12.6256 4.44337 12.7553 4.49355 12.8763C4.54373 12.9973 4.61728 13.1072 4.70999 13.1997L11.3 19.7897C11.3926 19.8823 11.5025 19.9557 11.6234 20.0058C11.7444 20.0559 11.8741 20.0817 12.005 20.0817C12.1359 20.0817 12.2656 20.0559 12.3865 20.0058C12.5075 19.9557 12.6174 19.8823 12.71 19.7897C12.8026 19.6971 12.876 19.5872 12.9261 19.4662C12.9762 19.3453 13.002 19.2156 13.002 19.0847C13.002 18.9537 12.9762 18.8241 12.9261 18.7031C12.876 18.5822 12.8026 18.4723 12.71 18.3797L7.82999 13.4997H19C19.55 13.4997 20 13.0497 20 12.4997C20 11.9497 19.55 11.4997 19 11.4997Z"
              fill="#202020"
            />
          </svg>
          <p className="text-[#202020] font-[600]">Back</p>
        </button>
        <img src="../assets/logo3.png" alt="" className="w-[200px]" />
      </div>

      {packageView.map((item, index) => (
        <div key={index} className=" ">
          {/* <img
            src="../assets/logo1.png"
            alt=""
            className="absolute inset-0 object-cover opacity-70 mx-auto my-auto"
          /> */}
          {item.serviceDetails.map((details) => (
            <div key={details.serviceId} className="mt-5">
              <div className="flex flex-col md:flexs-row justify-between items-start md:items-center">
                <div className="bg-[#139EB5] w-full">
                  <div className="bg-white w-fit xl:mx-[120px] mx-3 px-2">
                    <p className="text-[#11485B]  md:text-[22px] text-center font-[700]">
                      {details.serviceName}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-end mt-4 xl:px-[120px] px-3">
                <div className=""></div>
                <div className="flex gap-4 mt-3 md:mt-0 ">
                  {token && (
                    <div className="flex gap-2 items-center justify-center h-[30px] md:h-[50px] w-[120px] md:w-[150px] px-3 py-2 bg-[#11485B] rounded-full cursor-pointer">
                      <button
                        onClick={() => addToCart(subId, item.serviceId)}
                        className="font-[600] text-[#A3D284] text-[10px] md:text-[16px]"
                      >
                        {cartItems.includes(details.serviceId)
                          ? "Added!"
                          : "Add To Cart"}
                      </button>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2857 17.8282C10.2857 18.5825 9.66853 19.1996 8.91424 19.1996C8.15996 19.1996 7.54967 18.5825 7.54967 17.8282C7.54967 17.0739 8.15996 16.4568 8.91424 16.4568C9.66853 16.4568 10.2857 17.0739 10.2857 17.8282ZM15.7714 16.4568C15.0171 16.4568 14.4068 17.0739 14.4068 17.8282C14.4068 18.5825 15.0171 19.1996 15.7714 19.1996C16.5257 19.1996 17.1428 18.5825 17.1428 17.8282C17.1428 17.0739 16.5257 16.4568 15.7714 16.4568ZM16.0429 13.0282C16.3549 13.0282 16.6575 12.9217 16.9009 12.7265C17.1442 12.5313 17.3137 12.2589 17.3814 11.9544L18.5142 7.54249H8.91424V6.85678C8.91424 6.49305 8.76975 6.14423 8.51256 5.88703C8.25537 5.62984 7.90654 5.48535 7.54282 5.48535H6.17139V6.85678H7.54282V14.3996C7.54282 14.7634 7.6873 15.1122 7.9445 15.3694C8.20169 15.6266 8.55052 15.7711 8.91424 15.7711H17.1428C17.1428 15.4073 16.9983 15.0585 16.7411 14.8013C16.4839 14.5441 16.1351 14.3996 15.7714 14.3996H8.91424V13.0282H16.0429Z"
                          fill="#A3D284"
                        />
                        <circle
                          cx="12"
                          cy="12"
                          r="11"
                          stroke="#A3D284"
                          stroke-width="2"
                        />
                      </svg>
                    </div>
                  )}
                  <div
                    onClick={BookNow}
                    className="flex items-center justify-center h-[30px] md:h-[50px] w-[100px] md:w-[150px] px-3 py-2 bg-[#11485B] rounded-full cursor-pointer"
                  >
                    <p className="font-[600] text-[#A3D284] text-[10px] md:text-[16px]">
                      Request Quote
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-end mt-4 ">
                {successMessage && (
                  <div className="bg-green-500 p-2 text-[12px] md:text-[14px] w-[200px] md:w-[250px] text-center rounded-lg">
                    {successMessage}
                  </div>
                )}
                {errorMessage && (
                  <div className="bg-red-500 p-2 text-[12px] md:text-[14px] w-[200px] md:w-[250px] text-center rounded-lg">
                    {errorMessage}
                  </div>
                )}
              </div>
              {details.subservice.map((subdetails, subIndex) => (
                <div key={subIndex} className="mt-4 xl:px-[120px] px-3">
                  <p className="text-[14px] md:text-[20px] text-[#83d284]  font-semibold">
                    {subdetails.subheading}
                  </p>
                  <p
                    className="text-[14px] md:text-[20px] font-normal mt-2 rich-content"
                    dangerouslySetInnerHTML={{ __html: subdetails.content }}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}

      {book && (
        <div className="absolute top-0  p-4  z-50  scroll left-0 w-full h-screen bg-black bg-opacity-[50%] grid place-content-center figtree px-3 md:px-0">
          <div className="text-[#ffffff] border-[1px] border-white relative  lg:stop-10    bg-[#11485B] h-auto z-50  xl:w-[900px] xl:h-[630px] lg:w-[700px] lg:h-[620px] md:h-[700px] min-w-[300px] mt-[190px] md:rounded-[30px] rounded-[20px] lg:px-10 px-5 py-2">
            <form onSubmit={handleBooking}>
              <div
                className="absolute md:right-6 md:top-5 right-4  cursor-pointer"
                onClick={BookNow}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>

              <p className=" text-[14px]  md:text-[18px] font-[600] md:py-1">
                Enter the Patient Details
              </p>
              <p className=" text-[14px] md:text-[18px]  font-[300] my-2 text-justify">
                Our administrative team will contact you using the details
                provided. Please ensure all information entered is accurate.
              </p>
              <div className="md:flex gap-3 md:mt-0">
                <div className="inputbox text-black">
                  <select
                    name="title"
                    id="title"
                    value={data.title} 

                    onChange={(e) => setData({ ...data, title: e.target.value })}

                    className="w-full h-12 px-4 border-2 rounded-md custom-select"
                    required
                  >
                    <option value="">Title</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Mrs.">Mrs.</option>
                    <option value="Miss.">Miss.</option>
                    <option value="Dr.">Dr.</option>
                  </select>
                </div>
                <div className="inputbox">
                  <input type="text" name="FirstName" value={data.firstName}
                    onChange={(e) => setData({ ...data, firstName: e.target.value })} required />
                  <span>First Name</span>
                  <i></i>
                </div>
              </div>

              <div className="md:flex gap-3 md:mt-0">
                <div className="inputbox">
                  <input type="text" name="LastName" value={data.lastName}
                    onChange={(e) => setData({ ...data, lastName: e.target.value })} required />
                  <span>Last Name</span>
                  <i></i>
                </div>

                <div className="inputbox custom-phone-input">
                  <PhoneInput
                    defaultCountry="GB"
                    name="phoneNumber"
                    value={data.phoneNumber}
                    onChange={handlePhoneNumberChange}
                    placeholder="Phone Number*"
                    international
                    withCountryCallingCode
                  // containerClass="custom-phone-input"
                  />
                </div>
              </div>

              <div className="md:flex gap-3 md:mt-0">
                <div className="inputbox">
                  <input type="email" name="email" value={data.email}
                    onChange={(e) => setData({ ...data, email: e.target.value })} required />
                  <span> Email</span>
                  <i></i>
                </div>


                <div className="inputbox">
                  <div className="select-wrapper">
                    <select
                      name="gender"
                      value={data.gender}
                      onChange={handleChange}
                      className="custom-select"
                      required
                    >
                      <option value="" className="">
                        Select Gender
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                    <i className="highlight"></i>
                  </div>
                </div>
              </div>
              <div className="inputbox">
                <input type="text" name="address1" value={data.address1}
                  onChange={(e) => setData({ ...data, address1: e.target.value })} required />
                <span>Address Line1</span>
                <i></i>
              </div>
              <div className="inputbox">
                <input type="text" name="address2" required />
                <span>Address Line2</span>
                <i></i>
              </div>
              <div className="md:flex gap-3 md:mt-0">
                <div className="inputbox">
                  <input type="text" name="postalcode" required />
                  <span>Postal Code</span>
                  <i></i>
                </div>

                <div className="inputbox z-10">
                  <div className="select-wrapper">
                    <select
                      name="country"
                      id="country"
                      className="w-full custom-select h-12 px-4 rounded-md"
                      required
                    >
                      <option value="">Select Country</option>
                      {modifiedCountries.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="md:flex gap-3 md:mt-0 ">
                {/* <div className="inputboxDate border-[1px] border-[#4ab0b0] rounded-[6px] z-50">
                      <div className="!w-full">
                        <DatePicker
                          name="bookingDate"
                          selected={selectedTime ? new Date(selectedTime.split('-').reverse().join('-')) : null}
                          onChange={handleDateChange}
                          showTimeSelect
                          showTimeSelectOnly={false}
                          timeIntervals={15}
                          timeFormat="p"
                          dateFormat="MMMM d, yyyy h:mm a"
                          placeholderText="Select Date and Time"
                          className="!w-[280px] bg-transparent outline-none xl:text-[18px] text-[14px]"
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={50}
                        />
                      </div>
                    </div> */}
                <div className="inputboxDate border-[1px] border-[#4ab0b0] rounded-[6px] z-20">
                  <DatePicker
                    name="dob"
                    selected={selectedDOB ? new Date(selectedDOB.split('-').reverse().join('-')) : null}
                    onChange={handleDateChange1}
                    dateFormat="MMMM d, yyyy"
                    placeholderText="DOB (mm/dd/yyyy)"
                    className="!w-[280px] bg-transparent outline-none xl:text-[18px] text-[14px]"
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={50}
                  />
                </div>
              </div>

              <div className="flex justify-center mt-4">
                <button
                  type="submit"
                  className="text-[#11485B] bg-[#ffffff] lg:w-[300px] p-2 w-auto h-[40px] rounded-[20px] flex justify-center items-center cursor-pointer"
                >
                  <p className="text-[12px]">Request Quote</p>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Popup for success message */}
      {popup && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-5 rounded-lg shadow-lg">
            <p className="text-lg font-semibold text-[#1B5E20]">
              {packageView.serviceDetails &&
                packageView.serviceDetails.length > 0
                ? `Your request for ${packageView.serviceDetails[0].serviceName} has been successfully processed. Our team will reach out to you shortly.`
                : "Your request has been successfully processed. Our team will reach out to you shortly."}{" "}
            </p>
            <p className="text-lg font-semibold text-[#1B5E20]">
              Aqua Medical Laboratories
            </p>

            {/* <button
              onClick={() => setPopup(false)}
              className="bg-[#1B5E20] text-white py-2 px-4 rounded-lg mt-3"
            >
              Close
            </button> */}
          </div>
        </div>
      )}
      {/* Popup for error message */}
      {popup1 && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-5 rounded-lg shadow-lg">
            <p className="text-lg font-semibold text-red-600">
              Booking Failed! Please try again.
            </p>
            <button
              onClick={() => setPopup1(false)}
              className="bg-[#1B5E20] text-white py-2 px-4 rounded-lg mt-3"
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="bg-[#139EB5] w-full h-[40px]"></div>
    </div>
  );
}

export default PackageView;




{/* <div className="relative inputbox" ref={dropdownRef}>
                  <input
                    type="text"
                    name="serviceType"
                    // className="xl:mt-6 mt-4 w-full border-b-[1px] border-[#ffffff] bg-transparent outline-none xl:text-[18px] text-[14px]"
                    placeholder=""
                    value={selectedOption}
                    onFocus={() => setShowDropdown(true)}
                    onChange={() => setShowDropdown(true)}
                    required
                  />
                  {showDropdown && (
                    <div className="absolute z-50 w-full bg-white text-black shadow-md mt-2">
                      {options.map((option) => (
                        <div
                          key={option.value}
                          className="px-4 py-2 cursor-pointer text-black"
                          style={{ backgroundColor: option.color }}
                          onClick={() => handleOptionClick(option)}
                        >
                          {option.label}
                        </div>
                      ))}
                    </div>
                  )}
                </div> */}