import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AOS from "aos";

function ViewBooking() {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;
  AOS.init();

  const [bookingDetails, setBookingDetails] = useState(null);
  const { bookingId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("aqua");

    const fetchBookingDetails = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/booking/booking/${bookingId}`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setBookingDetails(data.booking);
        } else {
          throw new Error("Failed to fetch booking details");
        }
      } catch (error) {
        console.error("Error fetching booking details:", error);
      }
    };

    if (bookingId) {
      fetchBookingDetails();
    }
  }, [baseUrl, bookingId]);

  if (!bookingDetails) {
    return <p>Loading...</p>;
  }

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  return (
    <div className="">
      <div className="min-h-screen h-auto relative overflow-hidden">
        <div className="h-full w-full absolute lg:top-[115px] top-[80px] left-0 -z-10">
          <div className="relative h-full w-full">
            <img
              src="/assets/bg_blue.png"
              alt=""
              className="object-fill h-full w-full absolute top-0 -z-20"
            />
            <img
              src="/assets/bg_green.png"
              alt=""
              className="object-fill h-full w-full absolute top-0 -z-40"
            />
          </div>
        </div>
        <div className="px-5 md:px-20 xl:px-28 mt-[150px]">
          <button onClick={() => navigate(-1)} className="flex gap-2">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.0004 11.4997H7.83041L12.7104 6.61967C13.1004 6.22967 13.1004 5.58967 12.7104 5.19967C12.6179 5.10697 12.508 5.03342 12.387 4.98324C12.2661 4.93306 12.1364 4.90723 12.0054 4.90723C11.8744 4.90723 11.7448 4.93306 11.6238 4.98324C11.5028 5.03342 11.3929 5.10697 11.3004 5.19967L4.71041 11.7897C4.61771 11.8822 4.54416 11.9921 4.49398 12.113C4.4438 12.234 4.41797 12.3637 4.41797 12.4947C4.41797 12.6256 4.4438 12.7553 4.49398 12.8763C4.54416 12.9973 4.61771 13.1072 4.71041 13.1997L11.3004 19.7897C11.393 19.8823 11.5029 19.9557 11.6239 20.0058C11.7448 20.0559 11.8745 20.0817 12.0054 20.0817C12.1363 20.0817 12.266 20.0559 12.387 20.0058C12.5079 19.9557 12.6178 19.8823 12.7104 19.7897C12.803 19.6971 12.8764 19.5872 12.9265 19.4662C12.9766 19.3453 13.0024 19.2156 13.0024 19.0847C13.0024 18.9537 12.9766 18.8241 12.9265 18.7031C12.8764 18.5822 12.803 18.4723 12.7104 18.3797L7.83041 13.4997H19.0004C19.5504 13.4997 20.0004 13.0497 20.0004 12.4997C20.0004 11.9497 19.5504 11.4997 19.0004 11.4997Z"
                fill="#202020"
              />
            </svg>

            <p>Back</p>
          </button>
          <div className="lg:ml-80">
            <div
              className="flex px-8 py-4 justify-between mt-10 w-[280px] lg:w-[600px] rounded-lg"
              style={{
                backgroundImage: "url('/assets/card.png')",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div>
                <p className="text-[14px] md:text-[18px] font-semibold text-[#ffffff]">
                  {bookingDetails.TestName}
                </p>
                <p className="text-[12px] md:text-[16px] text-[#ffffff]">
                Request ID: <span>{bookingDetails.bookingId}</span>
                </p>
              </div>
            </div>

            <div className="mt-5">
              <div
                className="md:w-[600px] shadow-lg rounded-lg p-3 md:p-8 mb-10"
                style={{
                  backgroundImage: "url('/assets/card.png')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <h2 className="text-xl font-semibold text-[#ffffff] mb-6">
                Request Informations
                </h2>

                <div className="space-y-5">
                  <div className="flex gap-10">
                    <p className="font-semibold text-[#ffffff] w-40">
                      Test Request:
                    </p>
                    <p className="text-[#ffffff]">{bookingDetails?.TestName}</p>
                  </div>
                  <div className="flex gap-10">
                    <p className="font-semibold text-[#ffffff] w-40">
                    Request ID:
                    </p>
                    <p className="text-[#ffffff]">
                      {bookingDetails?.bookingId}
                    </p>
                  </div>
                  <div className="flex gap-10">
                    <p className="font-semibold text-[#ffffff] w-40">
                    Request Date & Time:
                    </p>
                    <p className="text-[#ffffff]">
                      {formatDate(bookingDetails.bookingDate)}
                    </p>
                  </div>
                  <div className="flex gap-10">
                    <p className="font-semibold text-[#ffffff] w-40">
                      Patient Name:
                    </p>
                    <p className="text-[#ffffff]">
                      {bookingDetails?.patienttitle}{" "}
                      {bookingDetails?.patientfirstname}{" "}
                      {bookingDetails?.patientlastname}
                    </p>
                  </div>
                  <div className="flex gap-10">
                    <p className="font-semibold text-[#ffffff] w-40">DOB:</p>
                    <p className="text-[#ffffff]">
                      {formatDate(bookingDetails.Patientdob)}
                    </p>
                  </div>
                  <div className="flex gap-10">
                    <p className="font-semibold text-[#ffffff] w-40">
                      Phone Number:
                    </p>
                    <p className="text-[#ffffff]">
                      {bookingDetails.patientNumber}
                    </p>
                  </div>
                  <div className="flex gap-10">
                    <p className="font-semibold text-[#ffffff] w-40">
                      Email Id:
                    </p>
                    <p className="text-[#ffffff]">
                      {bookingDetails.patientemail}
                    </p>
                  </div>
                  <div className="flex gap-10">
                    <p className="font-semibold text-[#ffffff] w-40">
                      Address:
                    </p>
                    <p className="text-[#ffffff] w-[300px]">
                      {bookingDetails.address}
                    </p>
                  </div>
                  <div className="flex gap-10">
                    <p className="font-semibold text-[#ffffff] w-40">
                      Type of Service:
                    </p>
                    <p className="text-[#ffffff]">
                      {bookingDetails.serviceType}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewBooking;
