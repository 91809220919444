import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
// import Loader from "../Loader";
// import TestForm from "../form";
import { FaEye, FaEyeSlash } from "react-icons/fa"; 


function Login() {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;
  const backgroundImage = "url('/assets/card.png')";

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    const lowerCaseValue = name === "email" ? value.toLowerCase() : value;

    setFormData({ ...formData, [name]: lowerCaseValue });
  };
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false); 

 

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      const { email, password } = formData;

      const response = await axios.post(
        `${baseUrl}/users/login`,
        {
          email,
          password,
        }
      );

      if (response.data.isSuccess) {
        localStorage.setItem("aqua", response.data.result.token);

        console.log("loginscxcxccc", response.data.result.token);

        // const role = response.data.result.role;
        // console.log("role", role)

        // if (role === 'vendor') {
        //     window.location = "/my-business";
        // } else {
        const redirectPath = localStorage.getItem("redirectPath");
        localStorage.removeItem("redirectPath");

        window.location.href = redirectPath || "/";
        // }
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      if (
        err.response &&
        err.response.status >= 400 &&
        err.response.status <= 500
      ) {
        setError(err.response.data.message);
      } else {
        setError("Internal Server Error");
      }
    }
  };

  const [isloading, setIsloading] = useState(true);

  useEffect(() => {
    const fakeDatafetch = () => {
      setTimeout(() => {
        setIsloading(false);
      }, 2000)
    }
    fakeDatafetch();
  }, [])


  return (
<>
<div className="relative min-h-screen flex items-center justify-center py-14 p-2 overflow-x-hidden">
      {/* <div className="absolute hidden lg:block top-4 md:top-[100px] lg:top-40 left-4 md:left-[20px] xl:left-[120px]">
        <img src="/assets/logo.png" alt="Left Logo" className="w-16 md:w-48" />
      </div> */}

      {/* <div className="absolute top-[60px] md:top-[46px] right-4 md:right-[8px]">
        <img
          src="/assets/log2.png"
          alt="Right Logo"
          className="w-20 md:w-[150px] lg:w-[306px]"
        />
      </div> */}

      <div
        className="bg-white p-10 mt-2 lg:mt-10 rounded-lg shadow-lg w-full max-w-md"
        style={{
          backgroundImage: backgroundImage,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h2 className="text-2xl font-bold mb-6 text-center text-[#ffff]">
          Log In
        </h2>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <input
              value={formData.email}
              onChange={handleChange}
              type="email"
              name="email"
              id="email"
              placeholder="Email*"
              className="w-full h-12 px-4 placeholder-[#234971] border-2 rounded-md"
              required
            />
          </div>
          <div className="relative">
      <input
        value={formData.password}
        onChange={handleChange}
        type={showPassword ? "text" : "password"} 
        name="password"
        id="password"
        placeholder="Password*"
        className="w-full h-12 px-4 placeholder-[#234971] border-2 rounded-md"
        required
      />

      <button
        type="button"
        onClick={togglePasswordVisibility}
        className="absolute inset-y-0 right-3 flex items-center text-[#234971]"
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </button>
    </div>
          <Link to="/forgetpassword">
            <p className=" font-semibold p-2 text-[12px] mb-6 text-end text-[#ffff]">
              {" "}
              Forgot Password
            </p>
          </Link>
          <div className="text-center">
            <button
              type="submit"
              className="w-[131px] h-12 bg-[#ffffff] text-[#234971] hover:bg-[#234971] hover:text-[#ffffff] border-2 rounded-[40px] text-center text-sm font-semibold mt-2 focus:outline-none focus:ring-2 focus:ring-[#234971]  focus:ring-opacity-50"
            >
              Login
            </button>
          </div>

          <Link to="/register">
            <p className=" font-semibold p-2 text-[12px] mb-6 text-center text-[#ffff]">
              {" "}
              Click here to <span className="underline">Register</span>
            </p>
          </Link>
        </form>
        {error && (
          <p className="error-message text-red-500 justify-center">{error}</p>
        )}
      </div>
      <div className="h-full w-full absolute top-0 left-0 -z-10">

        <div className="relative h-full w-full">
          <img src="./assets/bg_blue.png" alt="" className="object-fill h-full w-full absolute top-0 -z-20" />
          <img src="./assets/bg_green.png" alt="" className="object-fill h-full w-full absolute top-0 -z-40" />
        </div>
      </div>
    </div>
    </>
    
  );
}


export default Login;
