import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import { Link, useLocation, useNavigate } from "react-router-dom";

const BlogDetail = () => {

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const [isloading, setIsloading] = useState(true);
  const [blogData, setBlogData] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const blogId = queryParams.get("id");

  useEffect(() => {
    const fakeDatafetch = () => {
      setTimeout(() => {
        setIsloading(false);
      }, 2000);
    };
    fakeDatafetch();
  }, []);

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const updateDateTime = () => setCurrentDateTime(new Date());

    // Update time every second
    const intervalId = setInterval(updateDateTime, 1000);

    // Set the time immediately on component mount
    updateDateTime();

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const day = String(currentDateTime.getDate()).padStart(2, '0');
  const month = String(currentDateTime.getMonth() + 1).padStart(2, '0');
  const year = currentDateTime.getFullYear();
  const formattedDate = `${day}:${month}:${year}`;

  const hours24 = currentDateTime.getHours();
  const minutes = String(currentDateTime.getMinutes()).padStart(2, '0');
  const seconds = String(currentDateTime.getSeconds()).padStart(2, '0');
  const ampm = hours24 >= 12 ? 'PM' : 'AM';
  const hours = hours24 % 12 || 12;
  const formattedTime = `${String(hours).padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;


  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/blog/blogById?blogId=${blogId}`
        );
        if (response.status === 200) {
          const data = await response.json();
          console.log("Fetched BlogView data:", data);
          setBlogData(data);
        } else {
          throw new Error("Unable to fetch data");
        }
      } catch (err) {
        console.error(err);
        setBlogData(null);
      }
    };

    fetchBlogs();
  }, [blogId]);
  const navigate = useNavigate();


  if (isloading) {
    return <Loader />;
  }

  if (!blogData) {
    return <div>Blog not found</div>;
  }

  const handleGoBack = () => {
    window.history.back();
  };
  return (
    <div className="relative  pt-[140px]">
      <div className="flex justify-between xl:px-[120px] px-3 my-4">
        <button
          onClick={handleGoBack}
          className="flex gap-4 items-center cursor-pointer w-[100px]"
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 11.4997H7.82999L12.71 6.61967C13.1 6.22967 13.1 5.58967 12.71 5.19967C12.6175 5.10697 12.5076 5.03342 12.3866 4.98324C12.2656 4.93306 12.136 4.90723 12.005 4.90723C11.874 4.90723 11.7443 4.93306 11.6234 4.98324C11.5024 5.03342 11.3925 5.10697 11.3 5.19967L4.70999 11.7897C4.61728 11.8822 4.54373 11.9921 4.49355 12.113C4.44337 12.234 4.41754 12.3637 4.41754 12.4947C4.41754 12.6256 4.44337 12.7553 4.49355 12.8763C4.54373 12.9973 4.61728 13.1072 4.70999 13.1997L11.3 19.7897C11.3926 19.8823 11.5025 19.9557 11.6234 20.0058C11.7444 20.0559 11.8741 20.0817 12.005 20.0817C12.1359 20.0817 12.2656 20.0559 12.3865 20.0058C12.5075 19.9557 12.6174 19.8823 12.71 19.7897C12.8026 19.6971 12.876 19.5872 12.9261 19.4662C12.9762 19.3453 13.002 19.2156 13.002 19.0847C13.002 18.9537 12.9762 18.8241 12.9261 18.7031C12.876 18.5822 12.8026 18.4723 12.71 18.3797L7.82999 13.4997H19C19.55 13.4997 20 13.0497 20 12.4997C20 11.9497 19.55 11.4997 19 11.4997Z"
              fill="#202020"
            />
          </svg>
          <p className="text-[#202020] font-[600]">Back</p>
        </button>
        <img src="../assets/logo3.png" alt="" className="w-[200px]" />
      </div>

      <div className="relative mt-6">
        {/* <img
          src="./assets/logo1.png"
          alt=""
          className="absolute inset-0  object-cover opacity-70  mx-auto my-auto"
        /> */}
        <div className="relative z-10">
          <div className="bg-[#139EB5] w-full">
            <div className="bg-white w-fit xl:mx-[120px] mx-3 px-2">
              <p className="text-[#11485B]  md:text-[22px] text-center font-[700]">{blogData.title}</p>
            </div>
          </div>

          <div className="lg:px-0 xl:px-[120px] px-3">

            {blogData.sections.map((section, sIndex) => (
              <div key={sIndex} className="mt-6">
                <h2 className="text-[#11485B] font-bold text-[14px] md:text-[20px] ">{section.heading}</h2>

                <div className="lg:flex gap-5 items-start lg:items-center mt-4">
                  <div className=" pb-10">

                    <p className="text-[#202020] text-justify text-[14x] md:text-[20px]  font-normal blog" dangerouslySetInnerHTML={{ __html: section.content }} />
                  </div>
                  {/* <div className="lg:w-[35%] h-full flex justify-center mt-4 lg:mt-0">
                      <img src="./assets/blog/blog_img1.jpg" alt="Allergy Illustration" className="w-[50%] h-[50%] lg:w-full lg:h-[500px] fit" />
                    </div> */}

                </div>

              </div>
            ))}



          </div>
          <div className="bg-[#139EB5] w-full h-[40px]">
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
{/* <div className="flex justify-center gap-5">
            <p className="text-lg font-medium"> {formattedDate}</p>
            <p className="text-lg font-medium">{formattedTime}</p>
          </div> */}
{/* <img
            src={blogData.img}
            alt=""
            className="w-full h-full md:h-[100vh]  mt-3"
          /> */}
{/* <p className="text-[#202020] xl:text-[20px] md:text-[18px] text-[16px] my-4">{blogData.dec}</p> */ }